const buildPostLink = (lang, slug) => {
  if (lang === "UK") return `/blog/${slug}`

  return `/${lang}/blog/${slug}`.toLowerCase()
}

const cut = (html = "", length = 50, readMore = "...") => {
  const text = html.replace(/(<([^>]+)>)/gi, "")

  if (length >= text.length) {
    return text
  }

  return `${text.slice(0, length)}${readMore}`
}

export const trasfromPosts = (posts = []) => {
  return posts.map(post => ({
    ...post,
    title: cut(post?.title),
    excerpt: cut(post?.excerpt, 200, " [...]"),
    image: post?.image?.node?.localFile?.childImageSharp?.fluid,
    tags: trasfromTags(post?.tags?.nodes),
    link: buildPostLink(post?.language?.code, post?.slug),
    timeToReadMin: post?.options?.timeToReadMin,
    timeToReadMax: post?.options?.timeToReadMax,
  }))
}

export const trasfromTags = (tags = []) => {
  return tags.map(tag => ({
    ...tag,
    color: tag.style?.color,
    backgroundColor: tag.style?.backgroundColor,
    link: buildPostLink(tag?.language?.code, tag?.link),
  }))
}

export const translationsBuilder = currentTag => (langs, url) => {
  const translations = currentTag?.translations.map(tr => ({
    slug: tr.slug,
    code: tr.language.slug,
  }))

  return langs.map(code => {
    const translation = translations.find(tr => tr.code === code)
    const tagSlug = translation ? translation.slug : currentTag?.slug
    const translationLink = `/${code}/blog/${tagSlug}`

    return {
      link: translationLink,
      langKey: code,
      selected: translationLink === url,
    }
  })
}

export const getBlogDescription = lang => {
  const blogDescriptions = {
    ru:
      "Ми віримо, що обмін знаннями та ідеями — це ключ до створення крутих речей. Тож ми збираємо якомога більше власного досвіду, спілкуємося з талановитими представниками креативної індустрії та ділимося цим усім із вами, щоби надихнути на нові звершення.",
    uk:
      "Ми віримо, що обмін знаннями та ідеями — це ключ до створення крутих речей. Тож ми збираємо якомога більше власного досвіду, спілкуємося з талановитими представниками креативної індустрії та ділимося цим усім із вами, щоби надихнути на нові звершення.",
    en:
      "Ми віримо, що обмін знаннями та ідеями — це ключ до створення крутих речей. Тож ми збираємо якомога більше власного досвіду, спілкуємося з талановитими представниками креативної індустрії та ділимося цим усім із вами, щоби надихнути на нові звершення.",
  }

  return blogDescriptions[lang]
}
