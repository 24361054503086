import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../layouts/en/home"
import Hero from "../components/lendingSections/Hero"
import About from "../components/lendingSections/About"
import Why from "../components/lendingSections/Why"
import Services from "../components/lendingSections/Services"
import ChosenWorks from "../components/lendingSections/ChosenWorks"
import Contacts from "../components/lendingSections/Contacts"
import SectionWithText from "../components/SectionWithText"
import FeatureCard from "../components/FeatureCard"

import iconUsers from "../images/vp-icons/icon-1.svg"
import iconDocs from "../images/vp-icons/icon-2.svg"
import iconLamp from "../images/vp-icons/icon-3.svg"
import iconСlef from "../images/vp-icons/icon-4.svg"
import iconClock from "../images/vp-icons/icon-5.svg"
import iconDialog from "../images/vp-icons/icon-6.svg"

import mailTo from "../utils/mailTo"
import ModalContactForm from "../components/ModalContactForm/ModalContactForm"
import InlineButton from "../components/InlineButton"
import { seoDefaultData } from "../utils/seo"

const Home = ({ data, location }) => {
  const [showFrom, setShowForm] = useState(location.search === "?contact-form")

  const works = data.works.nodes
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <Hero link="/en/portfolio" />
      <About
        title="VP Production"
        text="We are an independent audio agency that crafts sonic identities for games, brands, and apps. With us, you won’t need to shy away from bold music, conceptual sounds, and distinctive voices — all for the sake of becoming a meaningful part of your audience's life."
        linkLabel="About us"
        linkURL="/en/about"
        alt="Sound design studio"
      />
      <Why>
        <FeatureCard img={iconClock}>
          <strong>Always meeting deadlines.</strong> In six years, we've missed
          zero deadlines.
        </FeatureCard>
        <FeatureCard img={iconСlef}>
          <strong>
            Audience + our audio ={" "}
            <span role="img" aria-label="heart">
              ❤️
            </span>
            .
          </strong>{" "}
          Our works have been listened to by millions of people, and many of
          them were so enthusiastic that they wrote{" "}
          <Link rel="nofollow" to="/en/reviews">
            this
          </Link>
          .
        </FeatureCard>
        <FeatureCard img={iconDocs}>
          <strong>A transparent work process.</strong> We make sure that you
          have a clear understanding of what's going on and control every step
          of the production. Our precise pipelines, audio mood boards, and
          feedback methodology help us a lot in the process.
        </FeatureCard>
        <FeatureCard img={iconLamp}>
          <strong>Creating a brand asset.</strong> We accurately study your
          product, analyze the market and target audience to develop a concept
          for functioning audio content.
        </FeatureCard>
        <FeatureCard img={iconDialog}>
          <strong>We communicate in simple language.</strong> You don't need to
          use audio terminology to explain the needs of your project.
        </FeatureCard>
        <FeatureCard img={iconUsers}>
          <strong>We're open</strong> and happy to share valuable knowledge for
          your product during{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.en}
          >
            a free consultation
          </a>{" "}
          and with{" "}
          <InlineButton onClick={() => setShowForm(true)}>
            the years-proven technical task template
          </InlineButton>
          .
        </FeatureCard>
      </Why>
      <ModalContactForm
        title="Technical task template"
        subTitle="We are confident that a correctly made technical task increases the chances of success of the project. Therefore, we are happy to share what works for us!"
        open={showFrom}
        onClose={() => setShowForm(false)}
      />
      <Services
        title="Our services"
        services={[
          {
            title: "Music Composing",
            link: "/en/services/music-composing",
            alt: "Music Composing",
          },
          {
            title: "Audio Branding",
            link: "/en/services/audio-branding",
            alt: "Audio branding",
          },
          {
            title: "Sound Design",
            link: "/en/services/sound-design",
            alt: "Sound design",
          },
          {
            title: "Voice Casting",
            link: "/en/services/voice-casting",
            alt: "Voice over services",
          },
        ]}
      />
      <ChosenWorks
        title="Featured Works"
        works={works}
        linkLabel="Portfolio"
        linkURL="/en/portfolio"
      />
      <SectionWithText linkLabel="Our Values" link="/en/our-values">
        <h3>
          We craft immersive emotional audio that inspires people to express
          themselves.
        </h3>
      </SectionWithText>
      <Contacts>
        Tell us about your project, product, or idea. Ask for advice, or get a
        full{" "}
        <a
          rel="nofollow noopener noreferrer"
          target="_blank"
          href={mailTo.mail.en}
        >
          free consultation
        </a>
        . Find out the rates, clarify a vital question, well anything you want!
        We will be happy to get to know you and help.
      </Contacts>
    </Layout>
  )
}

export const query = graphql`
  query HomePageEnQuery {
    site {
      siteMetadata {
        adminEmail
      }
    }
    seoPagesData: wpCustomPage(
      language: { code: { eq: EN } }
      seo: { pageSlug: { eq: "home" } }
    ) {
      ...seoCustomPageData
    }
    works: allWpWork(
      filter: {
        meta: { frontPage: { frontPageVisibility: { eq: true } } }
        language: { code: { eq: EN } }
      }
      sort: { fields: meta___frontPage___frontPageOrder, order: DESC }
    ) {
      ...workListItemData
    }
  }
`

export default Home
