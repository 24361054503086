import React from "react"
import PropTypes from "prop-types"

import * as styles from "./PriceCard.module.css"

const PriceCardList = ({ children }) => {
  return <div className={styles.cardList}>{children}</div>
}

PriceCardList.propTypes = {
  children: PropTypes.node,
}

export default PriceCardList
