import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../layouts/en/sidebar"

import ServiceHero from "~components/ServiceHero"
import ContactUs from "~components/ContactUs"
import Sidebar from "~components/Sidebar"
import ServiceSection from "~components/ServiceSection"
import ServiceIconList from "~components/ServiceIconList"
import ServiceIcon from "~components/ServiceIcon"
import StyledAccordion from "~components/StyledAccordion"
import StyledAccordionItem from "~components/StyledAccordionItem"
import ReviewCarousel from "~components/ReviewCarousel/ReviewCarousel"
import CardDeck from "~components/CardDeck/CardDeck"
import CardDeckItem from "~components/CardDeckItem/CardDeckItem"
import LazyIframe from "~components/LazyIframe"

import MaleIcon from "~images/service-icons/male.svg"
import LaptopIcon from "~images/service-icons/laptop.svg"
import FileIcon from "~images/service-icons/file.svg"
import VCFAIcon from "~images/service-icons/voice-casting-foreign-actors.svg"
import VCLAIcon from "~images/service-icons/voice-casting-local-actors.svg"

import mailTo from "~utils/mailTo"
import { seoDefaultData } from "~utils/seo"
import PriceCard, { PriceCardList } from "~components/PriceCard"

const VoiceCasting = ({ data, location }) => {
  const reviews = data.reviews.nodes
  const sidebarImage = data.sidebarImage.childImageSharp.fluid
  const contactEmail = data.site.siteMetadata.adminEmail
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      Sidebar={
        <Sidebar
          title="Voice Casting"
          text="The tongue can paint what the eyes cannot see."
          autor="Chinese Proverb"
          image={sidebarImage}
        />
      }
      ServiceHero={
        <ServiceHero>
          <h3>
            We like to think that the human voice is the perfect musical
            instrument.
          </h3>
          <p>
            We don’t know what else can so accurately transmit the author's
            message, convey the whole spectrum of emotions and the elusive
            nuances of mood. And if vocals are one of the significant music
            elements, then voice acting is an independent art form.
          </p>
          <p>
            The correct voice-over is not just a recitation of the necessary
            text with a well-set voice. It is more about managing the attention
            of the listener, characters’ revelation, and atmosphere creation.
            The right voice makes people listen and believe what they hear.
          </p>
        </ServiceHero>
      }
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <ServiceSection title="What do we do?">
        <p>We produce voice-overs, specifically we:</p>

        <ServiceIconList align="space-evenly">
          <ServiceIcon
            title="Cast voice actors"
            icon={MaleIcon}
            alt="Voice over"
            style={{ maxWidth: 200 }}
          />
          <ServiceIcon
            title="Organize and manage the recording process"
            icon={LaptopIcon}
            alt="Voice over agency"
            style={{ maxWidth: 200 }}
          />
          <ServiceIcon
            title="Process audio files"
            icon={FileIcon}
            alt="Voice over for videos"
            style={{ maxWidth: 200 }}
          />
        </ServiceIconList>
        <p>
          We also work with professional vocalists to add a human touch and
          depth to <Link to="/en/services/audio-branding">audio brands</Link>{" "}
          and <Link to="/en/services/music-composing">music</Link>.
        </p>
      </ServiceSection>
      <ServiceSection title="Voice Base">
        <p>
          Over the years, we have formed a base of voice talents, both actors
          and vocalists. Each of them owns a wide variety of languages and
          accents, timbres, and intonations. You can hear for yourself;
          playlists with examples of actors’ and singers’ voices are below.
        </p>

        <h3>Voice actors</h3>
        <LazyIframe
          title="Voice actors"
          height="450"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/303617614%3Fsecret_token%3Ds-jgwUQD8Wr0W&color=%23f23b0d&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"
        />
        <p>
          As a benefit, all actors from our base are native speakers, and{" "}
          <strong>proofreading</strong> is already included in the cost of work.
        </p>

        <h3>Singers</h3>
        <LazyIframe
          title="Voice actors"
          height="450"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1259357083%3Fsecret_token%3Ds-vWRXTht4Qep&color=%23f23b0d&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=false"
        />
      </ServiceSection>
      <ServiceSection title="What is the process like?">
        <p>
          Over the past few years, we have developed a clear and effective
          cooperation scheme. On the one hand, we ensure that you have a clear
          understanding of the process and complete control at every stage. On
          the other hand, we gather as much information as possible about your
          project to complete it with perfect voices.
        </p>
        <StyledAccordion>
          <StyledAccordionItem title="Getting to know each other">
            <p>
              You fill out a brief and send us a scenario we'll need to voice.
              We set up an audio or video call via Google Meet or Zoom to get to
              know each other, discuss the project, and make sure we are ready
              to collaborate. We need to match with the client in the general
              vision and principles of work.
            </p>
            <p>
              If you cannot share information about the project without an NDA,
              we sign the necessary documents at the beginning of the
              communication.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Pre-production">
            <p>
              At this stage, we carefully study the technical task, the script,
              and the characters’ description, select suitable voice talents and
              show them to you. If necessary, we record test phrases to make it
              easier for you to choose the voice actor.
            </p>
            <p>
              We study the document, select suitable talents, and show them to
              you. If necessary, we record test phrases to make it easier for
              you to choose the voice actor.
            </p>
            <p>
              At the same time, we prepare a contract with lawyers. Then, we
              discuss and coordinate everything with you and proceed to the next
              stage.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Legal formalities">
            <p>
              Together we sign a contract, you send us a full prepayment for the
              project, we sign an agreement with the actor and transfer the
              scenario to them.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Production">
            <p>
              The voice talent sends us a complete record, we check it for
              compliance with the requirements, and, if all sounds good, send it
              to you. If it seems to you that the actor has not correctly
              conveyed any intonations or general message, you can make up to
              two free edits for each line of the script.
            </p>
            <p>
              After you are satisfied with the actor’s work, we will do the
              standard processing of the audio file. Also, here at this stage,
              we can further process the recorded voice and add the desired
              effects.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Project acceptance">
            <p>
              After all approvals, we send you the final files. This moment is
              the last chance to use free edits.
            </p>
            <p>
              You implement the voice files into your project and give us access
              to it so we can make sure everything sounds right. If something
              doesn't go according to plan, we make a list of fixes and make
              sure you can tweak everything.
            </p>
            <p>
              When all is done and sounds good, together we sign an Acceptance
              Certificate.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Support">
            <p>
              We like to follow the progress of our partners and clients.
              Frequently after the project acceptance, the work just starts, and
              we are always ready to continue cooperation.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>
      </ServiceSection>

      <ServiceSection title="Our prices">
        <p style={{ marginBottom: 60 }}>
          The project’s cost depends on the choice of a voice actor; basic audio
          file processing (cleaning, equalization, compression, limitation) is
          included in the price; specific processings (radio effect, echo, robot
          voice, etc.) are negotiated separately.
        </p>

        <PriceCardList>
          <PriceCard
            icon={VCLAIcon}
            title="Local voice actors"
            subTitle="Available languages:"
            list={["Ukrainian", "Russian", "English"]}
            price="€260/hour"
          />
          <PriceCard
            icon={VCFAIcon}
            title="Foreign voice actors"
            subTitle="Available languages:"
            list={["English", "German", "French", "Spanish", "Chinese"]}
            price="€360/hour"
          />
        </PriceCardList>

        <p>
          The hour of work includes actor recording and the necessary sound
          processings. On average, it is possible to voice 800-1000 words of the
          scenario in one hour, but it all depends on the project’s specifics.
        </p>
        <p>The minimum order amount is the price of one hour of work.</p>
        <p>
          If you did not find the language you need in the list of available
          languages, be sure to{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={`mailto:${contactEmail}`}
          >
            email us
          </a>
          , and we will provide you with the right talent.
        </p>
        <p>
          The cost of the vocalists' work directly depends on the volume and
          complexity of the project and is calculated individually.
        </p>
      </ServiceSection>
      <ServiceSection title="Our approach">
        <CardDeck>
          <CardDeckItem variant="rect" textAlign="left">
            In our approach, the ideal work on voice-over looks like this: you
            give us the script and all the essential information about the
            project, and then you receive a finalized file with all the rights
            in return.
          </CardDeckItem>
          <CardDeckItem variant="full-waves" textAlign="left">
            We take care of the whole «headache». In quotation marks, because
            there is indeed much work, but we like it.
          </CardDeckItem>
          <CardDeckItem variant="waves" textAlign="left">
            We select the voice you need for your project, organize the process,
            manage the project, and carefully monitor the quality of the result.
            In addition, we process audio files and make sure that everything
            sounds as it should. We also undertake all legal aspects and
            transfer to you exclusive intellectual property rights to all
            finalized material.
          </CardDeckItem>
        </CardDeck>
      </ServiceSection>
      <ServiceSection title="Clients’ and partners’ testimonials">
        <ReviewCarousel reviews={reviews} />
      </ServiceSection>
      <div className="contact-us-wrapper">
        <ContactUs>
          Tell us about your project, product, or idea. Ask for advice, or get a{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.en}
          >
            free consultation
          </a>
          . Find out the rates, clarify a vital question, well anything you
          want! We will be happy to get to know you and help.
        </ContactUs>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query VoiceCastingEnPageQuery {
    seoPagesData: wpCustomPage(
      language: { code: { eq: EN } }
      seo: { pageSlug: { eq: "services/voice-casting" } }
    ) {
      ...seoCustomPageData
    }
    reviews: allWpClientReview(
      limit: 5
      filter: {
        meta: { viceOver: { viceOverVisibility: { eq: true } } }
        language: { code: { eq: EN } }
      }
    ) {
      ...serviceClientReviews
    }
    sidebarImage: file(relativePath: { eq: "services/voice-casting.jpg" }) {
      ...sidebarImage
    }
    sitePage {
      id
      path
    }
    site {
      siteMetadata {
        adminEmail
      }
    }
  }
`

export default VoiceCasting
