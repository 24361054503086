const mapPostTranslations = (translations = []) => {
  return translations.map(tr => {
    const langKey = `/${tr.language.slug}`.replace("/uk", "")
    const path = "/blog"
    const slug = tr.slug

    return {
      ...tr,
      uri: `${langKey}${path}/${slug}`,
    }
  })
}

const mapper = {
  mapPostTranslations,
}

export default mapper
