// extracted by mini-css-extract-plugin
export var container = "styles-module--container--nbPPa";
export var innerContainer = "styles-module--innerContainer--7hSJu";
export var col = "styles-module--col--UpE5H";
export var colHorizontal = "styles-module--colHorizontal--YwTtL";
export var footerLogoLink = "styles-module--footerLogoLink--0soMi";
export var footerLinks = "styles-module--footerLinks--FElEJ";
export var link = "styles-module--link--U3u3p";
export var privacyPolicy = "styles-module--privacyPolicy--gnlOS";
export var footerTitle = "styles-module--footerTitle--QtuTV";
export var footerRights = "styles-module--footerRights--q5l19";
export var footerLogo = "styles-module--footerLogo--Hj9NB";