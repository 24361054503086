import React from "react"
import Masonry from "react-masonry-css"

import Pagination from "~components/Pagination"
import PostCard from "~components/PostCard"

import * as styles from "./styles.module.css"

const defaultBreakpoint = {
  default: 2,
  500: 1,
}

const PostsList = ({
  posts = [],
  pageNumber = 0,
  pageCount = 1,
  hrefBuilder,
  onPageChange,
}) => {
  return (
    <div className={styles.posts}>
      <Masonry
        breakpointCols={defaultBreakpoint}
        className={styles.grid}
        columnClassName={styles.column}
      >
        {posts.map(({ databaseId, ...post }) => (
          <PostCard key={databaseId} {...post} />
        ))}
      </Masonry>

      <Pagination
        pageNumber={pageNumber}
        pageCount={pageCount}
        hrefBuilder={hrefBuilder}
        onPageChange={onPageChange}
      />
    </div>
  )
}

export default PostsList
