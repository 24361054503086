import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../layouts/en/sidebar"

import ServiceHero from "~components/ServiceHero"
import ContactUs from "~components/ContactUs"
import Sidebar from "~components/Sidebar"
import ServiceSection from "~components/ServiceSection"
import ServiceIconList from "~components/ServiceIconList"
import ServiceIcon from "~components/ServiceIcon"
import StyledAccordion from "~components/StyledAccordion"
import StyledAccordionItem from "~components/StyledAccordionItem"
import ReviewCarousel from "~components/ReviewCarousel/ReviewCarousel"
import CardDeck from "~components/CardDeck/CardDeck"
import CardDeckItem from "~components/CardDeckItem/CardDeckItem"
import ModalContactForm from "~components/ModalContactForm"
import InlineButton from "~components/InlineButton"
import PriceCard, { PriceCardList } from "~components/PriceCard"

import GamesIcon from "~images/service-icons/games.svg"
import AudioIcon from "~images/service-icons/audio.svg"
import PhoneIcon from "~images/service-icons/phone-2.svg"
import PlayerIcon from "~images/service-icons/player-2.svg"
import MasksIcon from "~images/service-icons/masks.svg"

import MCMCIcon from "~images/service-icons/music-composing-music-composing.svg"
import MCRIcon from "~images/service-icons/music-composing-research.svg"

import mailTo from "~utils/mailTo"
import { seoDefaultData } from "~utils/seo"

const MusicCreation = ({ data, location }) => {
  const [showForm, setShowForm] = useState(false)

  const reviews = data.reviews.nodes
  const sidebarImage = data.sidebarImage.childImageSharp.fluid
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      Sidebar={
        <Sidebar
          title="Music Composing"
          text="Music is indeed the mediator between the spiritual and the sensual life."
          autor="Ludwig van Beethoven"
          image={sidebarImage}
        />
      }
      ServiceHero={
        <ServiceHero>
          <h3>We love music primarily because of its potential.</h3>
          <p>
            As it is the most potent art form if we compare them by emotional
            impact. Do you know how one song can completely change your mood?
          </p>
          <p>
            Just a couple of notes, the correct rhythmic pattern, and a person
            is ready for outstanding accomplishments. And after a slight change
            in the arrangement, the very same person suddenly has sad memories.
            Yet, it all fascinates us and pushes us to do what we do every day:
            listen, study and, finally, compose music.
          </p>
        </ServiceHero>
      }
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <ServiceSection title="What do we do?">
        <p>We compose soundtracks and create music for:</p>
        <ServiceIconList>
          <ServiceIcon
            title="Games"
            icon={GamesIcon}
            alt="Composing music for video games"
          />
          <ServiceIcon
            title="Brands and commercials"
            icon={AudioIcon}
            alt="Composing music for commercials"
          />
          <ServiceIcon
            title="Animations"
            icon={PlayerIcon}
            alt="Composing music for animations"
          />
          <ServiceIcon
            title="Apps"
            icon={PhoneIcon}
            alt="Composing music for apps"
          />
          <ServiceIcon
            title="Theatre"
            icon={MasksIcon}
            alt="Composing music for theatres"
          />
        </ServiceIconList>
        <p>
          We also create music for motion graphics which relates more to sound
          design, and you can read about it{" "}
          <Link to="/en/works/audio-for-motion-design">here</Link>.
        </p>
        <p>
          We do not compose backing tracks and songs for corporate parties. But
          we do write brand tracks — you can read more about it{" "}
          <Link to="/en/services/audio-branding">here</Link>.
        </p>
      </ServiceSection>
      <ServiceSection title="Our Best Soundtracks">
        <iframe
          title="Our Best Soundtracks"
          width="100%"
          height="450"
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/174940453&color=%23f23b0d&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        ></iframe>
      </ServiceSection>
      <ServiceSection title="What is the process like?">
        <p>
          Over the past few years, we have developed a clear and effective
          cooperation scheme. On the one hand, we ensure that you have a clear
          understanding of the process and total control at every stage. On the
          other, we gather as much information as possible about your product to
          develop the right concept and create music that will become a
          meaningful part of your audience's life.
        </p>
        <StyledAccordion>
          <StyledAccordionItem title="Getting to know each other">
            <p>
              You fill out a brief and send us a list of music assets we'll need
              to create. We set up an audio or video call via Google Meet or
              Zoom to get to know each other, discuss the project, and make sure
              we are ready to collaborate. We need to match with the client in
              the general vision and principles of work.
            </p>
            <p>
              If you cannot share information about the project without an NDA,
              we sign the necessary documents at the beginning of the
              communication.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Legal formalities">
            <p>
              We clarify the project scope, the payments' quantity, and order,
              sign a contract with you and get an advance payment. Learn more
              about financial and legal issues in our{" "}
              <Link to="/en/faq">FAQ</Link>.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Pre-production">
            <p>
              Over the years, we have seen that pre-production largely
              determines the entire project performance and success. At this
              stage, we study your brief in detail and ask additional questions.
              Then, we conduct research, create the technical task and{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/en/faq#Audioconcept"
              >
                an audio concept
              </a>
              .
            </p>
            <p>
              Besides your project, we study your niche, competitors, the target
              audience, and on this basis, define music goals and the future
              sound. Our proven over the years{" "}
              <InlineButton onClick={() => setShowForm(true)}>
                technical task template
              </InlineButton>{" "}
              and{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/en/faq#Audiomoodboard"
              >
                audio mood boards
              </a>{" "}
              help us in this.{" "}
            </p>
            <p>
              We approve the completed technical task and audio concept document
              with you and make revisions if necessary. After that, we receive a
              second payment from you.
            </p>
          </StyledAccordionItem>
          <ModalContactForm
            title="Technical task template"
            subTitle="We are confident that a correctly made technical task increases the chances of success of the project. Therefore, we are happy to share what works for us!"
            open={showForm}
            onClose={() => setShowForm(false)}
          />
          <StyledAccordionItem title="Production">
            <p>
              At this stage, a lot depends on the size and specifics of the
              project. But in general, it consists of the following steps:
            </p>
            <ul>
              <li>
                We create a 30-second demo of the track and send it for your
                feedback. We familiarize you ahead with the effective feedback
                methodology, of course. :)
              </li>
              <li>We approve the demo with you or make a list of edits.</li>
              <li>
                Based on the demo and comments, we finalize the track and send
                it for your feedback.
              </li>
              <li>
                We approve the track with you or make new edits — we provide
                five rounds of free revisions in total.
              </li>
              <li>We finalize the approved version of the track.</li>
            </ul>
            <p>
              The cycle repeats depending on the number of tracks. During big
              and long projects, one more intermediate payment may appear at
              this stage.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Project acceptance">
            <p>
              After all approvals, we send you the final files. This moment is
              the last chance to use free edits.
            </p>
            <p>
              You implement the tracks into your project and give us access to
              it so we can make sure everything sounds right. If something
              doesn't go according to plan, we make a list of fixes and make
              sure you can tweak everything.
            </p>
            <p>
              When everything is done and sounds good, we sign an Acceptance
              Certificate, and you send us the final part of the payment.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Support">
            <p>
              We like to follow the progress of our partners and clients. Often
              after the project acceptance, the work just starts, and we are
              always ready to continue cooperation and add a new sound to the
              product. Within the framework of the initially developed audio
              concept, of course. :)
            </p>
          </StyledAccordionItem>
        </StyledAccordion>
      </ServiceSection>

      <ServiceSection title="Our prices">
        <p style={{ marginBottom: 60 }}>
          We experimented a lot with music pricing formats, observed the
          response of our partners and clients, and eventually came to a single
          standard. This option proved to be the most effective and comfortable
          for all parties.
        </p>

        <PriceCardList>
          <PriceCard
            icon={MCRIcon}
            title="Research and audio concept"
            list={[
              "in-depth briefing",
              "niche, competitors, and target audience analysis",
              "technical task creation",
              "audio concept development",
            ]}
            price="€700"
          />

          <PriceCard
            icon={MCMCIcon}
            title="Music composing"
            list={[
              "in-depth briefing",
              "five rounds of free edits",
              "intellectual property rights with resale restrictions",
              "distribution on streaming platforms",
            ]}
            price="€700/min"
          />
        </PriceCardList>

        <p>
          The research and audio concept stage is a mandatory part of the music
          composing process because it determines the tracks’ tasks and future
          sound. However, you can save on this service if you initially provide
          us with detailed information about the project, including:
        </p>
        <ul>
          <li>your product positioning;</li>
          <li>points of product differentiation;</li>
          <li>a list of your competitors with examples of their audio;</li>
          <li>
            information on how competitors use music for marketing purposes;
          </li>
          <li>portrait(s) of your target audience;</li>
          <li>the needs and preferences of your target audience;</li>
          <li>best practices and audio clichés of the niche.</li>
        </ul>
        <p>
          The minimum order amount for music composing is €700. It means that if
          you need a track of 30, 45, or 52 seconds, it still will cost the same
          as one minute.
        </p>
      </ServiceSection>
      <ServiceSection title="Our approach">
        <CardDeck>
          <CardDeckItem variant="rect" textAlign="left">
            Many people consider music composing to be something
            incomprehensible and mysterious. And some people say that it's just
            a matter of technique and the correct methodology. However, we are
            lucky because we have found our working formula over the years of
            practice.
          </CardDeckItem>
          <CardDeckItem variant="full-waves" textAlign="left">
            The most important is to unite discipline, creativity, and system
            approach.
          </CardDeckItem>
          <CardDeckItem variant="waves" textAlign="left">
            When we start working on a project, we find as much information as
            possible about it. We explore its atmosphere, mood, and message. We
            study the market, the competitors, and, obviously, the target
            audience and its interests.
          </CardDeckItem>
          <CardDeckItem variant="rect-revers" textAlign="center">
            On the one hand, all this information helps us better understand the
            product and form our opinion and attitude. But, on the other hand,
            it determines how accurately and effectively the music will affect
            the final listener.
          </CardDeckItem>
          <CardDeckItem variant="full-waves" textAlign="center">
            The rest is a matter of discipline and technique. Thanks to the
            collected data, we don't wait for inspiration but methodically
            create a «core» around which we later build everything else.
          </CardDeckItem>
          <CardDeckItem variant="circle" textAlign="center">
            Creatively and reliably.
          </CardDeckItem>
        </CardDeck>
      </ServiceSection>
      <ServiceSection title="Clients’ and partners’ testimonials">
        <ReviewCarousel reviews={reviews} />
      </ServiceSection>
      <div className="contact-us-wrapper">
        <ContactUs>
          Tell us about your project, product, or idea. Ask for advice, or get a{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.en}
          >
            free consultation
          </a>
          . Find out the rates, clarify a vital question, well anything you
          want! We will be happy to get to know you and help.
        </ContactUs>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MusicCreationENPageQuery {
    seoPagesData: wpCustomPage(
      language: { code: { eq: EN } }
      seo: { pageSlug: { eq: "services/music-composing" } }
    ) {
      ...seoCustomPageData
    }
    reviews: allWpClientReview(
      limit: 5
      filter: {
        meta: { musicComposing: { musicComposingVisibility: { eq: true } } }
        language: { code: { eq: EN } }
      }
    ) {
      ...serviceClientReviews
    }
    sidebarImage: file(relativePath: { eq: "services/music-creation.jpg" }) {
      ...sidebarImage
    }
    sitePage {
      id
      path
    }
  }
`

export default MusicCreation
