import React from "react"
import PropTypes from "prop-types"
import ReactPaginate from "react-paginate"

import "./styles.css"

const Pagination = ({ pageNumber, pageCount, onPageChange, hrefBuilder }) => {
  return (
    <ReactPaginate
      forcePage={pageNumber}
      pageCount={pageCount}
      breakLabel={"..."}
      breakClassName={"break-me"}
      marginPagesDisplayed={1}
      pageRangeDisplayed={2}
      containerClassName={"pagination"}
      activeClassName={"active"}
      onPageChange={onPageChange}
      hrefBuilder={hrefBuilder}
    />
  )
}

Pagination.propTypes = {
  pageNumber: PropTypes.number,
  pageCount: PropTypes.number,
  onPageChange: PropTypes.func,
  hrefBuilder: PropTypes.func,
}

export default Pagination
