import React from "react"
import { graphql } from "gatsby"

import Layout from "../../layouts/ru/default"

import PriceCard, { PriceCardList } from "~components/PriceCard"
import Contacts from "~components/lendingSections/Contacts"
import { Inner } from "~components/Container"
import Headline from "~components/Headline"

import { seoDefaultData } from "~utils/seo"

import RACCIcon from "~images/service-icons/review-and-consultations-consultation.svg"
import RACARIcon from "~images/service-icons/review-and-consultations-audio-review.svg"

const AudioReviewsAndConsultations = ({ data, location }) => {
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }
  const adminEmail = data?.site?.siteMetadata?.adminEmail

  return (
    <Layout
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <div
        className="text-page-content"
        style={{
          margin: "6rem 1rem 2rem",
        }}
      >
        <Inner>
          <Headline Tag="h1">Аудио-ревью и консультации</Headline>
          <p>
            За последние несколько лет мы работали над аудио десятков проектов,
            а ещё с десятком не работали напрямую. Но это не значит, что мы не
            принимали участия в их творческом процессе. Мы проводили
            консультации и аудио-ревью, чтобы помочь инди-разработчикам и
            брендам понять потребности своих продуктов в плане звука или
            улучшить существующее аудио до нужного уровня качества.
          </p>
          <p>
            Такая работа помогает нам распространять наш подход, делиться своим
            видением с другими, создавать значимые связи и помогать людям
            создавать приятно звучащие (и эффективные!) продукты. Именно поэтому
            мы решили, что пришло время официально представить эту услугу. Итак,
            встречайте аудио-ревью и консультации!
          </p>
          <Headline Tag="h2">Что мы делаем?</Headline>
          <p>
            Сейчас мы проводим консультации двух видов: консультация по аудио
            конкретного проекта и аудио-ревью.
          </p>
          <p>
            На <b>консультации по аудио</b> мы обсуждаем с вами проект в целом,
            его позиционирование, выделяем основные задачи аудио, помогаем
            составить техническое задание, обсуждаем возможные идеи для
            реализации, рассказываем о лучших практиках и о том, чему стоит
            уделить внимание при работе над аудио.
          </p>
          <p>
            <b>Аудио-ревью проекта</b> включает в себя оценку аудиального
            игрового опыта, оценку звучания отдельных анимаций и/или катсцен,
            составление документа с предложениями по правкам.
          </p>
          <Headline Tag="h2">Как проходит процесс?</Headline>
          <p>
            Всё зависит от типа консультации, который вы выбрали. Но в любом
            случае всё начинается с того, что мы предварительно тщательно
            изучаем подробное описание проекта, включая информацию о его целевой
            аудитории, рынке и конкурентах. Также нам пригодятся любые
            визуальные материалы: арты, видео, анимации и т.д. А если у вас
            вдруг есть питч-документ — это вообще идеально!
          </p>
          <p>
            В случае <b>консультации по аудио конкретного проекта</b>, после
            изучения всей предоставленной информации мы организовываем аудио-
            или видеоконференцию в Google Meet или Zoom. Такая консультация
            обычно длится 1,5 часа, но при необходимости мы можем её продлить,
            либо провести несколько сессий.
          </p>
          <p>
            Для <b>аудио-ревью</b> нам также понадобится играбельный unlock-билд
            и документ с названиями всех аудио-ассетов и ссылками на
            соответствующие анимации. Обычно мы проводим ревью в текстовом
            формате и в результате вы получаете текстовый документ.
          </p>
          <p>Аудио-ревью мы выполняем в течение трёх рабочих дней.</p>

          <strong>Оба вида консультаций требуют полной предоплаты.</strong>

          <Headline Tag="h2">Стоимость</Headline>
          <PriceCardList>
            <PriceCard
              icon={RACCIcon}
              title="Консультация по аудио конкретного проекта"
              list={[
                "обсуждаем проект в целом, его позиционирование",
                "выделяем основные задачи аудио",
                "помогаем составить техническое задание",
                "обсуждаем возможные идеи и рассказываем о лучших практиках",
              ]}
              price="€100/час"
            />

            <PriceCard
              icon={RACARIcon}
              title="Аудио-ревью"
              list={[
                "оценка аудиального игрового опыта",
                "оценка звучания отдельных анимаций и/или катсцен",
                "создание документа с предложениями по правкам",
              ]}
              price="€400*"
            />
          </PriceCardList>
          <p>
            *Стоимость аудио-ревью зависит от количества аудио ассетов в
            проекте.
          </p>
          <p>
            Кроме того, каждый месяц мы проводим три бесплатных тридцатиминутных
            консультации или лайт аудио-ревью.{" "}
            <a href={`mailto:${adminEmail}`}>Напишите нам</a>, чтобы поймать
            свой слот.
          </p>
        </Inner>
      </div>
      <Contacts>
        Расскажите нам о своём проекте, продукте или идее. Спросите совет или
        получите бесплатную консультацию. Узнайте цены, уточните важный вопрос,
        да что угодно! Мы будем рады с вами познакомиться и помочь.
      </Contacts>
    </Layout>
  )
}

export const query = graphql`
  query AudioReviewsAndConsultationsPageRuQuery {
    site {
      siteMetadata {
        adminEmail
      }
    }
    seoPagesData: wpCustomPage(
      language: { code: { eq: RU } }
      seo: { pageSlug: { eq: "audio-reviews-and-consultations" } }
    ) {
      ...seoCustomPageData
    }
  }
`

export default AudioReviewsAndConsultations
