import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../layouts/en/sidebar"
import ServiceHero from "../../components/ServiceHero"
import ContactUs from "../../components/ContactUs"
import Sidebar from "../../components/Sidebar"
import ServiceSection from "../../components/ServiceSection"
import ServiceIconList from "../../components/ServiceIconList"
import ServiceIcon from "../../components/ServiceIcon"
import StyledAccordion from "../../components/StyledAccordion"
import StyledAccordionItem from "../../components/StyledAccordionItem"
import ReviewCarousel from "../../components/ReviewCarousel/ReviewCarousel"
import CardDeck from "../../components/CardDeck/CardDeck"
import CardDeckItem from "../../components/CardDeckItem/CardDeckItem"
import WorkCards from "../../components/WorkCards"

import PlayerIcon from "../../images/service-icons/player.svg"
import RoomIcon from "../../images/service-icons/room.svg"
import GamesIcon from "../../images/service-icons/games.svg"

import mailTo from "../../utils/mailTo"
import { seoDefaultData } from "../../utils/seo"
import Button from "~components/Button"
import InlineButton from "~components/InlineButton"
import ModalContactForm from "~components/ModalContactForm"

const SoundDesign = ({ data, location }) => {
  const [showForm, setShowForm] = useState(false)

  const reviews = data.reviews.nodes
  const sidebarImage = data.sidebarImage.childImageSharp.fluid
  const works = data.works.nodes
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      Sidebar={
        <Sidebar
          title="Sound Design"
          text="The sound has great significance during the production of games as well as movies. 60 or even 70% of sensations are evoked exactly due to the sound. At the same time, the sounds themselves are not only incidental music but also sound effects. Their presence or absence highly defines the atmosphere of the game."
          autor="Hideo Kojima"
          image={sidebarImage}
        />
      }
      ServiceHero={
        <ServiceHero>
          <h3>
            If music is art, then sound is initially the way of transmitting
            information.
          </h3>
          <p>
            Moreover, it’s one of the oldest means. But it does not mean that
            music is all about emotions, and sound is only for information. A
            short sound indeed can directly affect a person’s feelings, just
            like music.
          </p>
          <p>
            Whether we like it or not, an alarming sound will make us wary. And
            that is exactly why we love sound design. For its picture
            transformations, ability to ginger up, and help the viewer to
            believe indeed. Magic itself!
          </p>
        </ServiceHero>
      }
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <ServiceSection title="What do we do?">
        <p>We create:</p>

        <ServiceIconList>
          <ServiceIcon
            title="Sound effects, ambients, and jingles for video games"
            icon={GamesIcon}
            alt="Sound design for animation"
            style={{ maxWidth: 260 }}
          />
          <ServiceIcon
            title="Video sound design"
            icon={PlayerIcon}
            alt="Sound design for theatre"
            style={{ maxWidth: 260 }}
          />
          <ServiceIcon
            title="Sound design of spaces (escape rooms, installations, etc.)"
            icon={RoomIcon}
            alt="Sound design for video games"
            style={{ maxWidth: 260 }}
          />
        </ServiceIconList>

        <p>
          We start with studying the target audience and the context of the
          project. Then, it helps us to define the tasks of the sounds. This
          approach allows us to create not only a set of separate assets but an
          effective, harmonious system.
        </p>
        <p>
          We discuss the sound implementation nuances in advance as we think
          about where and how sounds will play, and we use this information
          during the creative process.
        </p>
        <p>
          We also do sound design for apps, but we approach the development even
          more thoroughly. In this case, we emphasize sonic identity, and you
          can read about it on the{" "}
          <Link to="/en/services/audio-branding">Audio Branding page</Link>.
        </p>
      </ServiceSection>
      <ServiceSection title="Our works">
        <WorkCards
          works={works}
          breakpoints={{
            default: 3,
            1100: 3,
            700: 3,
            500: 2,
          }}
        />
        <div className="service-works-porfolio-button-container">
          <Button
            state={{
              service: "sound-design",
            }}
            to="/en/portfolio?service=sound-design-3"
            className="service-works-porfolio-button"
          >
            Portfolio
          </Button>
        </div>
      </ServiceSection>
      <ServiceSection title="What is the process like?">
        <p>
          Over the past few years, we have developed a clear and effective
          cooperation scheme. On the one hand, we make sure that you clearly
          understand the process and have total control at every stage. On the
          other hand, we gather as much information as possible about your
          product to develop the correct concept and create audio that will
          become a meaningful part of your audience's life.
        </p>
        <StyledAccordion>
          <StyledAccordionItem title="Getting to know each other">
            <p>
              You fill out a brief and send us a list of sound assets we'll need
              to create. We set up an audio or video call via Google Meet or
              Zoom to get to know each other, discuss the project, and make sure
              we are ready to collaborate. We need to match with the client in
              the general vision and principles of work.
            </p>
            <p>
              If you cannot share information about the project without an NDA,
              we sign the necessary documents at the beginning of the
              communication.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Legal formalities">
            <p>
              We clarify the project scope, the payments' quantity, and order,
              sign a contract with you and get an advance payment. Learn more
              about financial and legal issues in our{" "}
              <Link to="/en/faq">FAQ</Link>.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Pre-production">
            <p>
              Over the years, we have seen that pre-production largely
              determines the entire project performance and success. At this
              stage, we study your brief in detail and ask additional questions.
              Then, we conduct research, create the technical task and an{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/en/faq#Audioconcept"
              >
                an audio concept
              </a>
              .
            </p>
            <p>
              Besides your project, we study your niche, competitors, the target
              audience, and on this basis, define music goals and the future
              sound. Our proven over the years{" "}
              <InlineButton onClick={() => setShowForm(true)}>
                technical task template
              </InlineButton>{" "}
              and{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/en/faq#Audiomoodboard"
              >
                audio mood boards
              </a>{" "}
              help us to determine the focal points.{" "}
            </p>
            <p>
              We approve the completed technical task and audio concept document
              with you and make revisions if necessary. After that, we receive a
              second payment from you.
            </p>
          </StyledAccordionItem>
          <ModalContactForm
            title="Technical task template"
            subTitle="We are confident that a correctly made technical task increases the chances of success of the project. Therefore, we are happy to share what works for us!"
            open={showForm}
            onClose={() => setShowForm(false)}
          />
          <StyledAccordionItem title="Production">
            <p>
              At this stage, a lot depends on the size and specifics of the
              project. But in general, we do the following steps:
            </p>
            <ul>
              <li>
                We create 3–5 test sounds and send them for your feedback. We
                familiarize you ahead with the effective feedback methodology,
                of course. :)
              </li>
              <li>We approve sounds with you or make a list of edits.</li>
              <li>
                Based on the test sounds and comments, we finalize the next
                asset pack and send it for your feedback.
              </li>
              <li>
                We approve the sounds with you or make new edits — we provide
                five rounds of free revisions in total.
              </li>
            </ul>
            <p>
              The cycle repeats depending on the number of sound asset packs.
              During big and long projects, one more intermediate payment may
              appear at this stage.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Project acceptance">
            <p>
              After all approvals, we send you the final files. This moment is
              the last chance to use free edits.
            </p>
            <p>
              You implement the sounds into your project and give us access to
              it so we can make sure everything sounds right. If something
              doesn't go according to plan, we make a list of fixes and make
              sure you can tweak everything.
            </p>
            <p>
              When everything is done and sounds good, we sign an Acceptance
              Certificate, and you send us the final part of the payment.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Support">
            <p>
              We like to follow the progress of our partners and clients. Often
              after the project acceptance, the work just starts, and we are
              always ready to continue cooperation and add a new sound to the
              product. Within the framework of the initially developed audio
              concept, of course. :)
            </p>
          </StyledAccordionItem>
        </StyledAccordion>
      </ServiceSection>

      <ServiceSection title="Our prices">
        <p>Sound effect (sfx) — €40. </p>
        <p>Jingle (short musical phrase) — €60.</p>
        <p>Ambient (complex ambient sound) — €80.</p>
        <p>Sound design for videos (music + sound design) — €1500/min.</p>
        <p>The cost of all sound assets includes:</p>
        <ul>
          <li>5 rounds of free edits;</li>
          <li>exclusive intellectual property rights;</li>
          <li>help with implementation and testing.</li>
        </ul>
        <p>
          The minimum order amount is €400.{" "}
          <a href={mailTo.soundDesign.en}>Email us</a>, and we'll answer any
          questions you may have and help you with the project estimate.{" "}
          <span role="img" aria-label="hands">
            🙌
          </span>
        </p>
      </ServiceSection>
      <ServiceSection title="Our approach">
        <CardDeck>
          <CardDeckItem variant="rect" textAlign="left">
            For us, it is essential to make elements sound in a way that the
            listener does not have doubts about whether it is a «native» sound.
            We create each effect, keeping in mind the feelings it should evoke
            in a person.
          </CardDeckItem>
          <CardDeckItem variant="full-waves" textAlign="left">
            Will sound stress out the listeners? Or will it make them feel
            relieved? Or warn about something? We think about every detail,
            especially how sounds will sound altogether. How will they interact
            with each other and with the general background (ambient or music)?
          </CardDeckItem>
          <CardDeckItem variant="waves" textAlign="left">
            Together with you, we want to be confident in the final sound of the
            project. So we listen carefully to the sound design implemented in
            the product and help our clients adjust volume levels, number of
            simultaneous plays, randomization, assets variation, and other
            essential points.
          </CardDeckItem>
        </CardDeck>
      </ServiceSection>
      <ServiceSection title="Clients’ and partners’ testimonials">
        <ReviewCarousel reviews={reviews} />
      </ServiceSection>
      <div className="contact-us-wrapper">
        <ContactUs>
          Tell us about your project, product, or idea. Ask for advice, or get a{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.en}
          >
            free consultation
          </a>
          . Find out the rates, clarify a vital question, well anything you
          want! We will be happy to get to know you and help.
        </ContactUs>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SoundDesignEnPageQuery {
    seoPagesData: wpCustomPage(
      language: { code: { eq: EN } }
      seo: { pageSlug: { eq: "services/sound-design" } }
    ) {
      ...seoCustomPageData
    }

    reviews: allWpClientReview(
      limit: 5
      filter: {
        meta: { soundDesign: { soundDesignVisibility: { eq: true } } }
        language: { code: { eq: EN } }
      }
    ) {
      ...serviceClientReviews
    }
    works: allWpWork(
      filter: {
        language: { code: { eq: EN } }
        meta: { soundDesign: { soundDesignVisibility: { eq: true } } }
      }
      sort: { fields: meta___soundDesign___soundDesignOrder, order: DESC }
    ) {
      ...serviceWorks
    }
    sidebarImage: file(relativePath: { eq: "services/sound-design.jpg" }) {
      ...sidebarImage
    }
    sitePage {
      id
      path
    }
  }
`

export default SoundDesign
