import React from "react"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"
import * as styles from "./styles.module.css"

const Sidebar = ({ title = "", text = "", autor = "", image, slot }) => (
  <BackgroundImage critical className={styles.container} fluid={image}>
    <h1 className={styles.title}>{title}</h1>
    <figure className={styles.quote}>
      <blockquote>{text}</blockquote>
      {autor && (
        <figcaption className={styles.autor}>
          <cite>{autor}</cite>
        </figcaption>
      )}
    </figure>
    {slot ? slot : null}
  </BackgroundImage>
)

Sidebar.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  autor: PropTypes.string,
  image: PropTypes.any.isRequired,
  slot: PropTypes.node,
}

export default Sidebar
