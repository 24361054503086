import { useState } from "react"
import useIsomorphicLayoutEffect from "../hooks/useIsomorphicLayoutEffect"

const useDetectKeyboard = () => {
  const [useKeyboard, setUseKeyboard] = useState(false)

  useIsomorphicLayoutEffect(() => {
    const detectKeyboard = event => {
      if (useKeyboard) return

      if (event.key === "Tab") {
        setUseKeyboard(true)
        document.querySelector("body").classList.add("keyboard")
      }
    }

    document.addEventListener("keydown", detectKeyboard, false)
    return () => {
      document.removeEventListener("keydown", detectKeyboard, false)
    }
  }, [useKeyboard])

  useIsomorphicLayoutEffect(() => {
    const detectMouse = () => {
      if (!useKeyboard) return

      setUseKeyboard(false)
      document.querySelector("body").classList.remove("keyboard")
    }

    document.addEventListener("click", detectMouse, false)
    return () => {
      document.removeEventListener("click", detectMouse, false)
    }
  }, [useKeyboard])

  return useKeyboard
}

export default useDetectKeyboard
