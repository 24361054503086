import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../layouts/ru/sidebar"

import ServiceHero from "~components/ServiceHero"
import ContactUs from "~components/ContactUs"
import Sidebar from "~components/Sidebar"
import ServiceSection from "~components/ServiceSection"
import ServiceIconList from "~components/ServiceIconList"
import ServiceIcon from "~components/ServiceIcon"
import StyledAccordion from "~components/StyledAccordion"
import StyledAccordionItem from "~components/StyledAccordionItem"
import ReviewCarousel from "~components/ReviewCarousel/ReviewCarousel"
import CardDeck from "~components/CardDeck/CardDeck"
import CardDeckItem from "~components/CardDeckItem/CardDeckItem"
import ModalContactForm from "~components/ModalContactForm/ModalContactForm"
import InlineButton from "~components/InlineButton"
import PriceCard, { PriceCardList } from "~components/PriceCard"

import GamesIcon from "~images/service-icons/games.svg"
import AudioIcon from "~images/service-icons/audio.svg"
import PhoneIcon from "~images/service-icons/phone-2.svg"
import PlayerIcon from "~images/service-icons/player-2.svg"
import MasksIcon from "~images/service-icons/masks.svg"

import MCMCIcon from "~images/service-icons/music-composing-music-composing.svg"
import MCRIcon from "~images/service-icons/music-composing-research.svg"

import mailTo from "~utils/mailTo"
import { seoDefaultData } from "~utils/seo"

const MusicCreation = ({ data, location }) => {
  const [showForm, setShowForm] = useState(false)

  const reviews = data.reviews.nodes
  const sidebarImage = data.sidebarImage.childImageSharp.fluid
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      Sidebar={
        <Sidebar
          title="Создание музыки"
          text="Музыка — это посредник между духовной и чувственной жизнью."
          autor="Людвиг ван Бетховен"
          image={sidebarImage}
        />
      }
      ServiceHero={
        <ServiceHero>
          <h3>Больше всего мы любим музыку за её возможности.</h3>
          <p>
            Это ведь самый сильный вид искусства, если сравнивать их все по
            эмоциональному воздействию. Знаете, как это бывает, когда одна песня
            меняет ваше настроение?
          </p>
          <p>
            Всего пару нот, правильный ритмический рисунок и вот уже человек
            готов к великим свершениям. А стоит слегка изменить аранжировку — и
            он внезапно предаётся грустным воспоминаниям. Всё это захватывает
            нас и заставляет каждый день делать то, что мы делаем: слушаем,
            изучаем и, в конце концов, создаём музыку.
          </p>
        </ServiceHero>
      }
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <ServiceSection title="Что мы делаем?">
        <p>Мы пишем саундтреки и создаём музыку для:</p>

        <ServiceIconList>
          <ServiceIcon title="Игр" icon={GamesIcon} />
          <ServiceIcon title="Брендов и рекламы" icon={AudioIcon} />
          <ServiceIcon title="Анимаций" icon={PlayerIcon} />
          <ServiceIcon title="Приложений" icon={PhoneIcon} />
          <ServiceIcon title="Театральных постановок" icon={MasksIcon} />
        </ServiceIconList>

        <p>
          Ещё мы создаём музыку для motion-дизайна, но она скорее относится к
          звуковому дизайну, так что о ней вы можете прочесть{" "}
          <Link to="/ru/works/audio-for-motion-design">вот здесь</Link>.
        </p>
        <p>
          Мы не пишем «минусовки» и песни для корпоративов. А вот бренд-треки —
          это мы с радостью, подробнее смотрите{" "}
          <Link to="/ru/services/audio-branding">тут</Link>.
        </p>
      </ServiceSection>
      <ServiceSection title="Лучшие саундтреки">
        <iframe
          title="Лучшие саундтреки"
          width="100%"
          height="450"
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/174940453&color=%23f23b0d&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        ></iframe>
      </ServiceSection>
      <ServiceSection title="Как проходит процесс?">
        <p>
          За последние несколько лет мы выработали понятную и эффективную схему
          сотрудничества. С одной стороны, мы следим, чтобы на каждом этапе у
          вас было чёткое понимание происходящего и полный контроль над ним. А с
          другой — собираем максимум информации о вашем продукте, чтобы
          разработать подходящую концепцию и создать музыку, которая станет
          значимой частью жизни вашей аудитории.
        </p>
        <StyledAccordion>
          <StyledAccordionItem title="Знакомство">
            <p>
              Вы заполняете бриф и предоставляете список музыкальных ассетов,
              которые нам будет нужно создать. Мы назначаем аудио- или
              видеоконференцию в Google Meet или Zoom, на которой знакомимся
              друг с другом, обсуждаем проект и понимаем, насколько готовы
              сотрудничать. Для нас важно совпадать с клиентом в общем видении и
              принципах работы.
            </p>
            <p>
              Если вы не можете делиться информацией о проекте без NDA, то в
              самом начале общения мы подписываем необходимые документы.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Юридические формальности">
            <p>
              Мы уточняем объём проекта, количество и порядок платежей,
              подписываем с вами договор и получаем аванс. Детальнее о
              финансовых и юридических вопросах вы можете прочесть в нашем{" "}
              <Link to="/ru/faq">FAQ</Link>.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Препродакшн">
            <p>
              За годы работы мы не один раз убедились, что препродакшн во многом
              определяет успех всего проекта. На этом этапе мы более детально
              изучаем ваш бриф и задаём дополнительные вопросы, проводим рисёрч,
              составляем техническое задание и создаём{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/ru/faq#audialinayakontseptsiya"
              >
                аудиальную концепцию
              </a>
              .
            </p>
            <p>
              Кроме вашего проекта мы изучаем вашу нишу, конкурентов, целевую
              аудиторию и на этой основе определяем задачи и будущее звучание
              музыки. Помогают нам в этом{" "}
              <InlineButton onClick={() => setShowForm(true)}>
                проверенный годами темплейт ТЗ
              </InlineButton>{" "}
              и{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/ru/faq#audialiniimudbord"
              >
                аудиальные мудборды
              </a>
              .
            </p>
            <p>
              Мы утверждаем с вами готовое техническое задание и документ с
              аудиальной концепцией, при необходимости вносим правки. После
              этого мы получаем от вас второй платёж.
            </p>
          </StyledAccordionItem>
          <ModalContactForm
            title="Темплейт технического задания"
            subTitle="Мы уверены, что правильно составленное ТЗ повышает шансы на успех проекта. Поэтому с радостью делимся тем, что работает для нас!"
            open={showForm}
            onClose={() => setShowForm(false)}
          />
          <StyledAccordionItem title="Продакшн">
            <p>
              На этом этапе многое зависит от размера и специфики проекта, но в
              целом всё сводится к следующим действиям:
            </p>
            <ul>
              <li>
                Мы создаём 30-секундное демо трека и отправляем вам на фидбек,
                предварительно ознакомив с действенной методикой фидбека. :)
              </li>
              <li>Утверждаем с вами демо либо составляем список правок.</li>
              <li>
                На основе демо и комментариев дорабатываем композицию,
                отправляем вам на фидбек.
              </li>
              <li>
                Утверждаем с вами трек либо вносим новые правки — всего у нас
                предусмотрено 5 раундов бесплатных правок.
              </li>
              <li>Финализируем утверждённую версию трека.</li>
            </ul>
            <p>
              Цикл повторяется в зависимости от количества композиций. В больших
              и длительных проектах на этом этапе может добавиться ещё один
              промежуточный платёж.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Приём проекта">
            <p>
              После всех согласований мы высылаем вам конечные файлы. Этот
              момент — последний шанс воспользоваться бесплатными правками.
            </p>
            <p>
              Вы имплементируете композиции в свой проект и даёте нам к нему
              доступ, чтобы мы могли убедиться, что всё звучит правильно. В
              случае, когда что-то пошло не по плану, мы составляем список
              исправлений и следим, чтобы вы смогли всё настроить.
            </p>
            <p>
              Когда всё готово и хорошо звучит, мы с вами подписываем акт
              приёма-передачи и вы отправляете нам финальную часть оплаты.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Поддержка">
            <p>
              Мы любим следить за развитием наших партнёров и клиентов. Часто
              после приёма проекта работа только начинается и мы всегда готовы
              продолжить сотрудничество и добавить нового звучания продукту. В
              рамках изначально разработанной аудиальной концепции, конечно. :)
            </p>
          </StyledAccordionItem>
        </StyledAccordion>
      </ServiceSection>

      <ServiceSection title="Стоимость">
        <p style={{ marginBottom: 60 }}>
          Мы много экспериментировали с форматами цен на музыку, наблюдали за
          откликом наших партнёров и клиентов, и в итоге пришли к единому
          стандарту. Такой вариант показал себя самым действенным и комфортным
          для всех сторон.
        </p>

        <PriceCardList>
          <PriceCard
            icon={MCRIcon}
            title="Рисёрч и аудиальная концепция"
            list={[
              "глубинный брифинг",
              "анализ ниши, конкурентов и целевой аудитории",
              "составление технического задания",
              "разработка аудиальной концепции",
            ]}
            price="€700"
          />

          <PriceCard
            icon={MCMCIcon}
            title="Создание музыки"
            list={[
              "глубинный брифинг",
              "5 раундов бесплатных правок",
              "имущественные права интеллектуальной собственности с ограничением возможности перепродажи",
              "дистрибуция на стримингах",
            ]}
            price="€700/мин"
          />
        </PriceCardList>

        <p>
          Этап рисчёрча и создания аудиальной концепции — обязательная часть
          процесса создания музыки, потому что именно он определяет задачи и
          будущее звучание треков. Тем не менее, вы можете сэкономить на этой
          услуге, если сразу предоставите нам подробную информацию о проекте,
          включая:
        </p>
        <ul>
          <li>позиционирование вашего продукта;</li>
          <li>его точки дифференциации;</li>
          <li>список конкурентов с примерами их аудио;</li>
          <li>
            информацию, как конкуренты используют музыку в маркетинговых целях;
          </li>
          <li>портрет(ы) вашей целевой аудитории;</li>
          <li>потребности и предпочтения вашей целевой аудитории;</li>
          <li>лучшие практики и аудиальные клише ниши.</li>
        </ul>
        <p>
          Сумма минимального заказа на создание музыки — €700. Это значит, что
          если вам нужен трек на 30, 45 или 52 секунды, его стоимость в любом
          случае будет равна стоимости одной минуты.
        </p>
      </ServiceSection>
      <ServiceSection title="Наш подход">
        <CardDeck>
          <CardDeckItem variant="rect" textAlign="left">
            Многие люди считают создание музыки чем-то непостижимым и
            таинственным. А некоторые наоборот утверждают, что всё дело — только
            в технике и правильной методологии. Нам же повезло, ведь за годы
            практики мы нашли свою рабочую формулу.
          </CardDeckItem>
          <CardDeckItem variant="full-waves" textAlign="left">
            Главное – подружить дисциплину, креативность и системный подход.
          </CardDeckItem>
          <CardDeckItem variant="waves" textAlign="left">
            Когда мы начинаем работу над проектом, мы выясняем как можно больше
            о нём. Мы узнаём его атмосферу, настроение и посыл. Мы изучаем
            рынок, конкурентов, и, конечно же, целевую аудиторию и её интересы.
          </CardDeckItem>
          <CardDeckItem variant="rect-revers" textAlign="center">
            С одной стороны, вся эта информация помогает нам лучше проникнуться
            продуктом, сформировать своё мнение и отношение к нему. А с другой –
            определяет, насколько точно и эффективно музыка будет воздействовать
            на конечного слушателя.
          </CardDeckItem>
          <CardDeckItem variant="full-waves" textAlign="center">
            Дальше — дело за дисциплиной и техникой. Благодаря собранным данным
            мы не ждём вдохновения, а методично создаём «ядро», вокруг которого
            позже выстраиваем всё остальное.
          </CardDeckItem>
          <CardDeckItem variant="circle" textAlign="center">
            Творчески и надёжно.
          </CardDeckItem>
        </CardDeck>
      </ServiceSection>
      <ServiceSection title="Отзывы клиентов и партнёров">
        <ReviewCarousel reviews={reviews} />
      </ServiceSection>
      <div className="contact-us-wrapper">
        <ContactUs>
          Расскажите нам о своём проекте, продукте или идее. Спросите совет или
          получите{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.ru}
          >
            бесплатную консультацию
          </a>
          . Узнайте цены, уточните важный вопрос, да что угодно! Мы будем рады с
          вами познакомиться и помочь.
        </ContactUs>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MusicCreationRUPageQuery {
    seoPagesData: wpCustomPage(
      language: { code: { eq: RU } }
      seo: { pageSlug: { eq: "services/music-composing" } }
    ) {
      ...seoCustomPageData
    }
    reviews: allWpClientReview(
      limit: 5
      filter: {
        meta: { musicComposing: { musicComposingVisibility: { eq: true } } }
        language: { code: { eq: RU } }
      }
    ) {
      ...serviceClientReviews
    }
    sidebarImage: file(relativePath: { eq: "services/music-creation.jpg" }) {
      ...sidebarImage
    }
    sitePage {
      id
      path
    }
  }
`

export default MusicCreation
