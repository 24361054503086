import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../../layouts/en/default"
import { Inner } from "../../components/Container"
import SingleNavagation from "../../components/SingleNavagation"
import Contacts from "../../components/lendingSections/Contacts"
import parseContent from "../../utils/parseContent"
import { HeadingWithMeta, HeadingWithHero } from "../../components/WorkHeading"
import TermItem from "../../components/TermItem"

import * as styles from "./styles.module.css"
import Headline from "../../components/Headline"
import ReviewCarousel from "../../components/ReviewCarousel/ReviewCarousel"
import mailTo from "../../utils/mailTo"
import getServiceURI from "../../utils/getServiceURI"
import { filterProjectReviews } from "../../utils/filterProjectReviews"
import mappers from "../../mappers"

const WorkTemplate = ({ data, pageContext: { next, prev }, location }) => {
  const {
    title,
    content,
    wordpress_id,
    featuredImage,
    workCategories,
    workServices,
    workGenres,
    workPlatforms,
    workDevelopers,
    acf,
    seo,
    video,
    translations,
  } = data.wpWork

  const Content = parseContent(content)

  const isHeroFormat = video.workVideo !== null

  const reviews = filterProjectReviews(data.reviews.nodes, wordpress_id)

  const dbTranslations = mappers.WorkMapper.mapWorkTranslations(translations)

  return (
    <Layout
      location={location}
      title={seo.title || title}
      disableSiteNameInTitle
      description={seo.description}
      translations={dbTranslations}
    >
      {isHeroFormat && (
        <div className={styles.videoBGWrapper}>
          <div className={styles.videoBG}>
            <iframe
              src={`https://player.vimeo.com/video/${video.workVideo}?background=1&autoplay=1&loop=1&byline=0&title=0`}
              frameBorder="0"
              allowFullScreen
              title={`${title} - video bg`}
            ></iframe>
          </div>
        </div>
      )}
      <Inner>
        <div className={styles.template}>
          {isHeroFormat ? (
            <HeadingWithHero title={title} />
          ) : (
            <HeadingWithMeta
              image={featuredImage.node}
              title={title}
              terms={
                <>
                  <TermItem
                    className={styles.metaValues}
                    terms={workCategories.noodes}
                    name="Category"
                  />
                  <TermItem
                    className={styles.metaValues}
                    terms={getServiceURI(workServices.nodes)}
                    name="Service"
                  />
                  <TermItem
                    className={styles.metaValues}
                    terms={workGenres.nodes}
                    name="Genre"
                  />
                  <TermItem
                    className={styles.metaValues}
                    terms={workPlatforms.nodes}
                    name="Platform"
                  />
                  <TermItem
                    className={styles.metaValues}
                    terms={workDevelopers.nodes}
                    name="Developer"
                  />
                  <TermItem
                    className={styles.metaValues}
                    terms={acf.autors}
                    name="Work on the project"
                  />
                </>
              }
            />
          )}
          <div
            className={styles.content}
            style={{
              marginBottom: reviews.length ? "0" : "3rem",
            }}
          >
            {Content}
          </div>
          {reviews.length > 0 && (
            <>
              <Headline Tag="h2">Customer testimonial</Headline>
              <div
                style={{
                  marginBottom: "2rem",
                }}
              >
                <ReviewCarousel reviews={reviews} />
              </div>
            </>
          )}
        </div>
      </Inner>
      <SingleNavagation
        next={next ? next.slug : null}
        prev={prev ? prev.slug : null}
        list="en/portfolio"
        prefix="en/works"
      />
      <Inner>
        <Contacts>
          Tell us about your project, product, or idea. Ask for advice, or get a
          full{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.en}
          >
            free consultation
          </a>
          . Find out the rates, clarify a vital question, well anything you
          want! We will be happy to get to know you and help.
        </Contacts>
      </Inner>
    </Layout>
  )
}

WorkTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default WorkTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wpWork(id: { eq: $id }) {
      ...work
    }
    site {
      siteMetadata {
        title
      }
    }
    reviews: allWpClientReview(filter: { language: { code: { eq: EN } } }) {
      ...workClientReviews
    }
  }
`
