import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../layouts/uk/home"
import Hero from "../components/lendingSections/Hero"
import About from "../components/lendingSections/About"
import Why from "../components/lendingSections/Why"
import Services from "../components/lendingSections/Services"
import ChosenWorks from "../components/lendingSections/ChosenWorks"
import Contacts from "../components/lendingSections/Contacts"
import SectionWithText from "../components/SectionWithText"
import FeatureCard from "../components/FeatureCard"

import iconUsers from "../images/vp-icons/icon-1.svg"
import iconDocs from "../images/vp-icons/icon-2.svg"
import iconLamp from "../images/vp-icons/icon-3.svg"
import iconСlef from "../images/vp-icons/icon-4.svg"
import iconClock from "../images/vp-icons/icon-5.svg"
import iconDialog from "../images/vp-icons/icon-6.svg"

import mailTo from "../utils/mailTo"
import ModalContactForm from "../components/ModalContactForm/ModalContactForm"
import InlineButton from "../components/InlineButton"
import { seoDefaultData } from "../utils/seo"

const Home = ({ data, location, ...props }) => {
  const [showFrom, setShowForm] = useState(location.search === "?contact-form")

  const works = data.works.nodes
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <Hero link="/portfolio" />
      <About
        title="VP Production"
        text="Ми – незалежна аудіоагенція, яка створює звукову айдентику для ігор, брендів і застосунків. Із нами можна не соромитися зарядженої музики, концептуальних звуків і самобутніх голосів — усе заради того, щоби стати значущою частиною життя вашої аудиторії."
        linkLabel="Про нас"
        linkURL="/about"
        alt="Студія саунд дизайну"
      />
      <Why>
        <FeatureCard img={iconClock}>
          <strong>Дедлайни — наше все.</strong> За 6 років ми зірвали 0
          дедлайнів.
        </FeatureCard>
        <FeatureCard img={iconСlef}>
          <strong>
            Аудиторія + наше аудіо ={" "}
            <span role="img" aria-label="сердце">
              ❤️
            </span>
            .
          </strong>{" "}
          Наші роботи вже чули мільйони людей і багацько залишилися настільки
          небайдужими, що написали про нас{" "}
          <Link rel="nofollow" to="/reviews">
            таке
          </Link>
          .
        </FeatureCard>
        <FeatureCard img={iconDocs}>
          <strong>Зрозумілий процес роботи.</strong> Ми стежимо, щоби на кожному
          етапі у вас було чітке розуміння того, що відбувається, і повний
          контроль над ним. У цьому нам допомагають вивірені пайплайни,
          аудіальні мудборди й наша методика фідбеку.
        </FeatureCard>
        <FeatureCard img={iconLamp}>
          <strong>Створюємо актив бренду.</strong> Ми детально вивчаємо ваш
          продукт, аналізуємо ринок і цільову аудиторію, щоби розробити загальну
          концепцію і створити працюючий аудіоконтент.
        </FeatureCard>
        <FeatureCard img={iconDialog}>
          <strong>Ми спілкуємося простою мовою.</strong> Вам не потрібно
          використовувати аудіо термінологію, щоби ми зрозуміли, що потрібно
          вашому проєкту.
        </FeatureCard>
        <FeatureCard img={iconUsers}>
          <strong>Ми — відкриті</strong> і з радістю ділимося корисними для
          вашого продукту знаннями на{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.uk}
          >
            безплатній консультації
          </a>{" "}
          та у{" "}
          <InlineButton onClick={() => setShowForm(true)}>
            перевіреному роками темплейті ТЗ
          </InlineButton>
          .
        </FeatureCard>
      </Why>
      <ModalContactForm
        title="Темплейт технічного завдання"
        subTitle="Ми впевнені, що правильно складене ТЗ підвищує шанси на успіх проєкту. Тому з радістю ділимося тим, що працює для нас!"
        open={showFrom}
        onClose={() => setShowForm(false)}
      />
      <Services
        title="Наші послуги"
        services={[
          {
            title: "Створення музики",
            link: "/services/music-composing",
            alt: "Створення музики",
          },
          {
            title: "Аудіобрендинг",
            link: "/services/audio-branding",
            alt: "Аудіобрендинг",
          },
          {
            title: "Звуковий дизайн",
            link: "/services/sound-design",
            alt: "Саунд дизайн",
          },
          {
            title: "Голосове озвучення",
            link: "/services/voice-casting",
            alt: "Озвучення",
          },
        ]}
      />
      <ChosenWorks
        title="Вибрані роботи"
        works={works}
        linkLabel="Портфолiо"
        linkURL="/portfolio"
      />
      <SectionWithText linkLabel="Наші цінності" link="/our-values">
        <h3>
          Ми створюємо захоплююче емоційне аудіо, що надихає людей виражати
          себе.
        </h3>
      </SectionWithText>
      <Contacts>
        Розкажіть нам про свій проєкт, продукт або ідею. Запитайте пораду або
        отримайте повноцінну{" "}
        <a
          rel="nofollow noopener noreferrer"
          target="_blank"
          href={mailTo.mail.uk}
        >
          безплатну консультацію
        </a>
        . Дізнайтеся вартість, уточніть важливе питання, та що завгодно! Ми
        будемо раді з вами познайомитися й допомогти.
      </Contacts>
    </Layout>
  )
}

export const query = graphql`
  query HomePageUkQuery {
    site {
      siteMetadata {
        adminEmail
      }
    }
    seoPagesData: wpCustomPage(
      language: { code: { eq: UK } }
      seo: { pageSlug: { eq: "home" } }
    ) {
      ...seoCustomPageData
    }
    works: allWpWork(
      filter: {
        meta: { frontPage: { frontPageVisibility: { eq: true } } }
        language: { code: { eq: UK } }
      }
      sort: { fields: meta___frontPage___frontPageOrder, order: DESC }
    ) {
      ...workListItemData
    }
  }
`

export default Home
