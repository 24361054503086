import React, { useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import * as styles from "./styles.module.css"
import { useStaticQuery, Link } from "gatsby"
import { graphql } from "gatsby"
import Img from "~components/image"
import NestedNavItem from "./NestedNavItem"
import NavItem from "./NavItem"
import LangSelect from "../LangSelect"
import HamburgerButton from "../HamburgerButton"
import NavigationDrawer from "../NavigationDrawer"
import UkraineSupportAlert from "~components/UkraineSupportAlert"
import { CSSTransition } from "react-transition-group"

const HorizontalNav = ({
  langsMenu = [],
  locale = "uk",
  homeLink = "/",
  hideMenu = false,
  className = "",
  visible = true,
  uaAlert = false,
}) => {
  const {
    logo,
    site: {
      siteMetadata: { navMenu },
    },
  } = useStaticQuery(graphql`
    query getSiteData {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 54, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          navMenu {
            link {
              uk
              ru
              en
            }
            child {
              link {
                uk
                ru
                en
              }
              label {
                uk
                ru
                en
              }
            }
            label {
              uk
              ru
              en
            }
          }
        }
      }
    }
  `)

  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const handleMenuToogle = () => {
    setMenuIsOpen(!menuIsOpen)
  }

  return (
    <header
      className={classNames(styles.container, className)}
      style={
        hideMenu
          ? {
              backgroundColor: "transparent",
            }
          : null
      }
    >
      {uaAlert && <UkraineSupportAlert />}
      <CSSTransition
        in={visible}
        classNames="fade-form-top"
        mountOnEnter
        unmountOnExit
        timeout={200}
      >
        <div className={styles.inner}>
          <Link className={styles.logo} to={homeLink}>
            <Img
              fadeIn={false}
              loading="eager"
              fluid={logo.childImageSharp.fluid}
              alt="VP Production"
            />
          </Link>
          <div className={styles.filters} id="blog-filters" />
          {!hideMenu ? (
            <>
              <nav className={styles.nav}>
                <ul>
                  {navMenu.map((item, index) => {
                    if (item.child) {
                      return (
                        <NestedNavItem
                          key={index}
                          label={item.label[locale]}
                          child={item.child}
                          locale={locale}
                        />
                      )
                    }

                    return (
                      <NavItem
                        key={index}
                        label={item.label[locale]}
                        link={item.link[locale]}
                      />
                    )
                  })}
                </ul>
              </nav>
              <LangSelect
                langsMenu={langsMenu}
                className={styles.langsSelect}
              />
              <HamburgerButton
                isOpen={menuIsOpen}
                onClick={handleMenuToogle}
                className={styles.menuButton}
              />
              <NavigationDrawer
                isOpen={menuIsOpen}
                handleOpen={handleMenuToogle}
                langsMenu={langsMenu}
                locale={locale}
              />
            </>
          ) : (
            <>
              <HamburgerButton isOpen={menuIsOpen} onClick={handleMenuToogle} />
              <NavigationDrawer
                isOpen={menuIsOpen}
                handleOpen={handleMenuToogle}
                langsMenu={langsMenu}
                locale={locale}
              />
            </>
          )}
        </div>
      </CSSTransition>
    </header>
  )
}

HorizontalNav.propTypes = {
  langsMenu: PropTypes.array,
  locale: PropTypes.string,
  homeLink: PropTypes.string,
  hideMenu: PropTypes.bool,
  className: PropTypes.string,
}

export default HorizontalNav
