import React, { useLayoutEffect, useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"

import { Accordion } from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import * as styles from "./styles.module.css"

const StyledAccordion = ({ className = "", children }) => {
  const [preExpanded, setPreExpanded] = useState([])
  const loc = useLocation()
  const elements = React.Children.map(children, (child, i) =>
    React.cloneElement(child, { index: i + 1 })
  )

  useLayoutEffect(() => {
    if (!loc?.hash) return

    setPreExpanded([decodeURI(loc.hash).substring(1)])
  }, [loc])

  return (
    <Accordion
      allowZeroExpanded
      preExpanded={preExpanded}
      key={preExpanded.length}
      className={classNames(styles.accordion, className)}
    >
      {elements}
    </Accordion>
  )
}

StyledAccordion.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default StyledAccordion
