import React from "react"
import { graphql } from "gatsby"

import Layout from "../../layouts/en/default"

import PriceCard, { PriceCardList } from "~components/PriceCard"
import Contacts from "~components/lendingSections/Contacts"
import { Inner } from "~components/Container"
import Headline from "~components/Headline"

import { seoDefaultData } from "~utils/seo"

import RACCIcon from "~images/service-icons/review-and-consultations-consultation.svg"
import RACARIcon from "~images/service-icons/review-and-consultations-audio-review.svg"

const AudioReviewsAndConsultations = ({ data, location }) => {
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }
  const adminEmail = data?.site?.siteMetadata?.adminEmail

  return (
    <Layout
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <div
        className="text-page-content"
        style={{
          margin: "6rem 1rem 2rem",
        }}
      >
        <Inner>
          <Headline Tag="h1">Audio Reviews & Consultations</Headline>
          <p>
            We worked on the audio of dozens of projects in the past few years,
            and at the same time, we didn’t intervene directly into another
            dozen. But it doesn’t mean that we didn’t take part in their
            creative process. We provided consultations and reviews to help
            indie developers and brands understand their products’ needs in
            terms of sound or enhance the existing audio to a proper quality
            level.
          </p>
          <p>
            This kind of work helps us spread our approach, share our visions
            with others, create meaningful connections, and help people create
            nice-sounding (and effective!) products. That’s why we decided that
            it’s time to introduce this service officially. So please welcome
            audio reviews and consultations!
          </p>
          <Headline Tag="h2">What do we do?</Headline>
          <p>
            Currently, we offer two types of consultations: audio consultations
            for a specific project and audio reviews.
          </p>
          <p>
            During the <b>audio consultation</b>, we discuss the project as a
            whole, notably its positioning. We highlight audio’s main performing
            tasks, help create a technical task, and discuss possible ideas. We
            also talk about best practices and point out what you should pay
            attention to while working on audio.
          </p>
          <p>
            The <b>audio review</b> includes game experience evaluation from an
            audio perspective, sound assessment of individual animations and/or
            cutscenes, and a document with suggestions for revisions.
          </p>
          <Headline Tag="h2">What is the process like?</Headline>
          <p>
            It all depends on the type of consultation you’re looking for. But
            in any case, it all starts with a detailed description of the
            project, including information about its target audience, market,
            and competitors that we carefully study beforehand. Also, any visual
            materials will come in handy: art, videos, animations, etc. And if
            by chance, you have a pitch document, that's more than enough!
          </p>
          <p>
            In the case of <b>audio consultations for a specific project</b>,
            after studying the provided information, we arrange an audio or
            video call via Google Meet or Zoom. Such consultation usually takes
            1.5 hours, but we can extend it or run several sessions if
            necessary.
          </p>
          <p>
            For the <b>audio review</b>, we also need a playable unlock build
            and a document with the names of all audio assets and links to the
            corresponding animations. We usually conduct a review in the text
            format, and as a result, you will receive a text document. We
            perform audio reviews within three business days.
          </p>

          <strong>
            Both types of consultation require full advance payment.
          </strong>

          <Headline Tag="h2">Our prices</Headline>
          <PriceCardList>
            <PriceCard
              icon={RACCIcon}
              title="Audio consultation for a specific project"
              list={[
                "discuss the project as a whole, its positioning",
                "highlight the main performing tasks of audio",
                "help to create a technical task",
                "discuss possible ideas and talk about best practices",
              ]}
              price="€100/hour"
            />

            <PriceCard
              icon={RACARIcon}
              title="Audio Review"
              list={[
                "game experience evaluation from an audio perspective",
                "sound assessment of individual animations and/or cutscenes",
                "creation of a document with suggestions for revisions",
              ]}
              price="€400*"
            />
          </PriceCardList>
          <p>
            *The cost of an audio review depends on the number of audio assets
            in the project.
          </p>
          <p>
            We also conduct three thirty-minute consultations or lite audio
            reviews every month for free.{" "}
            <a href={`mailto:${adminEmail}`}>Email us</a> to catch your slot.
          </p>
        </Inner>
      </div>
      <Contacts>
        Tell us about your project, product, or idea. Ask for advice, or get a
        free consultation. Find out the cost, specify a vital question, well
        anything you want! We will be happy to get to know you and help.
      </Contacts>
    </Layout>
  )
}

export const query = graphql`
  query AudioReviewsAndConsultationsPageEnQuery {
    site {
      siteMetadata {
        adminEmail
      }
    }
    seoPagesData: wpCustomPage(
      language: { code: { eq: EN } }
      seo: { pageSlug: { eq: "audio-reviews-and-consultations" } }
    ) {
      ...seoCustomPageData
    }
  }
`

export default AudioReviewsAndConsultations
