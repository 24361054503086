import React from "react"
import { graphql } from "gatsby"

import Layout from "../../layouts/uk/default"

import Post from "~components/Post"
import { trasfromPosts } from "~utils/post"
import Contacts from "~components/lendingSections/Contacts"
import SocialCards from "~components/SocialCards"
import { Inner } from "~components/Container"
import mappers from "../../mappers"

const PostTemplate = ({ data, location }) => {
  const { title, content, image, seo, translations, post_options } = data.wpPost

  const readMorePosts = trasfromPosts(
    post_options.readMore || data.readMorePosts.nodes
  )

  const dbtranslations = mappers.PostMapper.mapPostTranslations(translations)

  return (
    <Layout
      location={location}
      title={seo?.title || title}
      disableSiteNameInTitle
      description={seo?.description}
      translations={dbtranslations}
      noindex
    >
      <Post
        title={title}
        content={content}
        image={image?.node?.localFile?.childImageSharp?.fluid}
        readMorePosts={readMorePosts}
      />
      <Inner>
        <SocialCards />
      </Inner>
      <Contacts className="post-contacts" />
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: Int!) {
    wpPost(databaseId: { eq: $id }) {
      ...singlePost
    }
    readMorePosts: allWpPost(
      filter: { databaseId: { ne: $id }, language: { code: { eq: UK } } }
      limit: 3
    ) {
      ...blogListPost
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
