import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../layouts/uk/sidebar"

import ServiceHero from "~components/ServiceHero"
import ContactUs from "~components/ContactUs"
import Sidebar from "~components/Sidebar"
import ServiceSection from "~components/ServiceSection"
import ServiceIconList from "~components/ServiceIconList"
import ServiceIcon from "~components/ServiceIcon"
import StyledAccordion from "~components/StyledAccordion"
import StyledAccordionItem from "~components/StyledAccordionItem"
import ReviewCarousel from "~components/ReviewCarousel/ReviewCarousel"
import CardDeck from "~components/CardDeck/CardDeck"
import CardDeckItem from "~components/CardDeckItem/CardDeckItem"
import ModalContactForm from "~components/ModalContactForm"
import InlineButton from "~components/InlineButton"
import PriceCard, { PriceCardList } from "~components/PriceCard"

import GamesIcon from "~images/service-icons/games.svg"
import AudioIcon from "~images/service-icons/audio.svg"
import PhoneIcon from "~images/service-icons/phone-2.svg"
import PlayerIcon from "~images/service-icons/player-2.svg"
import MasksIcon from "~images/service-icons/masks.svg"

import MCMCIcon from "~images/service-icons/music-composing-music-composing.svg"
import MCRIcon from "~images/service-icons/music-composing-research.svg"

import mailTo from "~utils/mailTo"
import { seoDefaultData } from "~utils/seo"

const MusicCreation = ({ data, location }) => {
  const [showForm, setShowForm] = useState(false)

  const reviews = data.reviews.nodes
  const sidebarImage = data.sidebarImage.childImageSharp.fluid
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      Sidebar={
        <Sidebar
          title="Створення музики"
          text="Музика – посередник між духовним і чуттєвим життям людини."
          autor="Людвіг ван Бетховен"
          image={sidebarImage}
        />
      }
      ServiceHero={
        <ServiceHero>
          <h3>Найбільше ми любимо музику за її можливості.</h3>
          <p>
            Адже це найсильніший вид мистецтва, якщо порівнювати їх усі за
            емоційним впливом. Знаєте, як це буває, коли одна пісня змінює ваш
            настрій?
          </p>
          <p>
            Усього декілька нот, правильний ритмічний малюнок і ось уже людина
            готова до великих звершень. А варто трохи змінити аранжування — і
            вона раптово віддається сумним спогадам. Усе це захоплює нас і
            змушує кожен день робити те, що ми робимо: слухаємо, вивчаємо і,
            врешті-решт, створюємо музику.
          </p>
        </ServiceHero>
      }
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <ServiceSection title="Що ми робимо?">
        <p>Ми пишемо саундтреки і створюємо музику для:</p>

        <ServiceIconList>
          <ServiceIcon title="Iгор" icon={GamesIcon} />
          <ServiceIcon title="Брендів та реклами" icon={AudioIcon} />
          <ServiceIcon title="Анімацій" icon={PlayerIcon} />
          <ServiceIcon title="Застосунків" icon={PhoneIcon} />
          <ServiceIcon title="Театральних постановок" icon={MasksIcon} />
        </ServiceIconList>

        <p>
          Також ми створюємо музику для motion-графіки, але вона швидше належить
          до звукового дизайну, тож про неї ви можете прочитати{" "}
          <Link to="/works/audio-for-motion-design">ось тут</Link>.
        </p>
        <p>
          Ми не пишемо «мінусовки» та пісні для корпоративів. А ось бренд-треки
          — це ми залюбки, докладніше дивіться{" "}
          <Link to="/services/audio-branding">тут</Link>.
        </p>
      </ServiceSection>
      <ServiceSection title="Кращі саундтреки">
        <iframe
          title="Кращі саундтреки"
          width="100%"
          height="450"
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/174940453&color=%23f23b0d&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        ></iframe>
      </ServiceSection>
      <ServiceSection title="Як проходить процес?">
        <p>
          За останні кілька років ми розробили зрозумілу й ефективну схему
          співпраці. З одного боку, ми стежимо, щоби на кожному етапі у вас було
          чітке розуміння того, що відбувається, і повний контроль над ним. А з
          іншого — збираємо максимум інформації про ваш продукт, щоби розробити
          відповідну концепцію і створити музику, яка стане значущою частиною
          життя вашої аудиторії.
        </p>
        <StyledAccordion>
          <StyledAccordionItem title="Знайомство">
            <p>
              Ви заповнюєте бриф і надаєте список музичних ассетів, які нам буде
              потрібно створити. Ми призначаємо аудіо- або відеоконференцію в
              Google Meet або Zoom, під час якої знайомимося один з одним,
              обговорюємо проєкт і розуміємо, наскільки готові до співпраці. Для
              нас важливо збігатися з клієнтом у загальному баченні і принципах
              роботи.
            </p>

            <p>
              Якщо ви не можете ділитися інформацією про проєкт без NDA, то на
              початку спілкування ми підписуємо необхідні документи.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Юридичні формальності">
            <p>
              Ми уточнюємо обсяг проєкту, кількість і порядок платежів,
              підписуємо з вами договір і одержуємо аванс. Докладніше про
              фінансові та юридичні питання ви можете прочитати в нашому{" "}
              <Link to="/faq">FAQ</Link>.{" "}
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Препродакшн">
            <p>
              За роки роботи ми неодноразово переконалися, що препродакшн багато
              в чому визначає успіх усього проєкту. На цьому етапі ми більш
              детально вивчаємо ваш бриф і ставимо додаткові питання, проводимо
              рісерч, складаємо технічне завдання і створюємо{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/faq#audalinakontseptsya"
              >
                аудіальну концепцію
              </a>
              .
            </p>
            <p>
              Крім вашого проєкту ми вивчаємо вашу нішу, конкурентів, цільову
              аудиторію й на основі цієї інформації визначаємо завдання й
              майбутнє звучання музики. Допомагають нам у цьому{" "}
              <InlineButton onClick={() => setShowForm(true)}>
                перевірений роками темплейт ТЗ
              </InlineButton>{" "}
              і{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/faq#audaliniimudbord"
              >
                аудіальні мудборди
              </a>
              .
            </p>
            <p>
              Ми затверджуємо з вами готове технічне завдання й документ з
              аудіальною концепцією, за необхідності вносимо правки. Після цього
              ми одержуємо від вас другий платіж.
            </p>
          </StyledAccordionItem>
          <ModalContactForm
            title="Темплейт технічного завдання"
            subTitle="Ми впевнені, що правильно складене ТЗ підвищує шанси на успіх проєкту. Тому з радістю ділимося тим, що працює для нас!"
            open={showForm}
            onClose={() => setShowForm(false)}
          />
          <StyledAccordionItem title="Продакшн">
            <p>
              На цьому етапі багато чого залежить від обсягу та специфіки
              проєкту, але загалом усе зводиться до таких дій:
            </p>
            <ul>
              <li>
                Ми створюємо 30-секундне демо треку й відправляємо вам на
                фідбек, попередньо ознайомивши з дієвою методикою фідбеку. :)
              </li>
              <li>Затверджуємо з вами демо або складаємо список правок.</li>
              <li>
                На основі демо й коментарів допрацьовуємо композицію,
                відправляємо вам на фідбек.
              </li>
              <li>
                Затверджуємо з вами трек або вносимо нові правки — усього в нас
                передбачено 5 раундів безплатних правок.
              </li>
              <li>Фіналізуємо затверджену версію треку.</li>
            </ul>
            <p>
              Цикл повторюється залежно від кількості композицій. У великих і
              тривалих проєктах на цьому етапі може додатися ще один проміжний
              платіж.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Приймання проєкту">
            <p>
              Після всіх погоджень ми висилаємо вам кінцеві файли. Цей момент —
              останній шанс скористатися безплатними правками.
            </p>
            <p>
              Ви імплементуєте композиції у свій проєкт і надаєте нам до нього
              доступ, щоби ми могли переконатися, що все звучить правильно. У
              разі, коли щось пішло не за планом, ми складаємо список виправлень
              і стежимо, щоби ви змогли все налаштувати.
            </p>
            <p>
              Коли все готово й добре звучить, ми з вами підписуємо акт
              прийому-передачі і ви відправляєте нам фінальну частину оплати.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Підтримання">
            <p>
              Ми любимо стежити за розвитком наших партнерів і клієнтів. Часто
              після приймання проєкту робота тільки починається й ми завжди
              готові продовжити співпрацю й додати нового звучання продукту. У
              рамках розробленої аудіальної концепції, звичайно. :)
            </p>
          </StyledAccordionItem>
        </StyledAccordion>
      </ServiceSection>

      <ServiceSection title="Вартість">
        <p style={{ marginBottom: 60 }}>
          Ми багато експериментували з форматами цін на музику, спостерігали за
          відгуками наших партнерів і клієнтів, і згодом прийшли до єдиного
          стандарту. Такий варіант показав себе найдієвішим і комфортним для
          всіх сторін.
        </p>

        <PriceCardList>
          <PriceCard
            icon={MCRIcon}
            title="Рісерч і аудіальна концепція"
            list={[
              "глибинний брифінг",
              "аналіз ніші, конкурентів і цільової аудиторії",
              "складання технічного завдання",
              "розроблення аудіальної концепції",
            ]}
            price="€700"
          />

          <PriceCard
            icon={MCMCIcon}
            title="Створення музики"
            list={[
              "глибинний брифінг",
              "5 раундів безплатних правок",
              "майнові права інтелектуальної власності з обмеженням можливості перепродажу",
              "дистрибуція на стрімінгах",
            ]}
            price="€700/хв"
          />
        </PriceCardList>

        <p>
          Етап рісерча і створення аудіальної концепції — обов’язкова частина
          процесу створення музики, адже саме він визначає завдання й майбутнє
          звучання треків. Але ви можете заощадити на цій послузі, якщо відразу
          надасте нам детальну інформацію про проєкт, включно з:
        </p>
        <ul>
          <li>позиціонуванням вашого продукту;</li>
          <li>його точками диференціації;</li>
          <li>списком конкурентів із прикладами їхнього аудіо;</li>
          <li>
            інформацією, як конкуренти використовують музику в маркетингових
            цілях;
          </li>
          <li>портретом(ами) вашої цільової аудиторії;</li>
          <li>потребами й уподобаннями вашої цільової аудиторії;</li>
          <li>кращими практиками й аудіальними кліше ніші.</li>
        </ul>
        <p>
          Сума мінімального замовлення на створення музики — €700. Це означає,
          що, якщо вам потрібен трек на 30, 45 або 52 секунди, його вартість у
          будь-якому випадку дорівнюватиме вартості однієї хвилини.
        </p>
      </ServiceSection>

      <ServiceSection title="Наш підхід">
        <CardDeck>
          <CardDeckItem variant="rect" textAlign="left">
            Багато людей вважають створення музики чимось незбагненним і
            таємничим. А деякі навпаки стверджують, що вся справа — тільки в
            техніці та правильній методології. Нам же пощастило, адже за роки
            практики ми знайшли свою робочу формулу.
          </CardDeckItem>
          <CardDeckItem variant="full-waves" textAlign="left">
            Головне — подружити дисципліну, креативність і системний підхід.
          </CardDeckItem>
          <CardDeckItem variant="waves" textAlign="left">
            Коли ми починаємо роботу над проєктом, ми з’ясовуємо якомога більше
            про нього. Ми дізнаємося його атмосферу, настрій і ідею. Ми вивчаємо
            ринок, конкурентів, і, звичайно ж, цільову аудиторію та її інтереси.
          </CardDeckItem>
          <CardDeckItem variant="rect-revers" textAlign="center">
            З одного боку, уся ця інформація допомагає нам краще перейнятися
            продуктом, сформувати свою думку і ставлення до нього. А з іншого —
            визначає, наскільки точно й ефективно музика впливатиме на кінцевого
            слухача.
          </CardDeckItem>
          <CardDeckItem variant="full-waves" textAlign="center">
            Далі — справа за дисципліною й технікою. Завдяки зібраним даним ми
            не чекаємо на натхнення, а методично створюємо «ядро», навколо якого
            пізніше вибудовуємо все інше.
          </CardDeckItem>
          <CardDeckItem variant="circle" textAlign="center">
            Творчо й надійно.
          </CardDeckItem>
        </CardDeck>
      </ServiceSection>
      <ServiceSection title="Відгуки клієнтів і партнерів">
        <ReviewCarousel reviews={reviews} />
      </ServiceSection>
      <div className="contact-us-wrapper">
        <ContactUs>
          Розкажіть нам про свій проєкт, продукт або ідею. Запитайте пораду або
          отримайте{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.uk}
          >
            безплатну консультацію
          </a>
          . Дізнайтеся вартість, уточніть важливе питання, та що завгодно! Ми
          будемо раді з вами познайомитися й допомогти.
        </ContactUs>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MusicCreationUKPageQuery {
    seoPagesData: wpCustomPage(
      language: { code: { eq: UK } }
      seo: { pageSlug: { eq: "services/music-composing" } }
    ) {
      ...seoCustomPageData
    }
    reviews: allWpClientReview(
      limit: 5
      filter: {
        meta: { musicComposing: { musicComposingVisibility: { eq: true } } }
        language: { code: { eq: UK } }
      }
    ) {
      ...serviceClientReviews
    }
    sidebarImage: file(relativePath: { eq: "services/music-creation.jpg" }) {
      ...sidebarImage
    }
    sitePage {
      id
      path
    }
  }
`

export default MusicCreation
