// extracted by mini-css-extract-plugin
export var navigation = "styles-module--navigation--Ntxgp";
export var container = "styles-module--container--JBVm9";
export var topLine = "styles-module--topLine--ZVF4y";
export var next = "styles-module--next---DD79";
export var prev = "styles-module--prev--aE6Wq";
export var disabled = "styles-module--disabled--MG7b4";
export var icon = "styles-module--icon--yBIrn";
export var label = "styles-module--label--PfWqD";
export var list = "styles-module--list--Oh+N-";
export var mobile = "styles-module--mobile--K0QUT";
export var desktop = "styles-module--desktop--kuLRf";