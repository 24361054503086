import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../layouts/ru/sidebar"
import ServiceHero from "../../components/ServiceHero"
import ContactUs from "../../components/ContactUs"
import Sidebar from "../../components/Sidebar"
import ServiceSection from "../../components/ServiceSection"
import ServiceIconList from "../../components/ServiceIconList"
import ServiceIcon from "../../components/ServiceIcon"
import StyledAccordion from "../../components/StyledAccordion"
import StyledAccordionItem from "../../components/StyledAccordionItem"
import ReviewCarousel from "../../components/ReviewCarousel/ReviewCarousel"
import CardDeck from "../../components/CardDeck/CardDeck"
import CardDeckItem from "../../components/CardDeckItem/CardDeckItem"
import WorkCards from "../../components/WorkCards"

import PlayerIcon from "../../images/service-icons/player.svg"
import RoomIcon from "../../images/service-icons/room.svg"
import GamesIcon from "../../images/service-icons/games.svg"

import mailTo from "../../utils/mailTo"
import { seoDefaultData } from "../../utils/seo"
import Button from "~components/Button"
import InlineButton from "~components/InlineButton"
import ModalContactForm from "~components/ModalContactForm"

const SoundDesign = ({ data, location }) => {
  const [showForm, setShowForm] = useState(false)

  const reviews = data.reviews.nodes
  const sidebarImage = data.sidebarImage.childImageSharp.fluid
  const works = data.works.nodes
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      Sidebar={
        <Sidebar
          title="Звуковой дизайн"
          text="При создании игр, как и при съёмке кино, звук имеет очень большое значение. 60 или даже 70% ощущений создаётся именно за счёт звука. При этом звук – это не только музыкальное сопровождение, но и звуковые эффекты. Их наличие или отсутствие очень сильно определяет атмосферу игры."
          autor="Хидео Кодзима"
          image={sidebarImage}
        />
      }
      ServiceHero={
        <ServiceHero>
          <h3>
            Если музыка — это искусство, то звук — изначально способ передачи
            информации.
          </h3>
          <p>
            Притом, один из самых древних. Но это не значит, что музыка — только
            эмоциональна, а звук — только информативен. Точно как и музыка,
            секундный звук способен напрямую воздействовать на эмоции человека.
          </p>
          <p>
            Хотим мы этого или нет, но тревожный звук заставит нас
            насторожиться. Именно за подобные вещи мы и обожаем звуковой дизайн.
            А ещё за то, как он преображает любую картинку и помогает зрителю
            поверить в происходящее. Магия!
          </p>
        </ServiceHero>
      }
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <ServiceSection title="Что мы делаем?">
        <p>Мы создаём:</p>
        <ServiceIconList>
          <ServiceIcon
            title="Звуковые эффекты, эмбиенты и джинглы для видеоигр"
            icon={GamesIcon}
            style={{ maxWidth: 260 }}
          />
          <ServiceIcon
            title="Звуковое оформление видео"
            icon={PlayerIcon}
            style={{ maxWidth: 260 }}
          />
          <ServiceIcon
            title="Звуковое оформление пространства (квест-комнаты, инсталляции и т.д.)"
            icon={RoomIcon}
            style={{ maxWidth: 260 }}
          />
        </ServiceIconList>
        <p>
          Каждый проект мы начинаем с изучения его целевой аудитории и
          контекста, в котором он будет звучать. Благодаря этому мы определяем
          задачи, которые будут выполнять звуки. Такой подход позволяет нам
          создать не просто набор отдельных ассетов, а эффективную гармоничную
          систему.
        </p>
        <p>
          Мы заранее обсуждаем нюансы будущей имплементации звуков, продумываем,
          где и как они будут воспроизводиться, и учитываем эту информацию во
          время их создания.
        </p>
        <p>
          Кстати, звуковой дизайн для приложений мы тоже делаем, но подходим к
          его разработке ещё более основательно. В этом случае речь идёт скорее
          о звуковой айдентике – прочесть об этом подробнее вы можете{" "}
          <Link to="/ru/services/audio-branding">
            на странице аудиобрендинга
          </Link>
          .
        </p>
      </ServiceSection>
      <ServiceSection title="Наши работы">
        <WorkCards
          works={works}
          breakpoints={{
            default: 3,
            1100: 3,
            700: 3,
            500: 2,
          }}
        />
        <div className="service-works-porfolio-button-container">
          <Button
            state={{
              service: "sound-design",
            }}
            to="/ru/portfolio?service=sound-design-2"
            className="service-works-porfolio-button"
          >
            Портфолио
          </Button>
        </div>
      </ServiceSection>
      <ServiceSection title="Как проходит процесс?">
        <p>
          За последние несколько лет мы выработали понятную и эффективную схему
          сотрудничества. С одной стороны, мы следим, чтобы на каждом этапе у
          вас было чёткое понимание происходящего и полный контроль над ним. А с
          другой — собираем максимум информации о вашем продукте, чтобы
          разработать подходящую концепцию и создать аудио, которое станет
          значимой частью жизни вашей аудитории.
        </p>
        <StyledAccordion>
          <StyledAccordionItem title="Знакомство">
            <p>
              Вы заполняете бриф и предоставляете список звуковых ассетов,
              которые нам будет нужно создать. Мы назначаем аудио- или
              видеоконференцию в Google Meet или Zoom, на которой знакомимся
              друг с другом, обсуждаем проект и понимаем, насколько готовы
              сотрудничать. Для нас важно совпадать с клиентом в общем видении и
              принципах работы.
            </p>
            <p>
              Если вы не можете делиться информацией о проекте без NDA, то в
              самом начале общения мы подписываем необходимые документы.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Юридические формальности">
            <p>
              Мы уточняем объём проекта, количество и порядок платежей,
              подписываем с вами договор и получаем аванс. Детальнее о
              финансовых и юридических вопросах вы можете прочесть в нашем{" "}
              <Link to="/ru/faq">FAQ</Link>.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Препродакшн">
            <p>
              За годы работы мы не один раз убедились, что препродакшн во многом
              определяет успех всего проекта. На этом этапе мы более детально
              изучаем ваш бриф и задаём дополнительные вопросы, проводим рисёрч,
              составляем техническое задание и создаём{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/ru/faq#audialinayakontseptsiya"
              >
                аудиальную концепцию
              </a>
              .
            </p>
            <p>
              Кроме вашего проекта мы изучаем вашу нишу, конкурентов, целевую
              аудиторию и на этой основе определяем задачи и будущее звучание
              музыки. Помогают нам в этом{" "}
              <InlineButton onClick={() => setShowForm(true)}>
                проверенный годами темплейт ТЗ
              </InlineButton>{" "}
              и{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/ru/faq#audialiniimudbord"
              >
                аудиальные мудборды
              </a>
              .{" "}
            </p>
            <p>
              Мы утверждаем с вами готовое техническое задание и документ с
              аудиальной концепцией, при необходимости вносим правки. После
              этого мы получаем от вас второй платёж.
            </p>
          </StyledAccordionItem>

          <StyledAccordionItem title="Продакшн">
            <p>
              На этом этапе многое зависит от размера и специфики проекта, но в
              целом всё сводится к следующим действиям:
            </p>
            <ul>
              <li>
                Мы создаём 3-5 тестовых звуков и отправляем вам на фидбек,
                предварительно ознакомив с действенной методикой фидбека. :)
              </li>
              <li>Утверждаем звучание с вами либо составляем список правок.</li>
              <li>
                На основе тестовых звуков и комментариев мы дорабатываем
                следующий пак ассетов, отправляем вам на фидбек.
              </li>
              <li>
                Утверждаем с вами звуки либо вносим новые правки — всего у нас
                предусмотрено 5 раундов бесплатных правок.
              </li>
            </ul>
            <p>
              Цикл повторяется в зависимости от количества паков звуков. В
              больших и длительных проектах на этом этапе может добавиться ещё
              один промежуточный платёж.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Приём проекта">
            <p>
              После всех согласований мы высылаем вам конечные файлы. Этот
              момент — последний шанс воспользоваться бесплатными правками.
            </p>
            <p>
              Вы имплементируете звуки в свой проект и даёте нам к нему доступ,
              чтобы мы могли убедиться, что всё звучит правильно. В случае,
              когда что-то пошло не по плану, мы составляем список исправлений и
              следим, чтобы вы смогли всё настроить.
            </p>
            <p>
              Когда всё готово и хорошо звучит, мы с вами подписываем акт
              приёма-передачи и вы отправляете нам финальную часть оплаты.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Поддержка">
            <p>
              Мы любим следить за развитием наших партнёров и клиентов. Часто
              после приёма проекта работа только начинается и мы всегда готовы
              продолжить сотрудничество и добавить нового звучания продукту. В
              рамках изначально разработанной аудиальной концепции, конечно. :)
            </p>
          </StyledAccordionItem>
        </StyledAccordion>
      </ServiceSection>

      <ModalContactForm
        title="Темплейт технического задания"
        subTitle="Мы уверены, что правильно составленное ТЗ повышает шансы на успех проекта. Поэтому с радостью делимся тем, что работает для нас!"
        open={showForm}
        onClose={() => setShowForm(false)}
      />

      <ServiceSection title="Стоимость">
        <p>Звуковой эффект (sfx) — €40;</p>
        <p>Джингл (короткая музыкальная фраза) — €60;</p>
        <p>Эмбиент (комплексный звук окружения) — €80.</p>
        <p>
          Создание аудио для видеороликов (музыка + звуковой дизайн) –
          €1500/мин.
        </p>
        <p>В стоимость всех звуковых ассетов входит:</p>
        <ul>
          <li>5 раундов бесплатных правок;</li>
          <li>
            исключительные имущественные права интеллектуальной собственности;
          </li>
          <li>помощь в имплементации и тестировании.</li>
        </ul>
        <p>
          Минимальная сумма заказа — €400.{" "}
          <a href={mailTo.soundDesign.ru}>Напишите нам</a> и мы ответим на любые
          ваши вопросы и поможем с просчётом проекта.{" "}
          <span role="img" aria-label="руки">
            🙌
          </span>
        </p>
      </ServiceSection>

      <ServiceSection title="Наш подход">
        <CardDeck>
          <CardDeckItem variant="rect" textAlign="left">
            Для нас важно заставить каждый элемент звучать так, чтобы у
            слушателя не возникало сомнений, что это его «родной» звук. Каждый
            эффект мы создаём с мыслью о чувствах, которые он должен вызывать у
            человека.
          </CardDeckItem>
          <CardDeckItem variant="full-waves" textAlign="left">
            Будет ли звук напрягать его? Заставит ли почувствовать облегчение?
            Или предупредит о чём-то? Мы продумываем каждую деталь. Особенно то,
            как будут звучать все звуки вместе. Как они будут взаимодействовать
            друг с другом и общим фоном — эмбиентом или музыкой.
          </CardDeckItem>
          <CardDeckItem variant="waves" textAlign="left">
            Мы вместе с вами хотим быть уверенными в финальном звучании проекта.
            Поэтому мы внимательно отслушиваем, как работает звуковой дизайн в
            контексте продукта и помогаем нашим клиентам настроить уровни
            громкости, количество одновременных воспроизведений, рандомизацию,
            вариативность ассетов и другие важные моменты.
          </CardDeckItem>
        </CardDeck>
      </ServiceSection>

      <ServiceSection title="Отзывы клиентов и партнёров">
        <ReviewCarousel reviews={reviews} />
      </ServiceSection>

      <div className="contact-us-wrapper">
        <ContactUs>
          Расскажите нам о своём проекте, продукте или идее. Спросите совет или
          получите{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.ru}
          >
            бесплатную консультацию
          </a>
          . Узнайте цены, уточните важный вопрос, да что угодно! Мы будем рады с
          вами познакомиться и помочь.
        </ContactUs>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SoundDesignRuPageQuery {
    seoPagesData: wpCustomPage(
      language: { code: { eq: RU } }
      seo: { pageSlug: { eq: "services/sound-design" } }
    ) {
      ...seoCustomPageData
    }
    reviews: allWpClientReview(
      limit: 5
      filter: {
        meta: { soundDesign: { soundDesignVisibility: { eq: true } } }
        language: { code: { eq: RU } }
      }
    ) {
      ...serviceClientReviews
    }
    works: allWpWork(
      filter: {
        language: { code: { eq: RU } }
        meta: { soundDesign: { soundDesignVisibility: { eq: true } } }
      }
      sort: { fields: meta___soundDesign___soundDesignOrder, order: DESC }
    ) {
      ...serviceWorks
    }
    sidebarImage: file(relativePath: { eq: "services/sound-design.jpg" }) {
      ...sidebarImage
    }
    sitePage {
      id
      path
    }
  }
`

export default SoundDesign
