import React from "react"
import classnames from "classnames"

import * as styles from "./styles.module.css"
import { Link } from "gatsby"

const Filter = ({
  link = "",
  label = "",
  color = "#fff",
  backgroundColor = "#000",
  reset = false,
  disabled = false,
}) => {
  return (
    <Link
      style={{ color, backgroundColor }}
      className={classnames(styles.link, {
        [styles.reset]: reset,
        [styles.disabled]: disabled,
      })}
      activeClassName={styles.active}
      tabIndex={disabled ? "-1" : "0"}
      partiallyActive
      to={link}
    >
      {label}
    </Link>
  )
}

export default Filter
