import React, { useState } from "react"
import * as styles from "./styles.module.css"
import Layout from "../../layouts/uk/default"
import Contacts from "../../components/lendingSections/Contacts"
import Headline from "../../components/Headline"
import { Inner } from "../../components/Container"
import StyledAccordion from "../../components/StyledAccordion"
import StyledAccordionItem from "../../components/StyledAccordionItem"
import boxOfDotsLink from "../../images/icon-box.svg"
import classNames from "classnames"

import mailTo from "../../utils/mailTo"
import ModalContactForm from "../../components/ModalContactForm/ModalContactForm"
import InlineButton from "../../components/InlineButton"
import { Link, graphql } from "gatsby"
import schemaOrg from "./schemaOrg.json"
import { seoDefaultData } from "../../utils/seo"

const Boxes = ({ className = "" }) => (
  <img
    className={classNames(styles.box, className)}
    src={boxOfDotsLink}
    role="presentation"
    alt=""
  />
)

const Faq = ({ data, location }) => {
  const [showFrom, setShowForm] = useState(false)
  const contactEmail = data.site.siteMetadata.adminEmail
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
      schemaOrg={schemaOrg.uk}
    >
      <Inner>
        <Headline Tag="h1" className={styles.title}>
          faq
        </Headline>

        <Boxes className={styles.boxFirst} />

        <Boxes className={styles.boxSecond} />

        <Headline Tag="h2" className={styles.subtitle}>
          Організаційні питання
        </Headline>
        <StyledAccordion className={styles.accordeon}>
          <StyledAccordionItem title="Що потрібно аби ви могли почати роботу?">
            <p>
              Спершу нам потрібно познайомитися з вами і вашим проєктом. Для
              цього ви заповнюєте бриф, а ми проводимо для вас{" "}
              <a
                rel="nofollow noopener noreferrer"
                target="_blank"
                href={mailTo.mail.uk}
              >
                безплатну консультацію
              </a>
              . Якщо ми з вами збігатемося характерами, залишиться тільки
              скласти технічне завдання й підписати договір.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="А що робити, якщо мій проєкт під NDA?">
            <p>
              <a
                rel="nofollow noopener noreferrer"
                target="_blank"
                href={`maito:${contactEmail}`}
              >
                Напишіть нам
              </a>
              , ми підпишемо відповідні документи і зможемо вільно все
              обговорити.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Ви виконуєте тестові завдання?">
            <p>
              Зазвичай ми не виконуємо тестові завдання, адже вони не зовсім відповідають нашому підходу й робочому пайплайну. 
              Ми починаємо кожен проєкт із рісерча та створення аудіальної концепції, і ми не бачимо можливості «подружити» цей етап 
              із власне концепцією тестового завдання.
            </p>

            <p>
             Ми розуміємо, що для багатьох людей тестові завдання, насамперед, спосіб краще познайомитися з новою командою 
             і зрозуміти, наскільки загалом можлива співпраця. Для нас не менш важливо збігатися з клієнтом у загальному баченні
             та принципах роботи.
            </p>

            <p>
             Тому ми приділяємо чимало часу попередній комунікації з клієнтом, розповідаємо про всі нюанси майбутньої
             роботи, обговорюємо проєкт і його потреби, ділимося першими ідеями та демонструємо релевантні кейси з нашої практики.
            </p>
            
             <p>
             Однак проєкти з голосовим озвученням — виняток, у цьому випадку ми з радістю надамо вам тестовий запис обраного актора озвучення.
             </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Хто складає технічне завдання на проєкт?">
            <p>
              В ідеалі, клієнт ініціює процес створення технічного завдання, складаючи список необхідних аудіо ассетів із відповідним 
              візуалом/анімаціями й основними вимогами. Для зручності ви можете скористатися{" "}
              <InlineButton onClick={() => setShowForm(true)}>
                нашим перевіреним часом шаблоном
              </InlineButton>.
            </p>
            
            <p>
            Потім ми допрацьовуємо документ на основі аналізу брифа клієнта, вивчення його ніші, конкурентів і цільової аудиторії.
            </p>
            
            <p>
            У такий спосіб обидві сторони від самого початку мають чітко сформовані очікування від результатів. Це допомагає вибрати правильний 
            напрям і закласти міцний фундамент для успішної співпраці.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Як організована решта процесу роботи?">
            <p>
              Залежно від обраної послуги процес може варіюватися, хоча основні
              6 стадій залишаються незмінними: знайомство, підготовка, запуск,
              продакшн, прийом проєкту і його подальша підтримка.
            </p>

            <p>
              Як організований процес роботи для кожної з наших послуг, можна
              детально прочитати на сторінках{" "}
              <Link to="/services/music-composing">створення музики</Link>,{" "}
              <Link to="/services/sound-design">звуковий дизайн</Link>,{" "}
              <Link to="/services/audio-branding">аудіобрендинг</Link> і{" "}
              <Link to="/services/voice-casting">голосове озвучення</Link>.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>
        <ModalContactForm
          title="Темплейт технічного завдання"
          subTitle="Ми впевнені, що правильно складене ТЗ підвищує шанси на успіх проєкту. Тому з радістю ділимося тим, що працює для нас!"
          open={showFrom}
          onClose={() => setShowForm(false)}
        />
        <Headline Tag="h2" className={styles.subtitle}>
          Фінансові питання
        </Headline>

        <StyledAccordion className={styles.accordeon}>
          <StyledAccordionItem title="Ви працюєте за передоплатою?">
            <p>
             Так, ми працюємо за передоплатою. Сума передоплати може варіюватися залежно від масштабу проєкту, його тривалості та обраних послуг. 
             Це може бути 50% від загальної суми, кілька невеликих платежів або повна передоплата в деяких випадках.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="У яких випадках передоплата становить 100 %?">
            <p>
              Повна передоплата потрібна у разі замовлення голосового озвучення та будь-якого виду консультацій.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Як можна оплатити ваші послуги?">
            <p>
              Ми приймаємо оплату за надані послуги тільки на розрахунковий
              рахунок фізичної особи підприємця (ФОП) або на рахунок Payoneer.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>

        <Headline Tag="h2" className={styles.subtitle}>
          Юридичні питання
        </Headline>

        <StyledAccordion className={styles.accordeon}>
          <StyledAccordionItem title="Чи є у вас договір?">
            <p>
              Так, із кожним клієнтом ми укладаємо договір про надання послуг.
              Він у нас крутий і крафтовий — усе завдяки команді юристів, з якою
              ми співпрацюємо.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Які права на результат я отримаю?">
            <p>
              На створені нами звукові ефекти, голосове озвучення та елементи
              аудіо бренда ми за замовчуванням передаємо вам усі виняткові
              майнові права інтелектуальної власності, звичайно, з однією умовою
              — після оплати наших послуг.
            </p>

            <p>
              На створені нами музичні композиції ми за замовчуванням передаємо
              вам майнові права інтелектуальної власності, з обмеженням
              можливості вільного їх перепродажу й перевипуску, поза
              обумовленого нами проєкту, а також отримання монетизації зі
              стрімінгових сервісів і маркетплейсів.
            </p>

            <p>
              За необхідності ми можемо передати вам усі виключні майнові права
              інтелектуальної власності без будь-яких обмежень, але це слід
              обговорювати окремо й у такому випадку загальна вартість проєкту
              буде вище.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Чому ви просто не передаєте всі виняткові права на музику?">
            <p>
              Іноді саундтреки стають додатковим засобом монетизації проєкту і
              джерелом надприбутку. Ми вважаємо справедливим, що в подібних
              випадках композитор має право на додаткову компенсацію.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>

        <Headline Tag="h2" className={styles.subtitle}>
          Питання щодо консультацій
        </Headline>

        <StyledAccordion className={styles.accordeon}>
          <StyledAccordionItem title="Які у вас є різновиди консультацій?">
            <p>
              Наразі ми надаємо консультації двох видів: консультація щодо аудіо конкретного проєкту та аудіо-рев’ю.
            </p>
            <p>
              На консультації щодо аудіо ми обговорюємо з вами проєкт загалом,
              його позиціонування, виділяємо основні завдання аудіо, допомагаємо
              скласти технічне завдання, обговорюємо ймовірні ідеї для
              реалізації, розповідаємо про кращі практики та про те, чому варто
              приділити увагу під час роботи над аудіо.
            </p>
            <p>
              Аудіо-рев’ю проєкту включає в себе оцінювання аудіального ігрового
              досвіду, оцінювання звучання окремих анімацій та/або катсцен,
              складання документу з пропозиціями щодо виправлень.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Яку інформацію необхідно підготувати для консультації?">
            <p>
              Для обох видів консультацій ми маємо заздалегідь ознайомитися з
              детальним описом проєкту, включно з інформацією про його цільову
              аудиторію, ринок і конкурентів. Крім того, у нагоді стануть
              будь-які наявні візуальні матеріали: арти, відео, анімації тощо.
              Загалом, якщо у вас є пітч-документ — це ідеально!
            </p>

            <p>
              Для аудіо-рев’ю нам також знадобляться іграбельний unlock-білд і
              документ із назвами всіх аудіо-асетів та посиланнями на відповідні
              озвучені анімації.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Скільки тривають консультації?">
            <p>
              Консультація щодо аудіо для конкретного проєкту зазвичай триває
              1,5 години, але за необхідності ми можемо її подовжити, або ж
              провести декілька сесій.
            </p>
            <p>Аудіо-рев’ю ми виконуємо упродовж трьох робочих днів.</p>
          </StyledAccordionItem>
          <StyledAccordionItem title="У якому форматі проходять консультації?">
            <p>
              Аудіо-рев’ю ми зазвичай проводимо в текстовому форматі й в
              результаті ви отримуєте текстовий документ.
            </p>
            <p>
              Консультацію щодо аудіо для конкретного проєкту ми проводимо у
              форматі аудіо- чи відеоконференції у Google Meet або Zoom.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Скільки коштує кожен різновид консультацій?">
            <p>
             Вартість консультації щодо аудіо конкретного проєкту складає €100/год.
            </p>
            <p>Вартість аудіо-рев’ю складає €400.</p>
            <p>
              Крім того, кожного місяця ми проводимо три безплатних
              тридцятихвилинних консультації або лайт аудіо-рев’ю.{" "}
              <a
                rel="nofollow noopener noreferrer"
                target="_blank"
                href={`maito:${contactEmail}`}
              >
                Напишіть нам
              </a>
              , аби вхопити свій слот.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>

        <Headline Tag="h2" className={styles.subtitle}>
          Глосарій
        </Headline>

        <StyledAccordion className={styles.accordeon}>
          <StyledAccordionItem title="Аудіоадаптаціі">
            <p>
              Музичні чи звукові фрагменти, які створюються на основі аудіо-ДНК
              і розробляються з урахуванням специфічних потреб кожної точки
              контакту бренду з аудиторією.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудіо бренд">
            <p>
              Система музики і звуків, яка транслює сенс бренду в кожній його
              точці контакту з аудиторією.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудіобрендинг">
            <p>
              Також відомий як звуковий, акустичний і музичний брендинг. Процес
              створення й управління системою музики і звуків, яка транслює сенс
              бренду в кожній його точці контакту з аудиторією.
            </p>
            <p>
              Це така ж важлива частина брендингу, як і створення фірмового
              стилю. Тільки тут головні інструменти — музика, звуки й голос.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудіальна концепція">
            <p>
              Документ, який містить позиціонування бренду, список його
              аудіальних точок контакту з аудиторією, а також ідеї про те, як
              передати конкретне повідомлення бренду і виконати необхідні
              завдання за допомогою аудіо.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудіо-ДНК">
            <p>
              Центральний елемент кожного аудіо бренда. Аудіо «колаж» або
              короткий повноцінний трек, який втілює обіцянку, цінності та
              індивідуальність бренду в аудіальній формі, а також визначає, як
              будуть звучати всі точки контакту бренду з аудиторією. Він
              визначає музичну «лексику» бренду: інструменти, гармонії, темп,
              ритмічні малюнки, тембр звуку, голосу тощо
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудіо логотип">
            <p>
              Звук тривалістю 2–3 секунди й найважливіша частина аудіо-ДНК. Він
              привертає увагу слухачів і транслює обіцянку, цінності та
              індивідуальність бренду, водночас він розроблений так, щоби
              надзвичайно легко запам’ятовуватися.
            </p>
            <p>
              Аудіо логотип — це прямий аналог візуального логотипу в музичній
              формі.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудіальний мудборд">
            <p>
              Плейлист, який демонструє різні способи передачі певного
              повідомлення, сенсу або ідеї.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудіо стайлгайд">
            <p>
              Частина брендбуку компанії або окремий документ, який містить опис
              та правила використання аудіо-ДНК, усіх його адаптацій і інших
              елементів аудіо бренду.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудіальні точки контакту">
            <p>
              Точки контакту співробітників і клієнтів із брендом, які можуть
              звучати. Вони мають бути уніфіковані за звучанням, щоби слухачі
              завжди могли отримувати позитивний і передбачуваний досвід
              взаємодії з брендом.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Джингл">
            <p>
              Коротка музично-вокальна фраза, яка найчастіше явно рекламує
              продукт або послугу й майже завжди містить слоган компанії.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Звукова ідентичність">
            <p>
              Сукупність звукових вражень, які створює бренд або продукт. Вона
              має бути як мінімум послідовною і як максимум передавати бажаний
              сенс, обіцянку й цінності.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>

        <Contacts>
          Не знайшли відповідь на важливе для вас питання? Обов’язково запитайте
          його в нас — ми будемо раді познайомитися з вами й допомогти.
        </Contacts>
      </Inner>
    </Layout>
  )
}

export const query = graphql`
  query FAQPageUkQuery {
    site {
      siteMetadata {
        adminEmail
      }
    }
    seoPagesData: wpCustomPage(
      language: { code: { eq: UK } }
      seo: { pageSlug: { eq: "faq" } }
    ) {
      ...seoCustomPageData
    }
  }
`

export default Faq
