import React from "react"

import * as styles from "./SubscriptionForm.module.css"
import { graphql, useStaticQuery } from "gatsby"
import FormGroup from "~components/FormGroup"
import FormButton from "~components/FormButton"
import { useFormik } from "formik"
import { object, string } from "yup"
import { toast } from "react-toastify"
import {
  injectIntl,
  intlShape,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl"
import axios from "axios"
import { Helmet } from "react-helmet"

const saveNewSub = async (name, email, lang, tag, token) => {
  try {
    const response = await axios(process.env.GATSBY_SUB_ENDPOINT, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      data: {
        name,
        email,
        token,
        lang,
        tag,
      },
    })

    if (response.status === 200) {
      return {
        status: "Ok",
      }
    }

    return {
      status: "Error",
    }
  } catch (error) {
    return {
      status: "Error",
    }
  }
}
/**
 * NOTE!: field "other" - bot protection
 *
 *
 *
 */
const Form = injectIntl(({ intl, tag }) => {
  const recaptchaSiteKey = process.env.GATSBY_RECAPTCHA_V3_SITE_KEY

  const validationSchema = object({
    other: string(),
    firstName: string().required(intl.formatMessage({ id: "required" })),
    email: string()
      .email(intl.formatMessage({ id: "invalidemail" }))
      .required(intl.formatMessage({ id: "required" })),
  })

  const submitData = async ({ other, firstName, email }, actions, token) => {
    if (!other) {
      const result = await saveNewSub(firstName, email, intl.locale, tag, token)

      if (result.status === "Ok") {
        toast(intl.formatMessage({ id: "successMailSend" }), {
          type: "success",
        })
      } else {
        toast(intl.formatMessage({ id: "errorMailSend" }), {
          type: "error",
        })
      }
    }

    actions.setSubmitting(false)
    actions.resetForm()
  }

  const formik = useFormik({
    initialValues: {
      other: "",
      firstName: "",
      email: "",
    },
    onSubmit: async (values, actions) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(recaptchaSiteKey, { action: "submit" })
          .then(token => {
            submitData(values, actions, token)
          })
      })
    },
    validationSchema,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Helmet>
        <script
          key="recaptcha"
          type="text/javascript"
          src={`https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`}
        />
      </Helmet>
      <input
        type="text"
        name="other"
        value={formik.values.other}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        hidden
      />
      <FormGroup
        name="firstName"
        label={intl.formatMessage({ id: "firstName" })}
        type="text"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.firstName}
        error={formik.touched.firstName && formik.errors.firstName}
        fullWidth
      />
      <FormGroup
        name="email"
        label="Email"
        type="email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={formik.touched.email && formik.errors.email}
        fullWidth
      />

      <FormattedHTMLMessage id="subscriptionPrivacy" />
      <FormButton type="submit" loading={formik.isSubmitting} fullWidth>
        {intl.formatMessage({ id: "subscriptionButton" })}
      </FormButton>
    </form>
  )
})

Form.propTypes = {
  intl: intlShape,
}

const AMDIN_MAIL = graphql`
  query {
    site {
      siteMetadata {
        adminEmail
      }
    }
  }
`

const SubscriptionForm = ({ tag = "" }) => {
  const data = useStaticQuery(AMDIN_MAIL)

  const adminEmail = data.site?.siteMetadata?.adminEmail

  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.title}>
          <FormattedMessage id="blog.contact.title" />
        </h2>
        <div className={styles.text}>
          <FormattedHTMLMessage id="blog.contact.text" />
        </div>
        <a className={styles.mailLink} href={`mailto:${adminEmail}`}>
          {adminEmail}
        </a>
      </div>
      <div>
        <Form tag={tag} />
      </div>
    </div>
  )
}

export default SubscriptionForm
