import React, { useState } from "react"
import * as styles from "./styles.module.css"
import Layout from "../../layouts/en/default"
import Contacts from "../../components/lendingSections/Contacts"
import Headline from "../../components/Headline"
import { Inner } from "../../components/Container"
import StyledAccordion from "../../components/StyledAccordion"
import StyledAccordionItem from "../../components/StyledAccordionItem"
import boxOfDotsLink from "../../images/icon-box.svg"
import classNames from "classnames"

import mailTo from "../../utils/mailTo"
import ModalContactForm from "../../components/ModalContactForm/ModalContactForm"
import InlineButton from "../../components/InlineButton"
import { Link, graphql } from "gatsby"
import schemaOrg from "./schemaOrg.json"
import { seoDefaultData } from "../../utils/seo"

const Boxes = ({ className = "" }) => (
  <img
    className={classNames(styles.box, className)}
    src={boxOfDotsLink}
    role="presentation"
    alt=""
  />
)

const Faq = ({ data, location }) => {
  const [showFrom, setShowForm] = useState(false)
  const contactEmail = data.site.siteMetadata.adminEmail
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
      schemaOrg={schemaOrg.en}
    >
      <Inner>
        <Headline Tag="h1" className={styles.title}>
          faq
        </Headline>

        <Boxes className={styles.boxFirst} />

        <Boxes className={styles.boxSecond} />

        <Headline Tag="h2" className={styles.subtitle}>
          Organizational details
        </Headline>
        <StyledAccordion className={styles.accordeon}>
          <StyledAccordionItem title="How do I get started?">
            <p>
              First of all, we need to get to know your project and you. To do
              this, you fill out a brief, and we conduct a{" "}
              <a
                rel="nofollow noopener noreferrer"
                target="_blank"
                href={mailTo.mail.en}
              >
                free consultation
              </a>{" "}
              for you. Then, if everything is good, it only remains to prepare the technical task and sign the contract.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="What if my project is under the NDA?">
            <p>
              You can{" "}
              <a
                rel="nofollow noopener noreferrer"
                target="_blank"
                href={`maito:${contactEmail}`}
              >
                email us
              </a>
              , we will sign all the needed documents, and after, we will be able to discuss everything freely.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Do you do test tasks?">
            <p>
              We usually don’t carry out test tasks as they don’t really fit our work approach and pipelines. 
              We start every project from the research and audio concept creation, and we can’t see how it can work well 
              with the whole «test task» concept.
            </p>

            <p>
              We understand that for many people, test tasks are a way to get to know a new team better and to understand 
              how much cooperation is possible in general. For us, it is equally important to match with the client in the 
              general vision and principles of work.
            </p>

            <p>
              That is why we spend a lot of time on preliminary communication with the client, talk about all the nuances 
              of the upcoming work, discuss the project and its needs, share initial ideas, and show relevant cases from our practice.
            </p>
            
            <p>
            However, there’s an exception for the voice-over projects, for that we can provide a free voice talent audition.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Who prepares the technical task of the project?">
            <p>
              Ideally, a client initiates the process of technical task creation by assembling an asset list with corresponding 
              visuals/animations and basic requirements. For that, you can use{" "}
              <InlineButton onClick={() => setShowForm(true)}>
                our trustworthy template
              </InlineButton>{" "}.
            </p>
            
            <p>
            Then we step in and develop a document based on the in-depth client brief, niche, competitors, and target audience analysis.
            </p>
            
            <p>
            In such a way, both sides have crystal clear results expectations from the very beginning. It helps to choose the direction 
            and to lay a solid foundation for the success of a collaboration.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="How is the rest of the work process organized?">
            <p>
              The process may vary depending on the chosen service, although the main six stages remain unchanged: acquaintance, 
              legal formalities, pre-production, production, project acceptance, and further support.
            </p>

            <p>
              You can read in detail the organization of each of our services on the following pages:{" "}
              <Link to="/en/services/music-composing">music composing</Link>,{" "}
              <Link to="/en/services/sound-design">sound design</Link>,{" "}
              <Link to="/en/services/audio-branding">audio branding</Link> and{" "}
              <Link to="/en/services/voice-casting">voice casting</Link>.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>
        <ModalContactForm
          title="Technical task template"
          subTitle="We are confident that a correctly made technical task increases the chances of success of the project. Therefore, we are happy to share what works for us!"
          open={showFrom}
          onClose={() => setShowForm(false)}
        />
        <Headline Tag="h2" className={styles.subtitle}>
          Financial questions
        </Headline>

        <StyledAccordion className={styles.accordeon}>
          <StyledAccordionItem title="Do you work on a prepaid basis?">
            <p>
             Yes, we work on a prepaid basis. Therefore, the advance payment amount may vary depending on the project scope, duration, and chosen services. 
             It can be 50% of the total amount,  multiple small payments, or a full prepayment in some cases.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="In what cases, 100 % prepayment is required?">
            <p>
              Full prepayment is required for any voice-over services and any consultation type.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="How can I pay for your services?">
            <p>
              We accept payments for the provided services only to the physical person entrepreneur (PPE) account or the Payoneer account.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>

        <Headline Tag="h2" className={styles.subtitle}>
          Legal questions
        </Headline>

        <StyledAccordion className={styles.accordeon}>
          <StyledAccordionItem title="Do you have a contract?">
            <p>
              Yes, we sign a contract for providing services with each client. We have a cool and craft contract — all thanks to the team of lawyers with whom we work.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Will I get the rights to the result?">
            <p>
              By default, we transfer to you all exclusive intellectual property rights to the sound effects, voice-overs, and audio brand elements we created, 
              of course, on one condition — after you pay for our services.
            </p>

            <p>
              By default, we transfer to you the intellectual property rights to the music compositions that we created, with the restriction on their free resale 
              and re-release, outside the project we have agreed on and monetization from streaming services and marketplaces.
            </p>

            <p>
              If necessary, we can transfer all exclusive intellectual property rights without restrictions, but we should discuss this separately. 
              In this case, the total cost of the project will be higher.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Why don't you transfer all exclusive rights to the music?">
            <p>
             Sometimes soundtracks become an additional means of project monetization and a source of superprofits. 
             We consider it fair that in such cases, the composer is entitled to further compensation.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>

        <Headline Tag="h2" className={styles.subtitle}>
          Questions about consultations
        </Headline>

        <StyledAccordion className={styles.accordeon}>
          <StyledAccordionItem title="Which kind of consultations do you have?">
            <p>
              Currently, we offer two types of consultations: audio consultations for a specific project and audio reviews.
            </p>
            <p>
             During an audio consultation, we discuss the project as a whole, notably its positioning; we highlight the main performing tasks of audio, 
             help create a technical task, discuss possible ideas, talk about best practices and points of attention while working on audio.
            </p>
            <p>
              The audio review includes game experience evaluation from an audio perspective, sound assessment of individual animations and/or cutscenes, 
              and a document with suggestions for revisions.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="What information must be prepared for the consultation?">
            <p>
              We need to go through a detailed description of the project for both types of consultations in advance, including information about its target audience, 
              market, and competitors. Also, any visual materials will come in handy: art, videos, animations, etc. 
              And even if you have a pitch document, that's more than enough!
            </p>

            <p>
            For the audio review, we also need a playable unlock build and a document with the names of all the audio assets and links to the corresponding animations.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="How long does a consultation take?">
            <p>
             An audio consultation for a specific project usually takes 1.5 hours, but we can extend it or run several sessions if necessary.
            </p>
            <p>We perform audio reviews within three business days.</p>
          </StyledAccordionItem>
          <StyledAccordionItem title="How do you provide the consultation?">
            <p>
              We usually conduct an audio review in text format, and as a result, you will receive a text document.
            </p>
            <p>
              We conduct audio consultations for a specific project in audio or video conferencing via Google Meet or Zoom.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="How much does the consultation cost?">
            <p>
              An audio consultation for a specific project costs €100/hour.
            </p>
            <p>The cost of an audio review is €400.</p>
            <p>
              We also conduct three thirty-minute consultations or lite audio
              reviews every month for free.{" "}
              <a
                rel="nofollow noopener noreferrer"
                target="_blank"
                href={`maito:${contactEmail}`}
              >
                Email us
              </a>{" "}
              to catch your slot.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>

        <Headline Tag="h2" className={styles.subtitle}>
          Glossary
        </Headline>

        <StyledAccordion className={styles.accordeon}>
          <StyledAccordionItem title="Audio adaptations">
            <p>
              Music pieces or sounds that are created on a base of the audio DNA
              and explicitly designed for every touchpoint and its needs.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Audio brand">
            <p>
              A system of music and sounds that conveys the brand meaning at
              each touchpoint.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Audio branding">
            <p>
              Also known as sonic, acoustic, music, and sound branding. A
              process of creating and managing a system of music and sounds that
              conveys the brand meaning at each touchpoint.
            </p>
            <p>
              It is an essential part of branding, just like visual identity
              development. The difference lies in the main tools, which in this
              case are: music, sound, and voice.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Audio concept">
            <p>
              A document that contains a brand positioning, a list of its
              audio-enabled touchpoints, and ideas of transmitting a specific
              message and tasks performing via audio.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Audio DNA">
            <p>
              A core element of each audio brand. An audio «collage» or a short
              complete track that embodies the brand's promise, values, and
              personality in the sonic form, as well as determines the sound of
              the brand's audio touchpoints. It defines the musical vocabulary
              of instruments, harmonies, tempo, rhythmic patterns, the timbre of
              sound, voice, etc.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Audio logo">
            <p>
              2 to 3 seconds-long sound and a crucial part of the audio DNA. It
              grabs listeners’ attention and conveys the brand's promise,
              values, and personality. At the same time, it is designed to be
              highly memorable.
            </p>
            <p>
              The audio logo is a direct analog of the visual logo in a music
              form.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Audio mood board">
            <p>
              A playlist that contains a selection of musical clips that show
              different ways of conveying a particular message, meaning, or
              idea.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Audio style guide">
            <p>
              A part of the company’s brand book or a separate document that
              contains descriptions and rules of usage of an audio DNA, all its
              adaptations, and other audio brand elements.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Audio touchpoints">
            <p>
              Contact points of employees and customers with the brand that are
              audio-enabled. They should be unified regarding the sound so that
              listeners can get positive and predictable experiences with the
              brand.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Jingle">
            <p>
              A short musical and vocal phrase that most often explicitly
              promotes a product or service and almost always contains a
              company’s slogan.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Sonic identity">
            <p>
              A complex of audio impressions from the brand or product. It
              should be at least consistent and transmit the desired meaning,
              promise, and values at the maximum.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>

        <Contacts>
          Did not find the answer to an essential question for you? Don't
          hesitate to ask us directly, and we will be happy to help you.
        </Contacts>
      </Inner>
    </Layout>
  )
}

export const query = graphql`
  query FAQPageEnQuery {
    site {
      siteMetadata {
        adminEmail
      }
    }
    seoPagesData: wpCustomPage(
      language: { code: { eq: EN } }
      seo: { pageSlug: { eq: "faq" } }
    ) {
      ...seoCustomPageData
    }
  }
`

export default Faq
