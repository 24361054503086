import React from "react"
import PropTypes from "prop-types"

import * as styles from "./PriceCard.module.css"

const PriceCard = ({
  icon,
  title = "",
  subTitle = "",
  alt = "",
  list = [],
  price = "",
}) => {
  const imageAlt = alt || title

  return (
    <div className={styles.card}>
      {icon && <img className={styles.icon} src={icon} alt={imageAlt} />}
      <h3 className={styles.title}>{title}</h3>
      {subTitle && <h5 className={styles.subTitle}>{subTitle}</h5>}
      {list && (
        <ul className={styles.list}>
          {list.map((item, i) => (
            <li key={`${item}-${i}`} className={styles.item}>
              {item}
            </li>
          ))}
        </ul>
      )}

      <span className={styles.price}>{price}</span>
    </div>
  )
}

PriceCard.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  alt: PropTypes.string,
  list: PropTypes.array,
  price: PropTypes.string,
}

export default PriceCard
