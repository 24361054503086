exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-en-js": () => import("./../../../src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-about-index-ru-js": () => import("./../../../src/pages/about/index.ru.js" /* webpackChunkName: "component---src-pages-about-index-ru-js" */),
  "component---src-pages-about-index-uk-js": () => import("./../../../src/pages/about/index.uk.js" /* webpackChunkName: "component---src-pages-about-index-uk-js" */),
  "component---src-pages-faq-index-en-js": () => import("./../../../src/pages/faq/index.en.js" /* webpackChunkName: "component---src-pages-faq-index-en-js" */),
  "component---src-pages-faq-index-ru-js": () => import("./../../../src/pages/faq/index.ru.js" /* webpackChunkName: "component---src-pages-faq-index-ru-js" */),
  "component---src-pages-faq-index-uk-js": () => import("./../../../src/pages/faq/index.uk.js" /* webpackChunkName: "component---src-pages-faq-index-uk-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-ru-js": () => import("./../../../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-index-uk-js": () => import("./../../../src/pages/index.uk.js" /* webpackChunkName: "component---src-pages-index-uk-js" */),
  "component---src-pages-our-values-index-en-js": () => import("./../../../src/pages/our-values/index.en.js" /* webpackChunkName: "component---src-pages-our-values-index-en-js" */),
  "component---src-pages-our-values-index-ru-js": () => import("./../../../src/pages/our-values/index.ru.js" /* webpackChunkName: "component---src-pages-our-values-index-ru-js" */),
  "component---src-pages-our-values-index-uk-js": () => import("./../../../src/pages/our-values/index.uk.js" /* webpackChunkName: "component---src-pages-our-values-index-uk-js" */),
  "component---src-pages-portfolio-index-en-js": () => import("./../../../src/pages/portfolio/index.en.js" /* webpackChunkName: "component---src-pages-portfolio-index-en-js" */),
  "component---src-pages-portfolio-index-ru-js": () => import("./../../../src/pages/portfolio/index.ru.js" /* webpackChunkName: "component---src-pages-portfolio-index-ru-js" */),
  "component---src-pages-portfolio-index-uk-js": () => import("./../../../src/pages/portfolio/index.uk.js" /* webpackChunkName: "component---src-pages-portfolio-index-uk-js" */),
  "component---src-pages-reviews-index-en-js": () => import("./../../../src/pages/reviews/index.en.js" /* webpackChunkName: "component---src-pages-reviews-index-en-js" */),
  "component---src-pages-reviews-index-ru-js": () => import("./../../../src/pages/reviews/index.ru.js" /* webpackChunkName: "component---src-pages-reviews-index-ru-js" */),
  "component---src-pages-reviews-index-uk-js": () => import("./../../../src/pages/reviews/index.uk.js" /* webpackChunkName: "component---src-pages-reviews-index-uk-js" */),
  "component---src-pages-services-audio-branding-en-js": () => import("./../../../src/pages/services/audio-branding.en.js" /* webpackChunkName: "component---src-pages-services-audio-branding-en-js" */),
  "component---src-pages-services-audio-branding-ru-js": () => import("./../../../src/pages/services/audio-branding.ru.js" /* webpackChunkName: "component---src-pages-services-audio-branding-ru-js" */),
  "component---src-pages-services-audio-branding-uk-js": () => import("./../../../src/pages/services/audio-branding.uk.js" /* webpackChunkName: "component---src-pages-services-audio-branding-uk-js" */),
  "component---src-pages-services-audio-reviews-and-consultations-en-js": () => import("./../../../src/pages/services/audio-reviews-and-consultations.en.js" /* webpackChunkName: "component---src-pages-services-audio-reviews-and-consultations-en-js" */),
  "component---src-pages-services-audio-reviews-and-consultations-ru-js": () => import("./../../../src/pages/services/audio-reviews-and-consultations.ru.js" /* webpackChunkName: "component---src-pages-services-audio-reviews-and-consultations-ru-js" */),
  "component---src-pages-services-audio-reviews-and-consultations-uk-js": () => import("./../../../src/pages/services/audio-reviews-and-consultations.uk.js" /* webpackChunkName: "component---src-pages-services-audio-reviews-and-consultations-uk-js" */),
  "component---src-pages-services-music-composing-en-js": () => import("./../../../src/pages/services/music-composing.en.js" /* webpackChunkName: "component---src-pages-services-music-composing-en-js" */),
  "component---src-pages-services-music-composing-ru-js": () => import("./../../../src/pages/services/music-composing.ru.js" /* webpackChunkName: "component---src-pages-services-music-composing-ru-js" */),
  "component---src-pages-services-music-composing-uk-js": () => import("./../../../src/pages/services/music-composing.uk.js" /* webpackChunkName: "component---src-pages-services-music-composing-uk-js" */),
  "component---src-pages-services-sound-design-en-js": () => import("./../../../src/pages/services/sound-design.en.js" /* webpackChunkName: "component---src-pages-services-sound-design-en-js" */),
  "component---src-pages-services-sound-design-ru-js": () => import("./../../../src/pages/services/sound-design.ru.js" /* webpackChunkName: "component---src-pages-services-sound-design-ru-js" */),
  "component---src-pages-services-sound-design-uk-js": () => import("./../../../src/pages/services/sound-design.uk.js" /* webpackChunkName: "component---src-pages-services-sound-design-uk-js" */),
  "component---src-pages-services-voice-casting-en-js": () => import("./../../../src/pages/services/voice-casting.en.js" /* webpackChunkName: "component---src-pages-services-voice-casting-en-js" */),
  "component---src-pages-services-voice-casting-ru-js": () => import("./../../../src/pages/services/voice-casting.ru.js" /* webpackChunkName: "component---src-pages-services-voice-casting-ru-js" */),
  "component---src-pages-services-voice-casting-uk-js": () => import("./../../../src/pages/services/voice-casting.uk.js" /* webpackChunkName: "component---src-pages-services-voice-casting-uk-js" */),
  "component---src-templates-page-index-en-js": () => import("./../../../src/templates/page/index.en.js" /* webpackChunkName: "component---src-templates-page-index-en-js" */),
  "component---src-templates-page-index-uk-js": () => import("./../../../src/templates/page/index.uk.js" /* webpackChunkName: "component---src-templates-page-index-uk-js" */),
  "component---src-templates-post-index-en-js": () => import("./../../../src/templates/post/index.en.js" /* webpackChunkName: "component---src-templates-post-index-en-js" */),
  "component---src-templates-post-index-ru-js": () => import("./../../../src/templates/post/index.ru.js" /* webpackChunkName: "component---src-templates-post-index-ru-js" */),
  "component---src-templates-post-index-uk-js": () => import("./../../../src/templates/post/index.uk.js" /* webpackChunkName: "component---src-templates-post-index-uk-js" */),
  "component---src-templates-posts-index-en-js": () => import("./../../../src/templates/posts/index.en.js" /* webpackChunkName: "component---src-templates-posts-index-en-js" */),
  "component---src-templates-posts-index-ru-js": () => import("./../../../src/templates/posts/index.ru.js" /* webpackChunkName: "component---src-templates-posts-index-ru-js" */),
  "component---src-templates-posts-index-uk-js": () => import("./../../../src/templates/posts/index.uk.js" /* webpackChunkName: "component---src-templates-posts-index-uk-js" */),
  "component---src-templates-posts-tag-index-en-js": () => import("./../../../src/templates/postsTag/index.en.js" /* webpackChunkName: "component---src-templates-posts-tag-index-en-js" */),
  "component---src-templates-posts-tag-index-ru-js": () => import("./../../../src/templates/postsTag/index.ru.js" /* webpackChunkName: "component---src-templates-posts-tag-index-ru-js" */),
  "component---src-templates-posts-tag-index-uk-js": () => import("./../../../src/templates/postsTag/index.uk.js" /* webpackChunkName: "component---src-templates-posts-tag-index-uk-js" */),
  "component---src-templates-work-work-en-js": () => import("./../../../src/templates/work/work.en.js" /* webpackChunkName: "component---src-templates-work-work-en-js" */),
  "component---src-templates-work-work-ru-js": () => import("./../../../src/templates/work/work.ru.js" /* webpackChunkName: "component---src-templates-work-work-ru-js" */),
  "component---src-templates-work-work-uk-js": () => import("./../../../src/templates/work/work.uk.js" /* webpackChunkName: "component---src-templates-work-work-uk-js" */)
}

