import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Layout from "../../layouts/uk/default"
import { Outer, Inner } from "../../components/Container"
import Headline from "../../components/Headline"
import boxOfDotsLink from "../../images/icon-box.svg"
import * as styles from "./styles.module.css"
import Contacts from "../../components/lendingSections/Contacts"
import SectionWithText from "../../components/SectionWithText"
import Why from "../../components/lendingSections/Why"
import FeatureCard from "../../components/FeatureCard"
import Services from "../../components/lendingSections/Services"

import iconUsers from "../../images/vp-icons/icon-1.svg"
import iconDocs from "../../images/vp-icons/icon-2.svg"
import iconLamp from "../../images/vp-icons/icon-3.svg"
import iconСlef from "../../images/vp-icons/icon-4.svg"
import iconClock from "../../images/vp-icons/icon-5.svg"
import iconDialog from "../../images/vp-icons/icon-6.svg"

import logoWithSlogan from "../../images/logo-with-slogan.svg"
import TeamCarousel from "../../components/TeamCarousel"

import mailTo from "../../utils/mailTo"
import InlineButton from "../../components/InlineButton"
import ModalContactForm from "../../components/ModalContactForm/ModalContactForm"
import { seoDefaultData } from "../../utils/seo"

const Box = () => (
  <img className={styles.box} src={boxOfDotsLink} role="presentation" alt="" />
)

const About = ({ data, location }) => {
  const [showFrom, setShowForm] = useState(false)

  const team = data.team.members
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <Inner>
        <Headline Tag="h1" className={styles.title}>
          Про нас
        </Headline>
        <Box />
        <Box />
      </Inner>
      <section className={styles.aboutSection}>
        <Outer>
          <Headline className={styles.aboutTitle} Tag="h2">
            Маленькі й могутні{" "}
            <span role="img" aria-label="могутні">
              💪
            </span>
          </Headline>
          <Inner className={styles.aboutContainer}>
            <div className={styles.aboutCol}>
              <p>
                Так ми описуємо нашу аудіоагенцію під час першого знайомства й
                навряд чи це скоро зміниться. Ми не прагнемо стати Великою
                Серйозною Компанією. Якою б привабливою не була ця перспектива —
                ми цінуємо наш craftsmen статус.
              </p>
              <p>
                Ми знаємо, що одних сміливих ідей недостатньо, щоби прогнозовано
                впливати на споживача. Тому ми взяли все найкраще від творчості
                та системності — так ми допомагаємо продуктам і брендам стати
                частиною життя їхньої аудиторії.
              </p>
              <p>
                А ще ми з великим ентузіазмом беремо участь у різних
                колабораціях і завжди відкриті до пропозицій і викликів.{" "}
                <span role="img" aria-label="руки">
                  🙌
                </span>
              </p>
              <p>
                До речі, партнерський контент ми теж любимо! Якщо ви хочете
                написати щось із нами або про нас — ось{" "}
                <a
                  href="https://docs.google.com/document/d/1CiDkZd-EZTz4aClMsCKkoAbuxfearfxthOkynZxPSQo/edit?usp=sharing"
                  rel="nofollow"
                  target="__blank"
                >
                  посилання на прес-кит
                </a>
                .
              </p>
            </div>
            <div className={styles.aboutCol}>
              <img
                className={styles.aboutImage}
                src={logoWithSlogan}
                alt="VP Production"
              />
            </div>
          </Inner>
        </Outer>
      </section>
      <section className={styles.ourTeamSection}>
        <Outer>
          <Headline Tag="h2">Наша команда</Headline>
        </Outer>
        <Inner>
          <TeamCarousel team={team} />
        </Inner>
      </section>
      <Services
        title="Наші послуги"
        className={styles.services}
        services={[
          {
            title: "Створення музики",
            link: "/services/music-composing",
          },
          {
            title: "Аудіобрендинг",
            link: "/services/audio-branding",
          },
          {
            title: "Звуковий дизайн",
            link: "/services/sound-design",
          },
          {
            title: "Голосове озвучення",
            link: "/services/voice-casting",
          },
        ]}
      />
      <Why title="І ще" style={{ backgroundColor: "#000" }}>
        <FeatureCard img={iconClock}>
          <strong>Дедлайни — наше все.</strong> За 6 років ми зірвали 0
          дедлайнів.
        </FeatureCard>
        <FeatureCard img={iconСlef}>
          <strong>
            Аудиторія + наше аудіо ={" "}
            <span role="img" aria-label="сердце">
              ❤️
            </span>
            .
          </strong>{" "}
          Наші роботи вже чули мільйони людей і багацько залишилися настільки
          небайдужими, що написали про нас{" "}
          <Link rel="nofollow" to="/reviews">
            таке
          </Link>
          .
        </FeatureCard>
        <FeatureCard img={iconDocs}>
          <strong>Зрозумілий процес роботи.</strong> Ми стежимо, щоби на кожному
          етапі у вас було чітке розуміння того, що відбувається, і повний
          контроль над ним. У цьому нам допомагають вивірені пайплайни,
          аудіальні мудборди й наша методика фідбеку.
        </FeatureCard>
        <FeatureCard img={iconLamp}>
          <strong>Створюємо актив бренду.</strong> Ми детально вивчаємо ваш
          продукт, аналізуємо ринок і цільову аудиторію, щоби розробити загальну
          концепцію і створити працюючий аудіоконтент.
        </FeatureCard>
        <FeatureCard img={iconDialog}>
          <strong>Ми спілкуємося простою мовою.</strong> Вам не потрібно
          використовувати аудіо термінологію, щоби ми зрозуміли, що потрібно
          вашому проєкту.
        </FeatureCard>
        <FeatureCard img={iconUsers}>
          <strong>Ми — відкриті</strong> і з радістю ділимося корисними для
          вашого продукту знаннями на{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.uk}
          >
            безплатній консультації
          </a>{" "}
          та у{" "}
          <InlineButton onClick={() => setShowForm(true)}>
            перевіреному роками темплейті ТЗ
          </InlineButton>
          .
        </FeatureCard>
      </Why>
      <SectionWithText
        linkLabel="Наші цінності"
        link="/our-values"
        style={{ backgroundColor: "#090909" }}
      >
        <h3>
          Ми створюємо захоплююче емоційне аудіо, що надихає людей виражати
          себе.
        </h3>
      </SectionWithText>
      <Contacts>
        Розкажіть нам про свій проєкт, продукт або ідею. Запитайте пораду або
        отримайте повноцінну{" "}
        <a
          rel="nofollow noopener noreferrer"
          target="_blank"
          href={mailTo.mail.uk}
        >
          безплатну консультацію
        </a>
        . Дізнайтеся вартість, уточніть важливе питання, чи, може, ви хочете
        стати частиною нашої команди? Та що завгодно! Ми будемо раді з вами
        познайомитися й допомогти.
      </Contacts>
      <ModalContactForm
        title="Темплейт технічного завдання"
        subTitle="Ми впевнені, що правильно складене ТЗ підвищує шанси на успіх проєкту. Тому з радістю ділимося тим, що працює для нас!"
        open={showFrom}
        onClose={() => setShowForm(false)}
      />
    </Layout>
  )
}

About.propTypes = {
  location: PropTypes.any.isRequired,
}

export const query = graphql`
  query AboutPageUkQuery {
    site {
      siteMetadata {
        adminEmail
      }
    }
    seoPagesData: wpCustomPage(
      language: { code: { eq: UK } }
      seo: { pageSlug: { eq: "about" } }
    ) {
      ...seoCustomPageData
    }
    team: allWpTeam(filter: { language: { code: { eq: UK } } }) {
      ...teamData
    }
  }
`

export default About
