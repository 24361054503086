import React from "react"
import { graphql, navigate } from "gatsby"

import Layout from "../../layouts/en/blog"

import PostsList from "~components/PostsList"
import Sidebar from "~components/BlogSidebar"
import { seoDefaultData } from "~utils/seo"
import { getBlogDescription, trasfromPosts, trasfromTags } from "~utils/post"
import SidebarFilters from "~components/SidebarFilters"
import CollapsedBlogFilter from "~components/CollapsedBlogFilter"
import SocialCards from "~components/SocialCards"
import SubscriptionForm from "~components/SubscriptionForm"

const PostsTemplate = ({ data, pageContext, location }) => {
  const { numberOfPages, pathPrefix, pageNumber } = pageContext

  const sidebarImage = data?.sidebarImage?.childImageSharp?.fluid
  const { seo } = data?.seoPagesData ?? { seo: seoDefaultData }
  const posts = trasfromPosts(data?.allWpPost?.nodes || [])
  const filters = trasfromTags(data?.allWpTag?.nodes || [])

  const handlePageChange = ({ selected }) => {
    if (selected === 0) {
      navigate(pathPrefix)
      return
    }

    navigate(`${pathPrefix}/${selected + 1}`)
  }

  const hrefBuilder = pageIndex => {
    if (pageIndex === 1) return "/en/blog"
    return `/en/blog/${pageIndex}`
  }

  const sidebar = (
    <Sidebar
      title="Blog"
      text={getBlogDescription("en")}
      image={sidebarImage}
      slot={
        <SidebarFilters
          label="Categories:"
          filters={filters}
          resetLabel="Reset"
          resetLink="/en/blog"
        />
      }
    />
  )

  return (
    <Layout
      noindex
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
      Sidebar={sidebar}
    >
      <PostsList
        posts={posts}
        pageNumber={pageNumber}
        hrefBuilder={hrefBuilder}
        pageCount={numberOfPages}
        onPageChange={handlePageChange}
      />
      <CollapsedBlogFilter
        label="Categories"
        filters={filters}
        resetLabel="Reset"
        resetLink="/en/blog"
      />
      <SocialCards />
      <SubscriptionForm />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!, $language: WpLanguageCodeEnum!) {
    seoPagesData: wpCustomPage(
      language: { code: { eq: EN } }
      seo: { pageSlug: { eq: "blog" } }
    ) {
      ...seoCustomPageData
    }
    sidebarImage: file(relativePath: { eq: "blog.jpg" }) {
      ...sidebarImage
    }
    allWpTag(filter: { language: { code: { eq: $language } }, slug: {ne: "news"} }) {
      ...blogListTag
    }
    allWpPost(
      filter: { language: { code: { eq: $language } } }
      skip: $skip
      limit: $limit
    ) {
      ...blogListPost
    }
  }
`

export default PostsTemplate
