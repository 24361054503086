import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../layouts/ru/sidebar"

import ServiceHero from "~components/ServiceHero"
import ContactUs from "~components/ContactUs"
import Sidebar from "~components/Sidebar"
import ServiceSection from "~components/ServiceSection"
import ServiceIconList from "~components/ServiceIconList"
import ServiceIcon from "~components/ServiceIcon"
import StyledAccordion from "~components/StyledAccordion"
import StyledAccordionItem from "~components/StyledAccordionItem"
import ReviewCarousel from "~components/ReviewCarousel/ReviewCarousel"
import CardDeck from "~components/CardDeck/CardDeck"
import CardDeckItem from "~components/CardDeckItem/CardDeckItem"
import WorkCards from "~components/WorkCards"
import PriceCard, { PriceCardList } from "~components/PriceCard"
import Button from "~components/Button"

import FemaleIcon from "~images/service-icons/female.svg"
import JingleIcon from "~images/service-icons/jingle.svg"
import AudioIcon from "~images/service-icons/audio.svg"
import PhoneIcon from "~images/service-icons/phone.svg"
import SoundIcon from "~images/service-icons/sound.svg"

import ABASIIcon from "~images/service-icons/audio-branding-app-sonic-Identity.svg"
import ABBIcon from "~images/service-icons/audio-branding-basic.svg"

import mailTo from "~utils/mailTo"
import { seoDefaultData } from "~utils/seo"

const AudioBranding = ({ data, location }) => {
  const reviews = data.reviews.nodes
  const sidebarImage = data.sidebarImage.childImageSharp.fluid
  const works = data.works.nodes
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      Sidebar={
        <Sidebar
          title="Аудиобрендинг"
          text="В современном мире бренды должны относиться к звуку с той же тщательностью и дисциплиной, что и к графическим стандартам и визуальному построению бренда. Если вы застряли в мире исключительно визуального брендинга, то соревнуетесь вы с одной завязанной за спиной рукой."
          autor="Лоуренс Мински, Коллин Фэйхи"
          image={sidebarImage}
        />
      }
      ServiceHero={
        <ServiceHero>
          <h3>
            Как для аудио-специалистов, мы слишком интересуемся маркетингом.
          </h3>
          <p>
            Для нас он — современный комплексный вид искусства. Плюс, мы —
            фанаты системного подхода, в котором всегда есть место креативности.
            Так что вполне ожидаемо, что нас заинтересовала тема аудиобрендинга.
          </p>
          <p>
            Зачастую бренды не используют весь потенциал звука для поддержки
            своих продуктов и мы хотим это изменить. Ведь правильный аудио бренд
            помогает установить связь с аудиторией, создать нужную ассоциацию,
            усилить запоминаемость бренда и его ключевого сообщения,
            дифференцироваться от конкурентов и даже скорректировать восприятие
            продукта или компании.
          </p>
          <p>
            В конце концов, именно благодаря звуковой айдентике человек
            мгновенно узнает ваш бренд, даже если он его ещё не увидел.
          </p>
        </ServiceHero>
      }
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <ServiceSection title="Что мы делаем?">
        <p>Мы создаём:</p>
        <ServiceIconList>
          <ServiceIcon title="Аудио стратегии брендов" icon={FemaleIcon} />
          <ServiceIcon title="Аудио логотипы и джинглы" icon={JingleIcon} />
          <ServiceIcon title="Аудиорекламу и бренд-музыку" icon={AudioIcon} />
          <ServiceIcon title="UI/UX звуковой дизайн" icon={PhoneIcon} />
          <ServiceIcon
            title="Другие аудиальные точки контакта с клиентом"
            icon={SoundIcon}
          />
        </ServiceIconList>

        <p>
          Каждый аудио бренд — уникален, как и сам бренд. У каждого бизнеса —
          свои потребности и нет универсальной формулы и состава аудио бренда.
          Поэтому для каждого проекта мы создаём персональную стратегию и набор
          элементов в зависимости от задач, которые нужно решить.
        </p>
      </ServiceSection>
      <ServiceSection title="Наши работы">
        <WorkCards
          works={works}
          breakpoints={{
            default: 3,
            1100: 3,
            700: 3,
            500: 2,
          }}
        />
        <iframe
          title="Наши работы"
          width="100%"
          height="450"
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/758338767&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        ></iframe>
        <div className="service-works-porfolio-button-container">
          <Button
            state={{
              service: "audio-branding",
            }}
            to="/ru/portfolio?service=audio-branding-2"
            className="service-works-porfolio-button"
          >
            Портфолио
          </Button>
        </div>
      </ServiceSection>
      <ServiceSection title="Как проходит процесс?">
        <p>
          За последние несколько лет мы выработали понятную и эффективную схему
          сотрудничества. С одной стороны, мы следим, чтобы на каждом этапе у
          вас было чёткое понимание происходящего и полный контроль над ним. А с
          другой — собираем максимум информации о вашем бренде, чтобы
          разработать подходящую концепцию и создать аудио, которое станет
          значимой частью жизни вашей аудитории.
        </p>
        <StyledAccordion>
          <StyledAccordionItem title="Знакомство">
            <p>
              Вы заполняете бриф и предоставляете список аудио-ассетов, которые
              нам нужно будет создать. Мы назначаем аудио- или видеоконференцию
              в Google Meet или Zoom, на которой знакомимся друг с другом,
              обсуждаем проект и понимаем, насколько готовы сотрудничать. Для
              нас важно совпадать с клиентом в общем видении и принципах работы.
            </p>
            <p>
              Если вы не можете делиться информацией о проекте без NDA, то в
              самом начале общения мы подписываем необходимые документы.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Юридические формальности">
            <p>
              Мы уточняем объём проекта, количество и порядок платежей,
              подписываем с вами договор и получаем аванс. Детальнее о
              финансовых и юридических вопросах вы можете прочесть в нашем{" "}
              <Link to="/ru/faq">FAQ</Link>.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Препродакшн">
            <p>
              За годы работы мы не один раз убедились, что препродакшн во многом
              определяет успех всего проекта. На этом этапе мы более детально
              изучаем ваш бриф и задаём дополнительные вопросы, проводим рисёрч,
              составляем техническое задание, подбираем{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/ru/faq#audialiniimudbord"
              >
                аудиальные мудборды
              </a>{" "}
              и начинаем формировать{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/ru/faq#audialinayakontseptsiya"
              >
                аудиальную концепцию
              </a>
              .
            </p>
            <p>
              Кроме вашего бренда мы изучаем вашу нишу, её аудиальные коды и
              клише, конкурентов, целевую аудиторию и на этой основе определяем
              задачи и будущее звучание аудио бренда.
            </p>
            <p>
              Мы утверждаем с вами готовое техническое задание, вместе
              отслушиваем аудиальные мудборды, при необходимости вносим правки.
              Благодаря этому мы определяем рамки, в которых мы должны будем
              разработать аудиальную концепцию. После этого мы получаем от вас
              второй платёж.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Продакшн">
            <p>
              На этом этапе многое зависит от размера и специфики проекта, но в
              целом всё сводится к следующим действиям:
            </p>
            <ul>
              <li>
                На основании технического задания и аудиальных мудбордов мы
                составляем список идей по основным элементам{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href="/ru/faq#audioDNK"
                >
                  аудио-ДНК
                </a>{" "}
                и согласовываем их с вами.
              </li>
              <li>
                Мы разрабатываем 2 варианта демо аудио-ДНК, каждый из которых
                по-своему воплощает ценности бренда. Отправляем демо вам на
                фидбек, предварительно ознакомив с действенной методикой
                фидбека. :)
              </li>
              <li>
                Утверждаем с вами демо ДНК либо составляем список правок. На
                этой же стадии важно провести тесты на аудитории, если у вас
                есть такая возможность. Если же её нет — мы поможем организовать
                небольшое внутреннее тестирование, чтобы определить, насколько
                точно звучание демо соотносится с брендом.
              </li>
              <li>
                На основе комментариев мы вместе выбираем более релевантное
                бренду демо, дорабатываем аудио-ДНК и отправляем вам на фидбек.
              </li>
              <li>
                Утверждаем с вами трек либо вносим новые правки — всего у нас
                предусмотрено 5 раундов бесплатных правок.
              </li>
              <li>
                <p>
                  После финализации аудио-ДНК мы приступаем к разработке её{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href="/ru/faq#audioadaptatsii"
                  >
                    адаптаций
                  </a>
                  , необходимых вашему бренду. Каждая адаптация проходит те же
                  стадии: демо, утверждение, тестирование, финализация.
                </p>
                <p>
                  В больших и длительных проектах на этом этапе может добавиться
                  ещё один промежуточный платёж.
                </p>
              </li>
              <li>
                Когда все аудио-ассеты готовы, мы составляем для вас аудио
                стайлгайд, в котором собраны все основные правила использования
                элементов вашего аудио бренда.
              </li>
            </ul>
          </StyledAccordionItem>
          <StyledAccordionItem title="Приём проекта">
            <p>
              После всех согласований мы высылаем вам конечные файлы. Вы
              имплементируете элементы аудио бренда в нужные точки контакта и
              даёте нам к ним доступ, чтобы мы могли убедиться, что всё звучит
              правильно. В случае, когда что-то пошло не по плану, мы составляем
              список исправлений и следим, чтобы вы смогли всё настроить.
            </p>
            <p>
              Когда всё готово и хорошо звучит, мы с вами подписываем акт
              приёма-передачи и вы отправляете нам финальную часть оплаты.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Поддержка">
            <p>
              Мы любим следить за развитием наших партнёров и клиентов. Часто
              после приёма проекта работа только начинается и мы всегда готовы
              продолжить сотрудничество и добавить нового звучания бренду. В
              рамках изначально разработанной аудиальной концепции, конечно. :)
            </p>
          </StyledAccordionItem>
        </StyledAccordion>
      </ServiceSection>

      <ServiceSection title="Стоимость">
        <PriceCardList>
          <PriceCard
            icon={ABBIcon}
            title="Базовый аудио бренд"
            list={[
              "глубинный брифинг",
              "анализ ниши, конкурентов и целевой аудитории",
              "составление технического задания",
              "создание аудиальной концепции",
              "создание аудио-ДНК",
              "разработка аудио логотипа",
              "разработка бренд-трека (в виде «конструктора»)",
              "составление аудио стайлгайда",
            ]}
            price="€8000"
          />

          <PriceCard
            icon={ABASIIcon}
            title="Звуковая айдентика приложения"
            list={[
              "глубинный брифинг",
              "анализ ниши, конкурентов и целевой аудитории",
              "составление технического задания",
              "создание аудиальной концепции",
              "разработка аудио-ДНК",
              "разработка звуков приложения",
              "составление аудио стайлгайда",
            ]}
            price="€2200 + €200/звук"
          />
        </PriceCardList>

        <p>
          Стоимость других возможных адаптаций аудио-ДНК рассчитывается
          отдельно.
        </p>
        <p>
          Этап рисёрча и создания аудио-ДНК — обязательная часть процесса
          создания звуковой айдентики приложения. Он не только определяет задачи
          и будущее звучание нотификаций и in-app звуков, но и то, как будут
          звучать все потенциальные точки контакта продукта с аудиторией. Именно
          аудио-ДНК лежит в основе гибкой аудиальной системы бренда, которая
          упрощает и удешевляет создание любого аудио-контента, необходимого
          бренду.
        </p>
        <p>
          Тем не менее, вы можете сэкономить на создании аудио-ДНК, если кроме
          приложения вы не планируете коммуницировать с аудиторией в других
          каналах. На услуге аудиального рисёрча (глубинный брифинг, анализ
          ниши, конкурентов и целевой аудитории) вы также можете сэкономить, для
          этого вам нужно предоставить нам подробную информацию о продукте,
          включая:
        </p>
        <ul>
          <li>позиционирование вашего продукта;</li>
          <li>его точки дифференциации;</li>
          <li>список конкурентов с примерами их аудио;</li>
          <li>
            информацию, как конкуренты используют аудио в маркетинговых целях;
          </li>
          <li>портрет(ы) вашей целевой аудитории;</li>
          <li>потребности и предпочтения вашей целевой аудитории;</li>
          <li>лучшие практики и аудиальные клише ниши.</li>
        </ul>
        <p>
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.audioBranding.ru}
          >
            Напишите нам
          </a>{" "}
          и мы ответим на любые ваши вопросы, и поможем с просчётом проекта.{" "}
          <span role="img" aria-label="руки">
            🙌
          </span>
        </p>
      </ServiceSection>
      <ServiceSection title="Наш подход">
        <CardDeck>
          <CardDeckItem variant="rect" textAlign="left">
            Мы считаем своей главной задачей создать уникальное звучание для
            вашего бренда и сделать его максимально информативным. Уместить всю
            суть в звук и правильно его направить, чтобы воздействовать на
            эмоции вашего потребителя так, как это нужно вам.
          </CardDeckItem>
          <CardDeckItem variant="full-waves" textAlign="left">
            Мы продумываем, где и когда ваш бренд будет звучать. Сначала мы
            разрабатываем аудио-ДНК — главную музыкальную тему, в которой
            отражаются идеология и отличительные особенности бренда. Она
            определяет то, какими будут все остальные элементы аудио бренда:
            аудио логотип, бренд-треки, джинглы, оповещения приложения и тому
            подобное.
          </CardDeckItem>
          <CardDeckItem variant="waves" textAlign="left">
            Мы тщательно изучаем ваш бренд, пробираемся в самые глубины вашего
            маркетинг-кита и задаём много вопросов. :) А ещё всегда рады, если у
            вас есть возможность провести тесты на целевой аудитории. Благодаря
            этому мы можем ещё точнее создать нужное вам звучание.
          </CardDeckItem>
        </CardDeck>
      </ServiceSection>
      <ServiceSection title="Отзывы клиентов и партнёров">
        <ReviewCarousel reviews={reviews} />
      </ServiceSection>
      <div className="contact-us-wrapper">
        <ContactUs>
          Расскажите нам о своём проекте, продукте или идее. Спросите совет или
          получите{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.ru}
          >
            бесплатную консультацию
          </a>
          . Узнайте цены, уточните важный вопрос, да что угодно! Мы будем рады с
          вами познакомиться и помочь.
        </ContactUs>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AudioBrandingRuPageQuery {
    seoPagesData: wpCustomPage(
      language: { code: { eq: RU } }
      seo: { pageSlug: { eq: "services/audio-branding" } }
    ) {
      ...seoCustomPageData
    }
    reviews: allWpClientReview(
      limit: 5
      filter: {
        meta: { audiobrending: { audiobrendingVisibility: { eq: true } } }
        language: { code: { eq: RU } }
      }
    ) {
      ...serviceClientReviews
    }
    works: allWpWork(
      filter: {
        language: { code: { eq: RU } }
        meta: { audiobrending: { audiobrendingVisibility: { eq: true } } }
      }
      sort: { fields: meta___audiobrending___audiobrendingOrder, order: DESC }
    ) {
      ...serviceWorks
    }
    sidebarImage: file(relativePath: { eq: "services/audio-branding.jpg" }) {
      ...sidebarImage
    }
    sitePage {
      id
      path
    }
  }
`

export default AudioBranding
