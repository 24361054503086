export const transliterate = (word = "") => {
  return word
    .replace(/[^0-9A-Za-z_\u0400-\u04FF]/gi, "")
    .replace(/\u0401/g, "YO") // Ё
    .replace(/\u0419/g, "I") // Й
    .replace(/\u0426/g, "TS") // Ц
    .replace(/\u0423/g, "U") // У
    .replace(/\u041A/g, "K") // К
    .replace(/\u0415/g, "E") // Е
    .replace(/\u041D/g, "N") // Н
    .replace(/\u0413/g, "G") // Г
    .replace(/\u0428/g, "SH") // Ш
    .replace(/\u0429/g, "SCH") // Щ
    .replace(/\u0417/g, "Z") // З
    .replace(/\u0425/g, "H") // Х
    .replace(/\u042A/g, "") // Ъ
    .replace(/\u0451/g, "yo") // ё
    .replace(/\u0439/g, "i") // й
    .replace(/\u0446/g, "ts") // ц
    .replace(/\u0443/g, "u") // у
    .replace(/\u043A/g, "k") // к
    .replace(/\u0435/g, "e") // е
    .replace(/\u043D/g, "n") // н
    .replace(/\u0433/g, "g") // г
    .replace(/\u0448/g, "sh") // ш
    .replace(/\u0449/g, "sch") // щ
    .replace(/\u0437/g, "z") // з
    .replace(/\u0445/g, "h") // х
    .replace(/\u044A/g, "U") // Ы
    .replace(/\u0424/g, "F") // Ф
    .replace(/\u042B/g, "I")
    .replace(/\u0412/g, "V") // В
    .replace(/\u0410/g, "a") // А
    .replace(/\u041F/g, "P") // П
    .replace(/\u0420/g, "R") // Р
    .replace(/\u041E/g, "O") // О
    .replace(/\u041B/g, "L") // Л
    .replace(/\u0414/g, "D") // Д
    .replace(/\u0416/g, "ZH") // Ж
    .replace(/\u042D/g, "E") // Э
    .replace(/\u0444/g, "f") // ф
    .replace(/\u044B/g, "i") // ы
    .replace(/\u0432/g, "v") // б
    .replace(/\u0430/g, "a") // а
    .replace(/\u043F/g, "p") // п
    .replace(/\u0440/g, "r") // р
    .replace(/\u043E/g, "o") // о
    .replace(/\u043B/g, "l") // л
    .replace(/\u0434/g, "d") // д
    .replace(/\u0436/g, "zh") // ж
    .replace(/\u044D/g, "e") // э
    .replace(/\u042F/g, "Ya") // Я
    .replace(/\u0427/g, "CH") // Ч
    .replace(/\u0421/g, "S") // С
    .replace(/\u041C/g, "M") // М
    .replace(/\u0418/g, "I") // И
    .replace(/\u0422/g, "T") // Т
    .replace(/\u042C/g, "I") // Ь
    .replace(/\u0411/g, "B") // Б
    .replace(/\u042E/g, "YU") // Ю
    .replace(/\u044F/g, "ya") // я
    .replace(/\u0447/g, "ch") // ч
    .replace(/\u0441/g, "s") // с
    .replace(/\u043C/g, "m") // м
    .replace(/\u0438/g, "i") // и
    .replace(/\u0442/g, "t") // т
    .replace(/\u044C/g, "i") // ь
    .replace(/\u0431/g, "b") // б
    .replace(/\u044E/g, "yu") // ю
    .replace(/\u0400/g, "YO") // Ё
    .replace(/\u0450/g, "yo") // ё
    .replace(/[^a-zA-Z ]/g, "") // remove rest
}
