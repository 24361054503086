import React from "react"
import PropTypes from "prop-types"

import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { graphql, useStaticQuery } from "gatsby"
import { IntlProvider } from "react-intl"

import "intl"
import "normalize.css"

import HorizontalNav from "~components/HorizontalNav"
import { Inner } from "~components/Container"
import Footer from "~components/Footer"
import Seo from "~components/seo"

import useDetectKeyboard from "~utils/useDetectKeyboard"
import AlertProvider from "~utils/AlertProvider"

const SITE_LANGS = graphql`
  query BlogLayoutQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`

const BlogLayout = ({
  Sidebar,
  ServiceHero,
  children,
  location,
  i18nMessages,
  title,
  description,
  meta,
  disableSiteNameInTitle,
  noindex = false,
  translationsBuilder,
}) => {
  useDetectKeyboard()

  const { site } = useStaticQuery(SITE_LANGS)
  const { langs, defaultLangKey } = site.siteMetadata.languages

  const url = location.pathname.replace(/\/[0-9]+$/, "")

  const langKey = getCurrentLangKey(langs, defaultLangKey, url)

  const homeLink = `/${langKey}`.replace(`/${defaultLangKey}`, "/")

  let langsMenu =
    translationsBuilder?.(langs, url) ??
    getLangs(langs, langKey, getUrlForLang(homeLink, url))

  langsMenu = langsMenu.map(item => ({
    ...item,
    link: item.link.replace("//", "/").replace(`/${defaultLangKey}/`, "/"),
  }))

  const pageMetaTags = noindex
    ? meta.concat([{ name: "robots", content: "noindex" }])
    : meta

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <AlertProvider>
        <Seo
          title={title}
          lang={langKey}
          pathname={url}
          meta={pageMetaTags}
          langsMenu={langsMenu}
          description={description}
          disableSiteNameInTitle={disableSiteNameInTitle}
          noindex={noindex}
        />
        <HorizontalNav
          hideMenu
          locale={langKey}
          homeLink={homeLink}
          langsMenu={langsMenu}
          className="blog-nav"
        />
        <div className="blog">
          <aside className="blog-sidebar">{Sidebar}</aside>
          <div className="blog-main">
            <div className="blog-content">
              <Inner>
                {children}
                <Footer />
              </Inner>
            </div>
          </div>
        </div>
      </AlertProvider>
    </IntlProvider>
  )
}

BlogLayout.defaultProps = {
  title: "",
  description: "",
  meta: [],
  disableSiteNameInTitle: false,
}

BlogLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.array,

  Sidebar: PropTypes.element.isRequired,
  children: PropTypes.node,

  location: PropTypes.object,

  disableSiteNameInTitle: PropTypes.bool,

  translationsBuilder: PropTypes.func,
}

export default BlogLayout
