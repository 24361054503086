// extracted by mini-css-extract-plugin
export var PostCard = "styles-module--PostCard--EY0Wi";
export var link = "styles-module--link--jME3M";
export var title = "styles-module--title--IhYp9";
export var excerpt = "styles-module--excerpt--3Bl14";
export var imageArea = "styles-module--imageArea--sdA-l";
export var image = "styles-module--image--T9d+h";
export var postInfo = "styles-module--postInfo--Y1-Fw";
export var tags = "styles-module--tags--V4ETW";
export var tag = "styles-module--tag--WUtP9";
export var timeToRead = "styles-module--timeToRead--FRSG5";