import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"
import Img from "~components/image"
import { FormattedMessage } from "react-intl"

import * as styles from "./styles.module.css"
import { Link } from "gatsby"

const buildTime = (min, max) => {
  const time = [min, max].filter(Boolean)

  return time.join("-")
}

const Tags = ({ tags = [] }) => {
  if (!tags || tags.length === 0) return null

  const prefix = tag =>
    tag?.language?.slug === "uk" ? "" : `/${tag?.language?.slug}`

  return (
    <ul className={styles.tags}>
      {tags.map(tag => (
        <li
          style={{ color: tag.color, backgroundColor: tag.backgroundColor }}
          className={styles.tag}
          key={tag.slug}
        >
          <Link to={`${prefix(tag)}/blog/${tag.slug}`} title={tag.name}>
            {tag.name}
          </Link>
        </li>
      ))}
    </ul>
  )
}

const PostCard = ({
  title = "",
  slug = "",
  image,
  excerpt = "",
  tags = [],
  link = "",
  timeToReadMin,
  timeToReadMax,
}) => {
  const timeToRead = buildTime(timeToReadMin, timeToReadMax)
  const postLink = link.replace(/^\/?/, "/")

  return (
    <Link className={styles.link} to={postLink} title={title}>
      <div className={styles.PostCard}>
        {image && (
          <div className={styles.imageArea}>
            <Img className={styles.image} alt={title} fluid={image} />

            <div className={styles.postInfo}>
              <Tags tags={tags} />
              {timeToRead && (
                <FormattedMessage id="post.timeToRead" defaultMessage="min">
                  {msg => (
                    <span className={classnames(styles.tag, styles.timeToRead)}>
                      <span>
                        {timeToRead}
                        {msg}
                      </span>
                    </span>
                  )}
                </FormattedMessage>
              )}
            </div>
          </div>
        )}
        <h5
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className={styles.excerpt}
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />
      </div>
    </Link>
  )
}

PostCard.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  image: PropTypes.object,
  excerpt: PropTypes.string,
  tags: PropTypes.array,
  timeToReadMin: PropTypes.number,
  timeToReadMax: PropTypes.number,
}

export default PostCard
