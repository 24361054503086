import React, { useEffect, useState } from "react"
import { CSSTransition } from "react-transition-group"
import classnames from "classnames"
import { createPortal } from "react-dom"
import PropTypes from "prop-types"

import Filter from "~components/Filter"

import * as styles from "./styles.module.css"

const CollapsedBlogFilter = ({
  label,
  filters,
  resetLink = "/blog",
  resetLabel = "Reset",
}) => {
  const [portalRoot, setPortalRoot] = useState(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!document) return

    setPortalRoot(document.getElementById("blog-filters"))
  }, [setPortalRoot])

  const toogleFilters = () => setOpen(prev => !prev)
  /* eslint-disable jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-noninteractive-element-interactions */
  const portal = (
    <div className={styles.filtersContainer}>
      <div
        className={styles.label}
        role="button"
        onClick={toogleFilters}
        tabIndex="-1"
      >
        <h4 className={styles.title}>{label}</h4>
        <span
          className={classnames(styles.arrow, { [styles.open]: open })}
          aria-hidden="true"
        />
      </div>
      <CSSTransition
        in={open}
        timeout={200}
        classNames="fixed-filter"
        unmountOnExit
      >
        <ul className={styles.list}>
          {filters.map(({ label, link, color, backgroundColor, posts }) => (
            <li key={label}>
              <Filter
                label={label}
                link={link}
                color={color}
                backgroundColor={backgroundColor}
                disabled={posts.nodes.length === 0}
              />
            </li>
          ))}

          <li className={styles.reset}>
            <Filter
              reset
              label={resetLabel}
              color="#000"
              link={resetLink}
              backgroundColor="#FFF"
            />
          </li>
        </ul>
      </CSSTransition>
    </div>
  )

  return portalRoot ? createPortal(portal, portalRoot) : null
}

CollapsedBlogFilter.propTypes = {
  resetLink: PropTypes.string,
  resetLabel: PropTypes.string,
}

export default CollapsedBlogFilter
