import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../layouts/ru/home"
import Hero from "../components/lendingSections/Hero"
import About from "../components/lendingSections/About"
import Why from "../components/lendingSections/Why"
import Services from "../components/lendingSections/Services"
import ChosenWorks from "../components/lendingSections/ChosenWorks"
import Contacts from "../components/lendingSections/Contacts"
import SectionWithText from "../components/SectionWithText"
import FeatureCard from "../components/FeatureCard"

import iconUsers from "../images/vp-icons/icon-1.svg"
import iconDocs from "../images/vp-icons/icon-2.svg"
import iconLamp from "../images/vp-icons/icon-3.svg"
import iconСlef from "../images/vp-icons/icon-4.svg"
import iconClock from "../images/vp-icons/icon-5.svg"
import iconDialog from "../images/vp-icons/icon-6.svg"

import mailTo from "../utils/mailTo"
import ModalContactForm from "../components/ModalContactForm/ModalContactForm"
import InlineButton from "../components/InlineButton"
import { seoDefaultData } from "../utils/seo"

const Home = ({ data, location }) => {
  const [showFrom, setShowForm] = useState(location.search === "?contact-form")

  const works = data.works.nodes
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <Hero link="/ru/portfolio" />
      <About
        title="VP Production"
        text="Мы — независимое аудиоагентство, которое создаёт звуковую айдентику для игр, брендов и приложений. С нами можно не стесняться заряженной музыки, концептуальных звуков и самобытных голосов — всё ради того, чтобы стать значимой частью жизни вашей аудитории."
        linkLabel="О нас"
        linkURL="/ru/about"
        alt="Студия саунд дизайна"
      />
      <Why>
        <FeatureCard img={iconClock}>
          <strong>Сроки — наше всё.</strong> За 6 лет мы сорвали 0 дедлайнов.
        </FeatureCard>
        <FeatureCard img={iconСlef}>
          <strong>
            Аудитория + наше аудио ={" "}
            <span role="img" aria-label="сердце">
              ❤️
            </span>
            .
          </strong>{" "}
          Наши работы уже слышали миллионы людей и многие остались настолько
          неравнодушны, что написали о нас{" "}
          <Link rel="nofollow" to="/ru/reviews">
            такое
          </Link>
          .
        </FeatureCard>
        <FeatureCard img={iconDocs}>
          <strong>Понятный процесс работы.</strong> Мы следим, чтобы на каждом
          этапе у вас было чёткое понимание происходящего и полный контроль над
          ним. В этом нам помогают выверенные пайплайны, аудиальные мудборды и
          наша методика фидбека.
        </FeatureCard>
        <FeatureCard img={iconLamp}>
          <strong>Создаём актив бренда.</strong> Мы детально изучаем ваш
          продукт, анализируем рынок и целевую аудиторию, чтобы разработать
          общую концепцию и создать работающий аудиоконтент.
        </FeatureCard>
        <FeatureCard img={iconDialog}>
          <strong>Мы общаемся простым языком.</strong> Вам не нужно использовать
          аудио-терминологию, чтобы мы поняли, что нужно вашему проекту.
        </FeatureCard>
        <FeatureCard img={iconUsers}>
          <strong>Мы — открытые</strong> и с радостью делимся полезными для
          вашего продукта знаниями на{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.ru}
          >
            бесплатной консультации
          </a>{" "}
          и в{" "}
          <InlineButton onClick={() => setShowForm(true)}>
            проверенном годами темплейте ТЗ
          </InlineButton>
          .
        </FeatureCard>
      </Why>
      <ModalContactForm
        title="Темплейт технического задания"
        subTitle="Мы уверены, что правильно составленное ТЗ повышает шансы на успех проекта. Поэтому с радостью делимся тем, что работает для нас!"
        open={showFrom}
        onClose={() => setShowForm(false)}
      />
      <Services
        title="Наши услуги"
        services={[
          {
            title: "Создание музыки",
            link: "/ru/services/music-composing",
            alt: "Создание музыки",
          },
          {
            title: "Аудиобрендинг",
            link: "/ru/services/audio-branding",
            alt: "Аудиобрендинг",
          },
          {
            title: "Звуковой дизайн",
            link: "/ru/services/sound-design",
            alt: "Саунд дизайн",
          },
          {
            title: "Голосовое озвучивание",
            link: "/ru/services/voice-casting",
            alt: "Озвучка",
          },
        ]}
      />
      <ChosenWorks
        title="Избранные работы"
        works={works}
        linkLabel="Портфолио"
        linkURL="/ru/portfolio"
      />
      <SectionWithText linkLabel="Наши ценности" link="/ru/our-values">
        <h3>
          Мы создаём захватывающее эмоциональное аудио, которое вдохновляет
          людей выражать себя.
        </h3>
      </SectionWithText>
      <Contacts>
        Расскажите нам о своём проекте, продукте или идее. Спросите совет или
        получите полноценную{" "}
        <a
          rel="nofollow noopener noreferrer"
          target="_blank"
          href={mailTo.mail.ru}
        >
          бесплатную консультацию
        </a>
        . Узнайте цены, уточните важный вопрос, да что угодно! Мы будем рады с
        вами познакомиться и помочь.
      </Contacts>
    </Layout>
  )
}

export const query = graphql`
  query HomePageRuQuery {
    site {
      siteMetadata {
        adminEmail
      }
    }
    seoPagesData: wpCustomPage(
      language: { code: { eq: RU } }
      seo: { pageSlug: { eq: "home" } }
    ) {
      ...seoCustomPageData
    }
    works: allWpWork(
      filter: {
        meta: { frontPage: { frontPageVisibility: { eq: true } } }
        language: { code: { eq: RU } }
      }
      sort: { fields: meta___frontPage___frontPageOrder, order: DESC }
    ) {
      ...workListItemData
    }
  }
`

export default Home
