import React from "react"
import PropTypes from "prop-types"
import Img from "~components/image"

import { Inner } from "~components/Container"

import parseContent from "~utils/parseContent"

import * as styles from "./styles.module.css"

import ReadMorePosts from "./ReadMorePosts"

const Post = ({ title, image, content, readMorePosts = [] }) => {
  const Content = parseContent(content)

  return (
    <article className={styles.post}>
      <Inner>
        <div className={styles.meta}>
          <h1
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Img className={styles.image} fluid={image} />
        </div>
      </Inner>
      <Inner className={styles.content}>{Content}</Inner>
      <Inner>
        <ReadMorePosts posts={readMorePosts} />
      </Inner>
    </article>
  )
}

Post.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
  content: PropTypes.node,
}

export default Post
