import React from "react"
import PropTypes from "prop-types"

const LazyIframe = ({ width = "100%", title, src, ...props }) => {
  return (
    <iframe width={width} title={title} src={src} {...props} loading="lazy" />
  )
}

LazyIframe.propTypes = {
  width: PropTypes.string,
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
}

export default LazyIframe
