import React from "react"
import parse from "html-react-parser"

const parser = content => {
  return parse(content, {
    replace: domNode => {
      if (domNode.name === "iframe") {
        if (domNode.attribs.allowfullscreen !== undefined) {
          const { src = "", title = "post-iframe" } = domNode.attribs

          return (
            <div className="video-wrapper">
              <iframe
                src={src}
                className="video"
                width="100%"
                height="100%"
                allowFullScreen={true}
                frameBorder="0"
                scrolling="no"
                title={title}
              />
            </div>
          )
        }
      }
    },
  })
}

export default parser
