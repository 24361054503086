import React from "react"
import { graphql } from "gatsby"

import Layout from "../../layouts/uk/default"

import PriceCard, { PriceCardList } from "~components/PriceCard"
import Contacts from "~components/lendingSections/Contacts"
import { Inner } from "~components/Container"
import Headline from "~components/Headline"

import { seoDefaultData } from "~utils/seo"

import RACCIcon from "~images/service-icons/review-and-consultations-consultation.svg"
import RACARIcon from "~images/service-icons/review-and-consultations-audio-review.svg"

const AudioReviewsAndConsultations = ({ data, location }) => {
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }
  const adminEmail = data?.site?.siteMetadata?.adminEmail

  return (
    <Layout
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <div
        className="text-page-content"
        style={{
          margin: "6rem 1rem 2rem",
        }}
      >
        <Inner>
          <Headline Tag="h1">Аудіо-рев’ю та консультації</Headline>
          <p>
            За останні кілька років ми працювали над аудіо десятків проєктів, а
            ще з десятком безпосередньо не працювали. Але це не означає, що ми
            не брали участі в їхньому творчому процесі. Ми проводили
            консультації й аудіо-рев’ю, щоби допомогти інді-розробникам і
            брендам зрозуміти потреби своїх продуктів у плані звуку або
            поліпшити наявне аудіо до необхідного рівня якості.
          </p>
          <p>
            Така робота допомагає нам поширювати наш підхід, ділитися своїм
            баченням з іншими, створювати значущі зв’язки та допомагати людям
            створювати продукти, що приємно звучать (і є ефективними!). Саме
            тому ми вирішили, що настав час офіційно представити цю послугу.
            Отже, зустрічайте аудіо-рев’ю та консультації!
          </p>
          <Headline Tag="h2">Що ми робимо?</Headline>
          <p>
            Наразі ми надаємо консультації двох видів:{" "}
            <b>консультація щодо аудіо</b> конкретного проєкту та аудіо-рев’ю.
          </p>
          <p>
            На консультації щодо аудіо ми обговорюємо з вами проєкт загалом,
            його позиціонування, виділяємо основні завдання аудіо, допомагаємо
            скласти технічне завдання, обговорюємо ймовірні ідеї для реалізації,
            розповідаємо про кращі практики та про те, чому варто приділити
            увагу під час роботи над аудіо.
          </p>
          <p>
            <b>Аудіо-рев’ю</b> проєкту включає в себе оцінювання аудіального
            ігрового досвіду, оцінювання звучання окремих анімацій та/або
            катсцен, складання документу з пропозиціями щодо виправлень.
          </p>
          <Headline Tag="h2">Як проходить процес?</Headline>
          <p>
            Усе залежить від типу консультації, який ви вибрали. Але в
            будь-якому разі все починається з того, що ми попередньо ретельно
            вивчаємо докладний опис проєкту, включно з інформацією про його
            цільову аудиторію, ринок і конкурентів. Також нам знадобляться
            будь-які візуальні матеріали: арти, відео, анімації тощо. А якщо у
            вас раптом є пітч-документ — це взагалі ідеально!
          </p>
          <p>
            У разі <b>консультації щодо аудіо конкретного проєкту</b>, після
            вивчення всієї наданої інформації ми організовуємо аудіо- або
            відеоконференцію в Google Meet або Zoom. Така консультація зазвичай
            триває 1,5 години, але за необхідності ми можемо її подовжити, або ж
            провести декілька сесій.
          </p>
          <p>
            Для <b>аудіо-рев’ю</b> нам також знадобиться іграбельний unlock-білд
            і документ із назвами всіх аудіо ассетів і посиланнями на відповідні
            анімації. Зазвичай ми проводимо рев’ю в текстовому форматі й у
            результаті ви отримуєте текстовий документ.
          </p>
          <p>Аудіо-рев’ю ми виконуємо упродовж трьох робочих днів.</p>

          <strong>
            Обидва види консультацій потребують повної передоплати.
          </strong>

          <Headline Tag="h2">Вартість</Headline>
          <PriceCardList>
            <PriceCard
              icon={RACCIcon}
              title="Консультація щодо аудіо конкретного проєкту"
              list={[
                "обговорюємо проєкт загалом, його позиціонування",
                "виділяємо основні завдання аудіо",
                "допомагаємо скласти технічне завдання",
                "обговорюємо можливі ідеї та розповідаємо про кращі практики",
              ]}
              price="€100/год"
            />

            <PriceCard
              icon={RACARIcon}
              title="Аудіо-рев’ю"
              list={[
                "оцінювання аудіального ігрового досвіду",
                "оцінювання звучання окремих анімацій та/або катсцен",
                "створення документа з пропозиціями щодо виправлень",
              ]}
              price="€400*"
            />
          </PriceCardList>
          <p>
            *Вартість аудіо-рев’ю залежить від кількості аудіо ассетів у
            проєкті.
          </p>
          <p>
            Крім того, кожного місяця ми проводимо три безплатних
            тридцятихвилинних консультації або лайт аудіо-рев’ю.{" "}
            <a
              rel="nofollow noopener noreferrer"
              target="_blank"
              href={`mailto:${adminEmail}`}
            >
              Напишіть нам
            </a>
            , аби вхопити свій слот.
          </p>
        </Inner>
      </div>
      <Contacts>
        Розкажіть нам про свій проєкт, продукт або ідею. Запитайте пораду або
        отримайте безплатну консультацію. Дізнайтеся вартість, уточніть важливе
        питання, та що завгодно! Ми будемо раді з вами познайомитися й
        допомогти.
      </Contacts>
    </Layout>
  )
}

export const query = graphql`
  query AudioReviewsAndConsultationsPageUkQuery {
    site {
      siteMetadata {
        adminEmail
      }
    }
    seoPagesData: wpCustomPage(
      language: { code: { eq: UK } }
      seo: { pageSlug: { eq: "audio-reviews-and-consultations" } }
    ) {
      ...seoCustomPageData
    }
  }
`

export default AudioReviewsAndConsultations
