import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../layouts/en/sidebar"

import ServiceHero from "~components/ServiceHero"
import ContactUs from "~components/ContactUs"
import Sidebar from "~components/Sidebar"
import ServiceSection from "~components/ServiceSection"
import ServiceIconList from "~components/ServiceIconList"
import ServiceIcon from "~components/ServiceIcon"
import StyledAccordion from "~components/StyledAccordion"
import StyledAccordionItem from "~components/StyledAccordionItem"
import ReviewCarousel from "~components/ReviewCarousel/ReviewCarousel"
import CardDeck from "~components/CardDeck/CardDeck"
import CardDeckItem from "~components/CardDeckItem/CardDeckItem"
import WorkCards from "~components/WorkCards"
import PriceCard, { PriceCardList } from "~components/PriceCard"
import Button from "~components/Button"

import FemaleIcon from "~images/service-icons/female.svg"
import JingleIcon from "~images/service-icons/jingle.svg"
import AudioIcon from "~images/service-icons/audio.svg"
import PhoneIcon from "~images/service-icons/phone.svg"
import SoundIcon from "~images/service-icons/sound.svg"

import ABASIIcon from "~images/service-icons/audio-branding-app-sonic-Identity.svg"
import ABBIcon from "~images/service-icons/audio-branding-basic.svg"

import mailTo from "~utils/mailTo"
import { seoDefaultData } from "~utils/seo"

const AudioBranding = ({ data, location }) => {
  const reviews = data.reviews.nodes
  const sidebarImage = data.sidebarImage.childImageSharp.fluid
  const works = data.works.nodes
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      Sidebar={
        <Sidebar
          title="Audio Branding"
          text="In today’s world, brands must now treat the sound with the same care and discipline as they do their graphic standards and visual brand-building. If you’re stuck in the visual-only branding world, you are competing with one hand tied behind your back."
          autor="Laurence Minsky, Colleen Fahey"
          image={sidebarImage}
        />
      }
      ServiceHero={
        <ServiceHero>
          <h3>
            As for audio professionals, we are way too interested in marketing.
          </h3>
          <p>
            For us, it's a modern, complex art form. Plus, we're fans of a
            systematic approach that always has room for creativity. So it is
            pretty obvious why we got interested in audio branding.
          </p>
          <p>
            Brands often don't use the full potential of audio to support their
            products, and we want to change that. The right audio brand helps
            establish a connection with the audience, create the proper
            association, enhance the brand and its key message memorability,
            differentiate from competitors, and even adjust the perception of
            the product or company.
          </p>
          <p>
            After all, a sonic identity makes people instantly recognize a
            brand, even if they haven't seen it yet.
          </p>
        </ServiceHero>
      }
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <ServiceSection title="What do we do?">
        <p>We create:</p>

        <ServiceIconList>
          <ServiceIcon
            title="Audio strategies for brands"
            icon={FemaleIcon}
            alt="Sonic branding"
          />
          <ServiceIcon
            title="Audio logos and jingles"
            icon={JingleIcon}
            alt="Audio logo &#38; radio jingles"
          />
          <ServiceIcon
            title="Audio advertising and brand music"
            icon={AudioIcon}
            alt="Brand tracking"
          />
          <ServiceIcon
            title="UI/UX sound design"
            icon={PhoneIcon}
            alt="Sonic Identity"
          />
          <ServiceIcon
            title="Other audio touchpoints"
            icon={SoundIcon}
            alt="Sound logo"
          />
        </ServiceIconList>

        <p>
          Each audio brand is unique, as the brand itself. Each business has its
          own needs, and there is no universal formula and composition of the
          audio brand. Therefore, we create a personal audio brand strategy and
          a set of elements for each project depending on its tasks and needs.
        </p>
      </ServiceSection>
      <ServiceSection title="Our works">
        <WorkCards
          works={works}
          breakpoints={{
            default: 3,
            1100: 3,
            700: 3,
            500: 2,
          }}
        />
        <iframe
          title="Our works"
          width="100%"
          height="450"
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/758338767&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        ></iframe>
        <div className="service-works-porfolio-button-container">
          <Button
            state={{
              service: "audio-branding",
            }}
            to="/en/portfolio?service=audio-branding-3"
            className="service-works-porfolio-button"
          >
            Portfolio
          </Button>
        </div>
      </ServiceSection>
      <ServiceSection title="What is the process like?">
        <p>
          Over the past few years, we have developed a clear and effective
          cooperation scheme. On the one hand, we ensure that you have a clear
          understanding of the process and complete control at every stage. On
          the other hand, we gather as much information as possible about your
          brand to develop the correct concept and create audio that will become
          a meaningful part of your audience's life.
        </p>
        <StyledAccordion>
          <StyledAccordionItem title="Getting to know each other">
            <p>
              You fill out a brief and send us a list of audio assets we'll need
              to create. We set up an audio or video call via Google Meet or
              Zoom to get to know each other, discuss the project, and make sure
              we are ready to collaborate. We need to match with the client in
              the general vision and principles of work.
            </p>
            <p>
              If you cannot share information about the project without an NDA,
              we sign the necessary documents at the beginning of the
              communication.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Legal formalities">
            <p>
              We clarify the project scope, the payments' quantity, and order,
              sign a contract with you and get an advance payment. Learn more
              about financial and legal issues in our{" "}
              <Link to="/en/faq">FAQ</Link>.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Pre-production">
            <p>
              Over the years, we have seen that pre-production largely
              determines the entire project performance and success. At this
              stage, we study your brief in detail and ask additional questions.
              Then, we conduct research, create the technical task, put the{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/en/faq#Audiomoodboard"
              >
                audio mood boards
              </a>{" "}
              together, and start to work on{" "}
              <a
                target="_blank"
                rel="noopener noreferrer nofollow"
                href="/en/faq#Audioconcept"
              >
                an audio concept
              </a>
              .
            </p>
            <p>
              Besides your project, we study your niche, its audio codes and
              clichés, competitors, the target audience, and based on these
              parameters, define audio brand goals and its future sound.
            </p>
            <p>
              We approve the completed technical task, listen to the audio mood
              boards together and make revisions if necessary. After that, we
              receive a second payment from you.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Production">
            <p>
              At this stage, a lot depends on the size and specifics of the
              project. But in general, we do the following steps:
            </p>
            <ul>
              <li>
                Based on the technical task and audio mood boards, we list ideas
                for the main{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href="/en/faq#AudioDNA"
                >
                  audio DNA
                </a>{" "}
                elements and approve them with you.
              </li>
              <li>
                We develop two versions of the audio DNA demo. Both embody the
                brand values in their own way. We send the demo for your
                feedback. We familiarize you ahead with the effective feedback
                methodology, of course. :)
              </li>
              <li>
                We approve the DNA demo with you or make a list of edits. At
                this stage, if you have an opportunity, it is also important to
                conduct tests on the audience. If you don't, we will help to
                organize internal testing. It will define how well the demo
                sound fits the concept of the brand.
              </li>
              <li>
                Based on the comments, we choose a more relevant demo, refine
                the audio DNA, and then send it for your feedback.
              </li>
              <li>
                We approve the track with you or make new edits — we provide
                five rounds of free revisions in total
              </li>
              <li>
                Once the audio DNA is finalized, we start developing its{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href="/en/faq#Audioadaptations"
                >
                  adaptations
                </a>
                . Each adaptation goes through the same stages: demo, approval,
                testing, finalization. During big and long projects, one more
                intermediate payment may appear at this stage.
              </li>
              <li>
                When all audio assets are ready, we compile an audio style
                guide, which contains all the basic rules for using the elements
                of your audio brand.
              </li>
            </ul>
          </StyledAccordionItem>
          <StyledAccordionItem title="Project acceptance">
            <p>
              After all approvals, we send you the final files. You implement
              the audio brand elements into relevant touchpoints and give us
              access to them so we can make sure everything sounds right. If
              something doesn't go according to plan, we make a list of fixes
              and make sure you can tweak everything.
            </p>
            <p>
              When all is done and sounds good, together we sign an Acceptance
              Certificate, and you send us the final part of the payment.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Support">
            <p>
              We like to follow the progress of our partners and clients.
              Frequently after the project acceptance, the work just starts, and
              we are always ready to continue cooperation and add a new sound to
              the brand. Within the framework of the initially developed audio
              concept, of course. :)
            </p>
          </StyledAccordionItem>
        </StyledAccordion>
      </ServiceSection>

      <ServiceSection title="Cost">
        <PriceCardList>
          <PriceCard
            icon={ABBIcon}
            title="Basic audio brand"
            list={[
              "in-depth briefing",
              "niche, competitors, and target audience analysis",
              "technical task creation",
              "audio concept development",
              "audio DNA development",
              "audio logo creation",
              "brand track creation (in the form of a «construction set»)",
              "audio style guide development",
            ]}
            price="€8000"
          />

          <PriceCard
            icon={ABASIIcon}
            title="Sonic identity for an app"
            list={[
              "in-depth briefing",
              "niche, competitors, and target audience analysis",
              "technical task creation",
              "audio concept development",
              "audio DNA development",
              "app sounds creation",
              "audio style guide development",
            ]}
            price="€2200 + €200/sound"
          />
        </PriceCardList>

        <p>
          The cost is calculated separately for all other audio DNA adaptations.
        </p>
        <p>
          The research and audio DNA development stage is a mandatory part of
          the creative process of the app's sonic identity. It defines the tasks
          and the future sound of notifications, in-app sounds, and the sound of
          all potential points of contact with the audience. In addition, the
          audio DNA underpins the brand's flexible audio system, making it
          easier and cheaper to create any audio content for the brand in the
          future.
        </p>
        <p>
          However, you can save money on creating audio DNA if you don't plan to
          communicate with your audience in other channels besides the app. You
          can also save on an audio research service (in-depth briefing, niche,
          competitor, and target audience analysis). In such case, you need to
          provide us with detailed information about the brand, including:
        </p>
        <ul>
          <li>your brand positioning;</li>
          <li>points of product differentiation;</li>
          <li>a list of your competitors with examples of their audio;</li>
          <li>
            information on how competitors use audio for marketing purposes;
          </li>
          <li>portrait(s) of your target audience;</li>
          <li>the needs and preferences of your target audience;</li>
          <li>best practices and audio clichés of the niche.</li>
        </ul>
        <p>
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.audioBranding.en}
          >
            Email us
          </a>
          , and we'll answer any questions you have and help you with project
          estimation.{" "}
          <span role="img" aria-label="hands">
            🙌
          </span>
        </p>
      </ServiceSection>
      <ServiceSection title="Our approach">
        <CardDeck>
          <CardDeckItem variant="rect" textAlign="left">
            Our main task is to create a unique sound for your brand and make it
            as informative as possible. In addition, we aim to fit the whole
            brand’s essence into the sound and influence consumer emotions the
            way you need them.
          </CardDeckItem>
          <CardDeckItem variant="full-waves" textAlign="left">
            We don’t think only about how but also where and when your brand
            will sound. We start with developing audio DNA, as it is a central
            music theme that reflects the ideology and distinctive features of
            the brand. And later, it defines the sound of all other audio brand
            elements, including audio logo, brand tracks, jingles, app
            notifications, and so on.
          </CardDeckItem>
          <CardDeckItem variant="waves" textAlign="left">
            We carefully study your brand, get into the very depths of your
            marketing kit, and ask many questions. :) And we are always happy if
            you can conduct target audience tests. Thanks to this, we can create
            even more precise audio for you.
          </CardDeckItem>
        </CardDeck>
      </ServiceSection>
      <ServiceSection title="Clients’ and partners’ testimonials">
        <ReviewCarousel reviews={reviews} />
      </ServiceSection>
      <div className="contact-us-wrapper">
        <ContactUs>
          Tell us about your project, product, or idea. Ask for advice, or get a{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.en}
          >
            free consultation
          </a>
          . Find out the rates, clarify a vital question, well anything you
          want! We will be happy to get to know you and help.
        </ContactUs>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AudioBrandingEnPageQuery {
    seoPagesData: wpCustomPage(
      language: { code: { eq: EN } }
      seo: { pageSlug: { eq: "services/audio-branding" } }
    ) {
      ...seoCustomPageData
    }
    reviews: allWpClientReview(
      limit: 5
      filter: {
        meta: { audiobrending: { audiobrendingVisibility: { eq: true } } }
        language: { code: { eq: EN } }
      }
    ) {
      ...serviceClientReviews
    }
    works: allWpWork(
      filter: {
        language: { code: { eq: EN } }
        meta: { audiobrending: { audiobrendingVisibility: { eq: true } } }
      }
      sort: { fields: meta___audiobrending___audiobrendingOrder, order: DESC }
    ) {
      ...serviceWorks
    }
    sidebarImage: file(relativePath: { eq: "services/audio-branding.jpg" }) {
      ...sidebarImage
    }
    sitePage {
      id
      path
    }
  }
`

export default AudioBranding
