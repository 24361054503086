import React from "react"
import PropTypes from "prop-types"

import Filter from "~components/Filter"

import * as styles from "./styles.module.css"

const SidebarFilters = ({
  label = "",
  filters = [],
  resetLink = "/blog",
  resetLabel = "Reset",
}) => {
  return (
    <div className={styles.filters}>
      <h3 className={styles.title}>{label}</h3>
      <ul className={styles.list}>
        {filters.map(({ label, link, color, backgroundColor, posts }) => (
          <li key={label}>
            <Filter
              label={label}
              link={link}
              color={color}
              backgroundColor={backgroundColor}
              disabled={posts.nodes.length === 0}
            />
          </li>
        ))}

        <li className={styles.reset}>
          <Filter
            reset
            label={resetLabel}
            color="#000"
            link={resetLink}
            backgroundColor="#FFF"
          />
        </li>
      </ul>
    </div>
  )
}

SidebarFilters.propTypes = {
  filters: PropTypes.array,
  resetLink: PropTypes.string,
  resetLabel: PropTypes.string,
}

export default SidebarFilters
