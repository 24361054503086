// for generating we use this service https://mailtolink.me/

const breakLineCode = "%3A%3Cbr%20/%3E"
const twoDotsSymbol = "%3A%0A"

const addBreakLines = object => {
  let obj = {}

  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      obj[key] = object[key].replace(
        new RegExp(twoDotsSymbol, "g"),
        breakLineCode
      )
    }
  }

  return obj
}

const mail = {
  ru:
    "mailto:connect@vp-production.com?subject=%D0%9A%D0%BE%D0%BD%D1%81%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%86%D0%B8%D1%8F&body=%D0%98%D0%BC%D1%8F%3A%0A%D0%9D%D0%B0%D0%B7%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%B0%3A%0A%D0%A2%D0%B8%D0%BF%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%B0%3A%0A%D0%A3%D0%B4%D0%BE%D0%B1%D0%BD%D1%8B%D0%B9%20%D1%81%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%20%D1%81%D0%B2%D1%8F%D0%B7%D0%B8%3A%0A%D0%A3%D0%B4%D0%BE%D0%B1%D0%BD%D0%BE%D0%B5%20%D0%B2%D1%80%D0%B5%D0%BC%D1%8F%20(%D0%B4%D0%B5%D0%BD%D1%8C%2F%D0%B2%D1%80%D0%B5%D0%BC%D1%8F)%3A%0A%0A%0A%0A%D0%9E%D0%B1%D1%80%D0%B0%D1%89%D0%B0%D0%B5%D0%BC%20%D0%B2%D0%B0%D1%88%D0%B5%20%D0%B2%D0%BD%D0%B8%D0%BC%D0%B0%D0%BD%D0%B8%D0%B5%2C%20%D1%87%D1%82%D0%BE%20%D0%B7%D0%B0%D1%8F%D0%B2%D0%BA%D0%B8%20%D0%BD%D0%B0%20%D0%B1%D0%B5%D1%81%D0%BF%D0%BB%D0%B0%D1%82%D0%BD%D1%83%D1%8E%20%D0%BA%D0%BE%D0%BD%D1%81%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%86%D0%B8%D1%8E%20%D0%BC%D1%8B%20%D1%80%D0%B0%D1%81%D1%81%D0%BC%D0%B0%D1%82%D1%80%D0%B8%D0%B2%D0%B0%D0%B5%D0%BC%20%D1%81%20%D0%BF%D0%BE%D0%BD%D0%B5%D0%B4%D0%B5%D0%BB%D1%8C%D0%BD%D0%B8%D0%BA%D0%B0%20%D0%BF%D0%BE%20%D0%BF%D1%8F%D1%82%D0%BD%D0%B8%D1%86%D1%83%20%D1%81%2010%3A00%20%D0%B4%D0%BE%2018%3A00%20(GMT%20%2B3)%20%D0%B8%20%D0%BE%D1%82%D0%B2%D0%B5%D1%87%D0%B0%D0%B5%D0%BC%20%D0%BD%D0%B0%20%D0%BD%D0%B8%D1%85%20%D0%B2%20%D0%BF%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D0%B5%20%D0%BF%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F.%20%D0%A1%D0%BF%D0%B0%D1%81%D0%B8%D0%B1%D0%BE!",
  uk:
    "mailto:connect@vp-production.com?subject=%D0%9A%D0%BE%D0%BD%D1%81%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%86%D1%96%D1%8F&body=%D0%86%D0%BC%E2%80%99%D1%8F%3A%0A%D0%9D%D0%B0%D0%B7%D0%B2%D0%B0%20%D0%BF%D1%80%D0%BE%D1%94%D0%BA%D1%82%D1%83%3A%0A%D0%A2%D0%B8%D0%BF%20%D0%BF%D1%80%D0%BE%D1%94%D0%BA%D1%82%D1%83%3A%0A%D0%97%D1%80%D1%83%D1%87%D0%BD%D0%B8%D0%B9%20%D1%81%D0%BF%D0%BE%D1%81%D1%96%D0%B1%20%D0%B7%D0%B2%E2%80%99%D1%8F%D0%B7%D0%BA%D1%83%3A%0A%D0%97%D1%80%D1%83%D1%87%D0%BD%D0%B8%D0%B9%20%D1%87%D0%B0%D1%81%20(%D0%B4%D0%B5%D0%BD%D1%8C%2F%D1%87%D0%B0%D1%81)%3A%0A%0A%0A%0A%D0%97%D0%B2%D0%B5%D1%80%D1%82%D0%B0%D1%94%D0%BC%D0%BE%20%D0%B2%D0%B0%D1%88%D1%83%20%D1%83%D0%B2%D0%B0%D0%B3%D1%83%2C%20%D1%89%D0%BE%20%D0%B7%D0%B0%D1%8F%D0%B2%D0%BA%D0%B8%20%D0%BD%D0%B0%20%D0%B1%D0%B5%D0%B7%D0%BF%D0%BB%D0%B0%D1%82%D0%BD%D1%83%20%D0%BA%D0%BE%D0%BD%D1%81%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%86%D1%96%D1%8E%20%D0%BC%D0%B8%20%D1%80%D0%BE%D0%B7%D0%B3%D0%BB%D1%8F%D0%B4%D0%B0%D1%94%D0%BC%D0%BE%20%D0%B7%20%D0%BF%D0%BE%D0%BD%D0%B5%D0%B4%D1%96%D0%BB%D0%BA%D0%B0%20%D0%B4%D0%BE%20%D0%BF%E2%80%99%D1%8F%D1%82%D0%BD%D0%B8%D1%86%D1%96%20%D0%B2%D0%BA%D0%BB%D1%8E%D1%87%D0%BD%D0%BE%2C%20%D0%B7%2010%3A00%20%D0%B4%D0%BE%2018%3A00%20(GMT%20%2B3)%2C%20%D1%96%20%D0%B2%D1%96%D0%B4%D0%BF%D0%BE%D0%B2%D1%96%D0%B4%D0%B0%D1%94%D0%BC%D0%BE%20%D0%BD%D0%B0%20%D0%BD%D0%B8%D1%85%20%D0%BF%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D0%BE%D0%BC%20%D0%BD%D0%B0%D0%B4%D1%85%D0%BE%D0%B4%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F.%20%D0%94%D1%8F%D0%BA%D1%83%D1%94%D0%BC%D0%BE!",
  en:
    "mailto:connect@vp-production.com?subject=Consultation&body=Name%3A%0AProject%E2%80%99s%20name%3A%0AProject%E2%80%99s%20type%3A%0AA%20convenient%20way%20of%20communication%3A%0AConvenient%20time%20(day%20%2F%20time)%3A%0A%0A%0A%0APlease%20note%20that%20we%20consider%20applications%20for%20a%20free%20consultation%20from%20Monday%20to%20Friday%20from%2010%3A00%20to%2018%3A00%20(GMT%20%2B3)%20and%20respond%20to%20them%20in%20the%20order%20they%20are%20received.%20Thank%20you!",
}

const audioBranding = {
  ru:
    "mailto:connect@vp-production.com?subject=%D0%A1%D1%82%D0%BE%D0%B8%D0%BC%D0%BE%D1%81%D1%82%D1%8C%20%D1%80%D0%B0%D0%B7%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20%D0%B0%D1%83%D0%B4%D0%B8%D0%BE%D0%B1%D1%80%D0%B5%D0%BD%D0%B4%D0%B0&body=%D0%98%D0%BC%D1%8F%3A%0A%D0%9D%D0%B0%D0%B7%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5%20%D0%B1%D1%80%D0%B5%D0%BD%D0%B4%D0%B0%3A%0A%D0%A1%D1%84%D0%B5%D1%80%D0%B0%20%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%B1%D1%80%D0%B5%D0%BD%D0%B4%D0%B0%3A%0A%D0%97%D0%B0%D0%B4%D0%B0%D1%87%D0%B8%20%D0%B0%D1%83%D0%B4%D0%B8%D0%BE%D0%B1%D1%80%D0%B5%D0%BD%D0%B4%D0%B0%3A%0A%D0%A2%D0%BE%D1%87%D0%BA%D0%B8%20%D0%BA%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D0%B0%20%D1%81%20%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D0%B5%D0%B9%3A%0A%D0%94%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F%20%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F%3A%0A",
  uk:
    "mailto:connect@vp-production.com?subject=%D0%92%D0%B0%D1%80%D1%82%D1%96%D1%81%D1%82%D1%8C%20%D1%80%D0%BE%D0%B7%D1%80%D0%BE%D0%B1%D0%BA%D0%B8%20%D0%B0%D1%83%D0%B4%D1%96%D0%BE%D0%B1%D1%80%D0%B5%D0%BD%D0%B4%D0%B0&body=%D0%86%D0%BC%E2%80%99%D1%8F%3A%0A%D0%9D%D0%B0%D0%B7%D0%B2%D0%B0%20%D0%B1%D1%80%D0%B5%D0%BD%D0%B4%D0%B0%3A%0A%D0%A1%D1%84%D0%B5%D1%80%D0%B0%20%D0%B4%D1%96%D1%8F%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%96%20%D0%B1%D1%80%D0%B5%D0%BD%D0%B4%D1%83%3A%0A%D0%97%D0%B0%D0%B2%D0%B4%D0%B0%D0%BD%D0%BD%D1%8F%20%D0%B0%D1%83%D0%B4%D1%96%D0%BE%D0%B1%D1%80%D0%B5%D0%BD%D0%B4%D0%B0%3A%0A%D0%A2%D0%BE%D1%87%D0%BA%D0%B8%20%D0%BA%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D1%83%20%D0%B7%20%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D1%96%D1%94%D1%8E%3A%0A%D0%94%D0%BE%D0%B4%D0%B0%D1%82%D0%BA%D0%BE%D0%B2%D0%B0%20%D1%96%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D1%96%D1%8F%3A%0A",
  en:
    "mailto:connect@vp-production.com?subject=Audio%20Brand%20Creation%20Cost&body=Name%3A%0ABrand%20Name%3A%0AThe%20sphere%20of%20the%20brand%E2%80%99s%20activity%3A%0AAudio%20Brand%20Tasks%3A%0APoints%20of%20contact%20with%20the%20audience%3A%0AAdditional%20Information%3A%0A",
}

const soundDesign = {
  ru:
    "mailto:connect@vp-production.com?subject=%D0%A1%D1%82%D0%BE%D0%B8%D0%BC%D0%BE%D1%81%D1%82%D1%8C%20%D1%80%D0%B0%D0%B7%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20%D0%B7%D0%B2%D1%83%D0%BA%D0%BE%D0%B2%D0%BE%D0%B3%D0%BE%20%D0%B4%D0%B8%D0%B7%D0%B0%D0%B9%D0%BD%D0%B0&body=%D0%98%D0%BC%D1%8F%3A%0A%D0%9D%D0%B0%D0%B7%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%B0%3A%0A%D0%A2%D0%B8%D0%BF%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%B0%3A%0A%D0%9A%D0%BE%D0%BB%D0%B8%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%BE%20%D0%B7%D0%B2%D1%83%D0%BA%D0%BE%D0%B2%D1%8B%D1%85%20%D1%8D%D1%84%D1%84%D0%B5%D0%BA%D1%82%D0%BE%D0%B2%3A%0A%D0%9A%D0%BE%D0%BB%D0%B8%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%BE%20%D0%B4%D0%B6%D0%B8%D0%BD%D0%B3%D0%BB%D0%BE%D0%B2%20(%D0%BA%D0%BE%D1%80%D0%BE%D1%82%D0%BA%D0%B8%D1%85%20%D0%BC%D1%83%D0%B7%D1%8B%D0%BA%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D1%84%D1%80%D0%B0%D0%B7)%3A%0A%D0%9A%D0%BE%D0%BB%D0%B8%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%BE%20%D1%8D%D0%BC%D0%B1%D0%B8%D0%B5%D0%BD%D1%82%D0%BE%D0%B2%20(%D0%B7%D0%B2%D1%83%D0%BA%D0%B8%20%D0%BE%D0%BA%D1%80%D1%83%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F)%3A%0A%D0%94%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F%20%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F%3A%0A",
  uk:
    "mailto:connect@vp-production.com?subject=%D0%92%D0%B0%D1%80%D1%82%D1%96%D1%81%D1%82%D1%8C%20%D1%80%D0%BE%D0%B7%D1%80%D0%BE%D0%B1%D0%BA%D0%B8%20%D0%B7%D0%B2%D1%83%D0%BA%D0%BE%D0%B2%D0%BE%D0%B3%D0%BE%20%D0%B4%D0%B8%D0%B7%D0%B0%D0%B9%D0%BD%D1%83&body=%D0%86%D0%BC%E2%80%99%D1%8F%3A%0A%D0%9D%D0%B0%D0%B7%D0%B2%D0%B0%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D1%83%3A%0A%D0%A2%D0%B8%D0%BF%20%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D1%83%3A%0A%D0%9A%D1%96%D0%BB%D1%8C%D0%BA%D1%96%D1%81%D1%82%D1%8C%20%D0%B7%D0%B2%D1%83%D0%BA%D0%BE%D0%B2%D0%B8%D1%85%20%D0%B5%D1%84%D0%B5%D0%BA%D1%82%D1%96%D0%B2%3A%0A%D0%9A%D1%96%D0%BB%D1%8C%D0%BA%D1%96%D1%81%D1%82%D1%8C%20%D0%B4%D0%B6%D0%B8%D0%BD%D0%B3%D0%BB%D1%96%D0%B2%20(%D0%BA%D0%BE%D1%80%D0%BE%D1%82%D0%BA%D0%B8%D1%85%20%D0%BC%D1%83%D0%B7%D0%B8%D1%87%D0%BD%D0%B8%D1%85%20%D1%84%D1%80%D0%B0%D0%B7)%3A%0A%D0%9A%D1%96%D0%BB%D1%8C%D0%BA%D1%96%D1%81%D1%82%D1%8C%20%D0%B5%D0%BC%D0%B1%D1%96%D1%94%D0%BD%D1%82%D1%96%D0%B2%20(%D0%B7%D0%B2%D1%83%D0%BA%D0%B8%20%D0%BE%D1%82%D0%BE%D1%87%D0%B5%D0%BD%D0%BD%D1%8F)%3A%0A%D0%94%D0%BE%D0%B4%D0%B0%D1%82%D0%BA%D0%BE%D0%B2%D0%B0%20%D1%96%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D1%96%D1%8F%3A%0A",
  en:
    "mailto:connect@vp-production.com?subject=Sound%20Design%20Creation%20Cost&body=Name%3A%0AProject%E2%80%99s%20name%3A%0AProject%E2%80%99s%20type%3A%0AAmount%20of%20sound%20effects%3A%0AAmount%20of%20jingles%20(short%20musical%20phrases)%3A%0AAmount%20of%20ambient%20sounds%3A%0AAdditional%20Information%3A%0A",
}

const mails = {
  mail: addBreakLines(mail),
  audioBranding: addBreakLines(audioBranding),
  soundDesign: addBreakLines(soundDesign),
}

export default mails
