const mapWorkTranslations = (translations = []) => {
  return translations.map(tr => ({
    ...tr,
    uri: tr.uri.replace("/work/", "/works/"),
  }))
}

const mapper = {
  mapWorkTranslations,
}

export default mapper
