import React from "react"
import PropTypes from "prop-types"

import * as styles from "./styles.module.css"

const Card = ({ children, icon, link }) => {
  const Icon = icon
  return (
    <a
      href={link}
      target="_blank"
      className={styles.link}
      rel="noopener noreferrer nofollow"
    >
      <Icon className={styles.icon} />
      <h5 className={styles.label}>
        {children}
        <span className={styles.arrow} />
      </h5>
    </a>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
}

export default Card
