import React from "react"
import Layout from "../HomeLayout"
import { addLocaleData } from "react-intl"

import messages from "../../data/messages/ru"
import ru from "react-intl/locale-data/ru"
import "intl/locale-data/jsonp/ru"

addLocaleData(ru)

const Home = props => <Layout {...props} i18nMessages={messages} />

export default Home
