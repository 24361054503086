module.exports = {
  "post.readMore.title": "Читати ще",
  "post.soc.title": "Хочете побачити більше?",
  alertButtonLabel: "Прийняти",
  alertTitle: "Cookies... Yum...",
  alertBody: `Наш сайт використовує файли cookie, щоб покращити ваш користувацький досвід. Натискаючи кнопку "Прийняти", ви погоджуєтесь з нашою <a href="/privacy-policy">Політикою конфіденційності</a>.`,
  "work-in-progress-message": `Наразі ми тестуємо сайт. Якщо ви знайшли баг або помилку, будь ласка, <a href="mailto:connect@vp-production.com">повідомте про це нам</a>.`,
  successMailSend: "Успішно відправлено",
  errorMailSend: "Помилка, спробуйте пізніше",
  invalidemail: "Невірна адреса пошти",
  required: "Обов'язкове",
  subscriptionTitle: "Темплейт технічного завдання",
  subscriptionSubTitle: `Ми впевнені, що правильно складене ТЗ підвищує шанси на успіх
  проєкту. Тому з радістю ділимося тим, що працює для нас!`,
  firstName: "Ім'я",
  subscriptionPrivacy: `<p>
  Відправляючи цю форму, ви погоджуєтеся з
  <a href="/privacy-policy">нашою політикою конфіденційності</a>.
</p>`,
  loadingMessage: "Завантаження...",
  submit: "Відправити",
  portfolioLabel: "Портфоліо",
  prevWork: "Попередня робота",
  nextWork: "Наступна робота",
  backToWorks: "Повернутися в портфоліо",
  loadMoreButton: "Більше",
  noOptionsMessage: "Порожньо",
  categoryFilter: "Категорія",
  serviceFilter: "Послуга",
  resetFilterButton: "Скинути фільтри",
  planCardButtonLabel: "Дізнатись ціну",
  whyTitle: "Чому ми?",
  contactTitle: "Напишіть нам",
  footerLogoAlt: "Студія звукового дизайну VP Production",
  contactText:
    "Розкажіть нам про свій проєкт, продукт або ідею. Запитайте пораду або отримайте повноцінну безплатну консультацію. Дізнайтеся вартість, уточніть важливе питання, та що завгодно! Ми будемо раді з вами познайомитися й допомогти.",
  "post.soc.tw": "Більше фану",
  "post.soc.inst": "Більше бекстейджу",
  "post.soc.fb": "Більше новин",
  "post.soc.link": "Більше корисного",
  "post.timeToRead": "хв",
  subscriptionButton: "Підписатися на розсилку",
  "blog.contact.title": "Будемо на зв'язку",
  "blog.contact.text": `
  <p>У вас є цікава тема для спільного матеріалу чи ідея колаборації? Напишіть нам!</p>
  <p>Або підпишіться на нашу розсилку, щоби стежити за новими дописами.</p>
  `,
}
