// extracted by mini-css-extract-plugin
export var reviews = "styles-module--reviews--eE13i";
export var review = "styles-module--review--o4GE9";
export var meta = "styles-module--meta--5Tn1B";
export var nameAndPos = "styles-module--nameAndPos--iDxSZ";
export var thumbnail = "styles-module--thumbnail--act4s";
export var noImage = "styles-module--noImage--S8lZ-";
export var company = "styles-module--company--Q+aMI";
export var content = "styles-module--content--aWYac";
export var readMoreButton = "styles-module--readMoreButton--0tLSx";
export var closeModalButton = "styles-module--closeModalButton--Gar41";
export var modalInner = "styles-module--modalInner--pDVd1";
export var modal = "styles-module--modal--SR9gq";