import React from "react"
import { graphql } from "gatsby"
import { useQueryParams, StringParam } from "use-query-params"
import Layout from "../../layouts/uk/default"
import Headline from "../../components/Headline"
import { Outer } from "../../components/Container"
import WorksList from "../../components/PortfolioWithFilters"
import boxOfDotsLink from "../../images/box-of-dots.svg"
import Contacts from "../../components/lendingSections/Contacts"

import mailTo from "../../utils/mailTo"

import * as styles from "./styles.module.css"
import { seoDefaultData } from "../../utils/seo"

const PortfolioBox = () => (
  <img className={styles.box} src={boxOfDotsLink} role="presentation" alt="" />
)

const Portfolio = ({ data, location }) => {
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  const categories = data.allWpWorkCategory?.categories || []

  const services = data.allWpWorkService?.services || []

  const [filters, setFilters] = useQueryParams({
    category: StringParam,
    service: StringParam,
  })

  const handleFilterChange = (category, service) =>
    setFilters({ category, service })

  return (
    <Layout
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <Outer>
        <Headline Tag="h1" className={styles.title}>
          Портфолiо
        </Headline>
        <PortfolioBox />
        <PortfolioBox />
      </Outer>
      <WorksList
        works={data.allWpWork.works}
        filters={filters}
        categories={categories}
        services={services}
        onFiltersChange={handleFilterChange}
      />
      <Contacts>
        Розкажіть нам про свій проєкт, продукт або ідею. Запитайте пораду або
        отримайте повноцінну{" "}
        <a
          rel="nofollow noopener noreferrer"
          target="_blank"
          href={mailTo.mail.uk}
        >
          безплатну консультацію
        </a>
        . Дізнайтеся вартість, уточніть важливе питання, та що завгодно! Ми
        будемо раді з вами познайомитися й допомогти.
      </Contacts>
    </Layout>
  )
}

export const query = graphql`
  query PortfolioPageUkQuery {
    seoPagesData: wpCustomPage(
      language: { code: { eq: UK } }
      seo: { pageSlug: { eq: "portfolio" } }
    ) {
      ...seoCustomPageData
    }

    allWpWork(
      filter: { language: { code: { eq: UK } } }
      sort: { fields: acf___order, order: DESC }
    ) {
      ...workListItemDataWithFilters
    }
    allWpWorkCategory(
      filter: { language: { code: { eq: UK } }, count: { ne: null } }
    ) {
      categories: nodes {
        label: name
        value: slug
      }
    }
    allWpWorkService(
      filter: { language: { code: { eq: UK } }, count: { ne: null } }
    ) {
      services: nodes {
        label: name
        value: slug
      }
    }
  }
`

export default Portfolio
