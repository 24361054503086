import React from "react"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"
import * as styles from "./styles.module.css"

import { Inner } from "~components/Container"

const Sidebar = ({ title = "", text = "", image, slot }) => (
  <BackgroundImage critical className={styles.container} fluid={image}>
    <Inner>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.text}>{text}</p>
    </Inner>
    {slot ? slot : null}
  </BackgroundImage>
)

Sidebar.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.any.isRequired,
  slot: PropTypes.node,
}

export default Sidebar
