import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Layout from "../../layouts/ru/default"
import { Outer, Inner } from "../../components/Container"
import Headline from "../../components/Headline"
import boxOfDotsLink from "../../images/icon-box.svg"
import * as styles from "./styles.module.css"
import Contacts from "../../components/lendingSections/Contacts"
import SectionWithText from "../../components/SectionWithText"
import Why from "../../components/lendingSections/Why"
import FeatureCard from "../../components/FeatureCard"
import Services from "../../components/lendingSections/Services"

import iconUsers from "../../images/vp-icons/icon-1.svg"
import iconDocs from "../../images/vp-icons/icon-2.svg"
import iconLamp from "../../images/vp-icons/icon-3.svg"
import iconСlef from "../../images/vp-icons/icon-4.svg"
import iconClock from "../../images/vp-icons/icon-5.svg"
import iconDialog from "../../images/vp-icons/icon-6.svg"

import logoWithSlogan from "../../images/logo-with-slogan.svg"
import TeamCarousel from "../../components/TeamCarousel"

import mailTo from "../../utils/mailTo"
import ModalContactForm from "../../components/ModalContactForm/ModalContactForm"
import InlineButton from "../../components/InlineButton"
import { seoDefaultData } from "../../utils/seo"

const Box = () => (
  <img className={styles.box} src={boxOfDotsLink} role="presentation" alt="" />
)

const About = ({ data, location }) => {
  const [showFrom, setShowForm] = useState(false)

  const team = data.team.members
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <Inner>
        <Headline Tag="h1" className={styles.title}>
          О нас
        </Headline>
        <Box />
        <Box />
      </Inner>
      <section className={styles.aboutSection}>
        <Outer>
          <Headline className={styles.aboutTitle} Tag="h2">
            Маленькие и могучие{" "}
            <span role="img" aria-label="могучие">
              💪
            </span>
          </Headline>
          <Inner className={styles.aboutContainer}>
            <div className={styles.aboutCol}>
              <p>
                Так мы описываем наше аудиоагентство при первом знакомстве и
                вряд ли это скоро изменится. Мы не стремимся стать Большой
                Серьёзной Компанией. Какой бы привлекательной ни была эта
                перспектива — мы ценим наш craftsmen статус.
              </p>
              <p>
                Мы знаем, что одних смелых идей недостаточно, чтобы
                прогнозировано влиять на потребителя. Поэтому мы взяли всё
                лучшее от творчества и системности — так мы помогаем продуктам и
                брендам стать частью жизни их аудитории.
              </p>
              <p>
                А ещё мы с большим энтузиазмом участвуем в разных коллаборациях
                и всегда открыты к предложениям и вызовам.{" "}
                <span role="img" aria-label="руки">
                  🙌
                </span>
              </p>
              <p>
                Кстати, партнёрский контент мы тоже любим! Если вы хотите
                написать что-то с нами или о нас — вот{" "}
                <a
                  href="https://docs.google.com/document/d/10U5qjjd-vP9_w1lnaUoCYNOe7aH8V1R2Y6Ceg-mdCl8/edit?usp=sharing"
                  rel="nofollow"
                  target="__blank"
                >
                  ссылка на пресс-кит
                </a>
                .
              </p>
            </div>
            <div className={styles.aboutCol}>
              <img
                className={styles.aboutImage}
                src={logoWithSlogan}
                alt="VP Production"
              />
            </div>
          </Inner>
        </Outer>
      </section>
      <section className={styles.ourTeamSection}>
        <Outer>
          <Headline Tag="h2">Наша команда</Headline>
        </Outer>
        <Inner>
          <TeamCarousel team={team} />
        </Inner>
      </section>
      <Services
        className={styles.services}
        title="Наши услуги"
        services={[
          {
            title: "Создание музыки",
            link: "/ru/services/music-composing",
          },
          {
            title: "Аудиобрендинг",
            link: "/ru/services/audio-branding",
          },
          {
            title: "Звуковой дизайн",
            link: "/ru/services/sound-design",
          },
          {
            title: "Голосовое озвучивание",
            link: "/ru/services/voice-casting",
          },
        ]}
      />
      <Why title="И еще" style={{ backgroundColor: "#000" }}>
        <FeatureCard img={iconClock}>
          <strong>Сроки — наше всё.</strong> За 6 лет мы сорвали 0 дедлайнов.
        </FeatureCard>
        <FeatureCard img={iconСlef}>
          <strong>
            Аудитория + наше аудио ={" "}
            <span role="img" aria-label="сердце">
              ❤️
            </span>
            .
          </strong>{" "}
          Наши работы уже слышали миллионы людей и многие остались настолько
          неравнодушны, что написали о нас{" "}
          <Link rel="nofollow" to="/ru/reviews">
            такое
          </Link>
          .
        </FeatureCard>
        <FeatureCard img={iconDocs}>
          <strong>Понятный процесс работы.</strong> Мы следим, чтобы на каждом
          этапе у вас было чёткое понимание происходящего и полный контроль над
          ним. В этом нам помогают выверенные пайплайны, аудиальные мудборды и
          наша методика фидбека.
        </FeatureCard>
        <FeatureCard img={iconLamp}>
          <strong>Создаём актив бренда.</strong> Мы детально изучаем ваш
          продукт, анализируем рынок и целевую аудиторию, чтобы разработать
          общую концепцию и создать работающий аудиоконтент.
        </FeatureCard>
        <FeatureCard img={iconDialog}>
          <strong>Мы общаемся простым языком.</strong> Вам не нужно использовать
          аудио-терминологию, чтобы мы поняли, что нужно вашему проекту.
        </FeatureCard>
        <FeatureCard img={iconUsers}>
          <strong>Мы — открытые</strong> и с радостью делимся полезными для
          вашего продукта знаниями на{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.ru}
          >
            бесплатной консультации
          </a>{" "}
          и в{" "}
          <InlineButton onClick={() => setShowForm(true)}>
            проверенном годами темплейте ТЗ
          </InlineButton>
          .
        </FeatureCard>
      </Why>
      <SectionWithText
        linkLabel="Наши ценности"
        link="/ru/our-values"
        style={{ backgroundColor: "#090909" }}
      >
        <h3>
          Мы создаём захватывающее эмоциональное аудио, которое вдохновляет
          людей выражать себя.
        </h3>
      </SectionWithText>
      <Contacts>
        Расскажите нам о своём проекте, продукте или идее. Спросите совет или
        получите полноценную{" "}
        <a
          rel="nofollow noopener noreferrer"
          target="_blank"
          href={mailTo.mail.ru}
        >
          бесплатную консультацию
        </a>
        . Узнайте цены, уточните важный вопрос, или, может, вы хотите стать
        частью нашей команды? Да что угодно! Мы будем рады с вами познакомиться
        и помочь.
      </Contacts>
      <ModalContactForm
        title="Темплейт технического задания"
        subTitle="Мы уверены, что правильно составленное ТЗ повышает шансы на успех проекта. Поэтому с радостью делимся тем, что работает для нас!"
        open={showFrom}
        onClose={() => setShowForm(false)}
      />
    </Layout>
  )
}

About.propTypes = {
  location: PropTypes.any.isRequired,
}

export const query = graphql`
  query AboutPageRuQuery {
    site {
      siteMetadata {
        adminEmail
      }
    }
    seoPagesData: wpCustomPage(
      language: { code: { eq: RU } }
      seo: { pageSlug: { eq: "about" } }
    ) {
      ...seoCustomPageData
    }
    team: allWpTeam(filter: { language: { code: { eq: RU } } }) {
      ...teamData
    }
  }
`

export default About
