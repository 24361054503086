import React from "react"
import { FormattedMessage } from "react-intl"

import PostCard from "~components/PostCard"

import * as styles from "./styles.module.css"

const ReadMorePosts = ({ posts = [] }) => {
  return (
    <div className={styles.readMorePostsArea}>
      <h2 className={styles.subtitle}>
        <FormattedMessage id="post.readMore.title" defaultMessage="Read more">
          {msg => msg}
        </FormattedMessage>
      </h2>
      <ul className={styles.readMorePosts}>
        {posts.map(({ databaseId, ...post }) => (
          <li className={styles.readMorePost} key={databaseId}>
            <PostCard {...post} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ReadMorePosts
