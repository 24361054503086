import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Layout from "../../layouts/en/default"
import { Outer, Inner } from "../../components/Container"
import Headline from "../../components/Headline"
import boxOfDotsLink from "../../images/icon-box.svg"
import * as styles from "./styles.module.css"
import Contacts from "../../components/lendingSections/Contacts"
import SectionWithText from "../../components/SectionWithText"
import Why from "../../components/lendingSections/Why"
import FeatureCard from "../../components/FeatureCard"
import Services from "../../components/lendingSections/Services"

import iconUsers from "../../images/vp-icons/icon-1.svg"
import iconDocs from "../../images/vp-icons/icon-2.svg"
import iconLamp from "../../images/vp-icons/icon-3.svg"
import iconСlef from "../../images/vp-icons/icon-4.svg"
import iconClock from "../../images/vp-icons/icon-5.svg"
import iconDialog from "../../images/vp-icons/icon-6.svg"

import logoWithSlogan from "../../images/logo-with-slogan.svg"
import TeamCarousel from "../../components/TeamCarousel"

import mailTo from "../../utils/mailTo"
import ModalContactForm from "../../components/ModalContactForm/ModalContactForm"
import InlineButton from "../../components/InlineButton"
import { seoDefaultData } from "../../utils/seo"

const Box = () => (
  <img className={styles.box} src={boxOfDotsLink} role="presentation" alt="" />
)

const About = ({ data, location }) => {
  const [showFrom, setShowForm] = useState(false)
  const team = data.team.members
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
    >
      <Inner>
        <Headline Tag="h1" className={styles.title}>
          About us
        </Headline>
        <Box />
        <Box />
      </Inner>
      <section className={styles.aboutSection}>
        <Outer>
          <Headline className={styles.aboutTitle} Tag="h2">
            Small but mighty{" "}
            <span role="img" aria-label="mighty">
              💪
            </span>
          </Headline>
          <Inner className={styles.aboutContainer}>
            <div className={styles.aboutCol}>
              <p>
                That's how we describe our audio agency when we meet people for
                the first time, and most likely, it won’t change anytime soon.
                We don't aim to become a Big Serious Company. No matter how
                appealing this perspective is — we value our craftsmen status.
              </p>
              <p>
                We know that it is not enough just to have bold ideas to make a
                predictable impact on the consumer. That's why we've gotten the
                best out of creativity and system approach — in this way, we
                help products and brands to become part of their audience's
                lives.
              </p>
              <p>
                We're also very enthusiastic about different collaborations and
                are always open to suggestions and challenges.{" "}
                <span role="img" aria-label="hands">
                  🙌
                </span>
              </p>
              <p>
                By the way, we love affiliate content as well! If you want to
                write something with us or about us, here's a link to{" "}
                <a
                  href="https://docs.google.com/document/d/1r2qlEuyelg-V-WI4oEcEAiX8QCaPKCVD226dizvdbg8/edit?usp=sharing"
                  rel="nofollow"
                  target="__blank"
                >
                  the press kit
                </a>
                .
              </p>
            </div>
            <div className={styles.aboutCol}>
              <img
                className={styles.aboutImage}
                src={logoWithSlogan}
                alt="VP Production"
              />
            </div>
          </Inner>
        </Outer>
      </section>
      <section className={styles.ourTeamSection}>
        <Outer>
          <Headline Tag="h2">Audio team</Headline>
        </Outer>
        <Inner>
          <TeamCarousel team={team} />
        </Inner>
      </section>
      <Services
        title="Our services"
        className={styles.services}
        services={[
          {
            title: "Music Composing",
            link: "/en/services/music-composing",
          },
          {
            title: "Audio Branding",
            link: "/en/services/audio-branding",
          },
          {
            title: "Sound Design",
            link: "/en/services/sound-design",
          },
          {
            title: "Voice Casting",
            link: "/en/services/voice-casting",
          },
        ]}
      />
      <Why title="Also" style={{ backgroundColor: "#000" }}>
        <FeatureCard img={iconClock}>
          <strong>Always meeting deadlines.</strong> In six years, we've missed
          zero deadlines.
        </FeatureCard>
        <FeatureCard img={iconСlef}>
          <strong>
            Audience + our audio ={" "}
            <span role="img" aria-label="heart">
              ❤️
            </span>
            .
          </strong>{" "}
          Our works have been listened to by millions of people, and many of
          them were so enthusiastic that they wrote{" "}
          <Link rel="nofollow" to="/en/reviews">
            this
          </Link>
          .
        </FeatureCard>
        <FeatureCard img={iconDocs}>
          <strong>A transparent work process.</strong> We make sure that you
          have a clear understanding of what's going on and control every step
          of the production. Our precise pipelines, audio mood boards, and
          feedback methodology help us a lot in the process.
        </FeatureCard>
        <FeatureCard img={iconLamp}>
          <strong>Creating a brand asset.</strong> We accurately study your
          product, analyze the market and target audience to develop a concept
          for functioning audio content.
        </FeatureCard>
        <FeatureCard img={iconDialog}>
          <strong>We communicate in simple language.</strong> You don't need to
          use audio terminology to explain the needs of your project.
        </FeatureCard>
        <FeatureCard img={iconUsers}>
          <strong>We're open</strong> and happy to share valuable knowledge for
          your product during{" "}
          <a
            rel="nofollow noopener noreferrer"
            target="_blank"
            href={mailTo.mail.en}
          >
            a free consultation
          </a>{" "}
          and with{" "}
          <InlineButton onClick={() => setShowForm(true)}>
            the years-proven technical task template
          </InlineButton>
          .
        </FeatureCard>
      </Why>
      <SectionWithText
        linkLabel="Our values"
        link="/en/our-values"
        style={{ backgroundColor: "#090909" }}
      >
        <h3>
          We craft immersive emotional audio that inspires people to express
          themselves.
        </h3>
      </SectionWithText>
      <Contacts>
        Tell us about your project, product, or idea. Ask for advice, or get a
        full{" "}
        <a
          rel="nofollow noopener noreferrer"
          target="_blank"
          href={mailTo.mail.en}
        >
          free consultation
        </a>
        . Find out the rates, clarify a vital question, or maybe you want to
        become part of our team? Anything you want! We will be happy to get to
        know you and help.
      </Contacts>
      <ModalContactForm
        title="Technical task template"
        subTitle="We are confident that a correctly made technical task increases the chances of success of the project. Therefore, we are happy to share what works for us!"
        open={showFrom}
        onClose={() => setShowForm(false)}
      />
    </Layout>
  )
}

About.propTypes = {
  location: PropTypes.any.isRequired,
}

export const query = graphql`
  query AboutPageEnQuery {
    site {
      siteMetadata {
        adminEmail
      }
    }
    seoPagesData: wpCustomPage(
      language: { code: { eq: EN } }
      seo: { pageSlug: { eq: "about" } }
    ) {
      ...seoCustomPageData
    }
    team: allWpTeam(filter: { language: { code: { eq: EN } } }) {
      ...teamData
    }
  }
`

export default About
