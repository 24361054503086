import React, { useState } from "react"
import * as styles from "./styles.module.css"
import Layout from "../../layouts/ru/default"
import Contacts from "../../components/lendingSections/Contacts"
import Headline from "../../components/Headline"
import { Inner } from "../../components/Container"
import StyledAccordion from "../../components/StyledAccordion"
import StyledAccordionItem from "../../components/StyledAccordionItem"
import boxOfDotsLink from "../../images/icon-box.svg"
import classNames from "classnames"

import mailTo from "../../utils/mailTo"
import InlineButton from "../../components/InlineButton"
import ModalContactForm from "../../components/ModalContactForm/ModalContactForm"
import { Link, graphql } from "gatsby"
import schemaOrg from "./schemaOrg.json"
import { seoDefaultData } from "../../utils/seo"

const Boxes = ({ className = "" }) => (
  <img
    className={classNames(styles.box, className)}
    src={boxOfDotsLink}
    role="presentation"
    alt=""
  />
)

const Faq = ({ data, location }) => {
  const [showFrom, setShowForm] = useState(false)
  const contactEmail = data.site.siteMetadata.adminEmail
  const { seo } = data.seoPagesData ?? { seo: seoDefaultData }

  return (
    <Layout
      location={location}
      title={seo.title}
      disableSiteNameInTitle
      description={seo.description}
      schemaOrg={schemaOrg.ru}
    >
      <Inner>
        <Headline Tag="h1" className={styles.title}>
          faq
        </Headline>

        <Boxes className={styles.boxFirst} />

        <Boxes className={styles.boxSecond} />

        <Headline Tag="h2" className={styles.subtitle}>
          Организационные вопросы
        </Headline>
        <StyledAccordion className={styles.accordeon}>
          <StyledAccordionItem title="Что нужно, чтобы вы могли начать работу?">
            <p>
              Нам нужно сперва познакомиться с вами и вашим проектом. Для этого
              вы заполняете бриф, а мы проводим для вас{" "}
              <a
                rel="nofollow noopener noreferrer"
                target="_blank"
                href={mailTo.mail.ru}
              >
                бесплатную консультацию
              </a>
              . Если мы с вами совпадём характерами, останется только составить
              техническое задание и подписать договор.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="А что делать, если мой проект под NDA?">
            <p>
              <a
                rel="nofollow noopener noreferrer"
                target="_blank"
                href={`maito:${contactEmail}`}
              >
                Напишите нам
              </a>
              , мы подпишем соответствующие документы и сможем свободно всё
              обсудить.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Вы делаете тестовые задания?">
            <p>
             Обычно мы не выполняем тестовые задания, так как они не совсем соответствуют нашему подходу и рабочему пайплайну. 
             Мы начинаем каждый проект с рисёрча и создания аудиальной концепции, и мы не видим возможности «подружить» этот этап 
             с самой концепцией тестового задания.
            </p>

            <p>
             Мы понимаем, что для многих людей тестовые задания, в первую очередь, способ лучше познакомиться с новой командой 
             и понять, насколько в целом возможно сотрудничество. Для нас не менее важно совпадать с клиентом в общем видении и принципах работы.
            </p>

            <p>
             Поэтому мы уделяем немало времени предварительной коммуникации с клиентом, рассказываем обо всех нюансах предстоящей работы, 
             обсуждаем проект и его потребности, делимся изначальными идеями и показываем релевантные кейсы из нашей практики. 
            </p>
            
            <p>
            Однако проекты по голосовому озвучиванию — исключение, в этом случае мы с радостью предоставим вам тестовую запись выбранного актёра озвучивания.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Кто составляет техническое задание на проект?">
            <p>
              В идеале, клиент инициирует процесс создания технического задания, составляя список необходимых аудио-ассетов с соответствующим 
              визуалом/анимациями и основными требованиями. Для удобства вы можете использовать{" "}
              <InlineButton onClick={() => setShowForm(true)}>
                наш проверенный временем шаблон
              </InlineButton>.
            </p>
            
             <p>
             Затем мы дорабатываем документ на основе анализа брифа клиента, изучения его ниши, конкурентов и целевой аудитории.
             </p>
             
             <p>
             Таким образом, обе стороны с самого начала имеют чётко сформированные ожидания от результатов. 
             Это помогает выбрать правильное направление и заложить прочный фундамент для успешного сотрудничества.
             </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Как организован остальной процесс работы?">
            <p>
              В зависимости от выбранной услуги процесс может варьироваться,
              хотя основные 6 стадий остаются неизменными: знакомство,
              подготовка, запуск, продакшн, приём проекта и его дальнейшая
              поддержка.
            </p>

            <p>
              Как организован процесс работы для каждой из наших услуг, можно
              подробно прочитать на страницах{" "}
              <Link to="/ru/services/music-composing">создание музыки</Link>,{" "}
              <Link to="/ru/services/sound-design">звуковой дизайн</Link>,{" "}
              <Link to="/ru/services/audio-branding">аудиобрендинг</Link> и{" "}
              <Link to="/ru/services/voice-casting">голосовое озвучивание</Link>
              .
            </p>
          </StyledAccordionItem>
        </StyledAccordion>
        <ModalContactForm
          title="Темплейт технического задания"
          subTitle="Мы уверены, что правильно составленное ТЗ повышает шансы на успех проекта. Поэтому с радостью делимся тем, что работает для нас!"
          open={showFrom}
          onClose={() => setShowForm(false)}
        />
        <Headline Tag="h2" className={styles.subtitle}>
          Финансовые вопросы
        </Headline>

        <StyledAccordion className={styles.accordeon}>
          <StyledAccordionItem title="Вы работаете по предоплате?">
            <p>
              Да, мы работаем по предоплате. Сумма предоплаты может варьироваться в зависимости от масштаба проекта, его продолжительности и выбранных услуг.
              Это может быть 50% от общей суммы, несколько небольших платежей или полная предоплата в некоторых случаях.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="В каких случаях предоплата 100 %?">
            <p>
              Полная предоплата нужна при любом заказе голосового озвучивания и любого вида консультаций.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Как можно оплатить ваши услуги?">
            <p>
              Мы принимаем оплату за оказанные услуги только на расчётный счёт
              физического лица предпринимателя (ФЛП) или на счёт Payoneer.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>

        <Headline Tag="h2" className={styles.subtitle}>
          Юридические вопросы
        </Headline>

        <StyledAccordion className={styles.accordeon}>
          <StyledAccordionItem title="Есть ли у вас договор?">
            <p>
              Да, с каждым клиентом мы заключаем договор о предоставлении услуг.
              Он у нас крутой и крафтовый — всё благодаря команде юристов, с
              которой мы сотрудничаем.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Какие права на результат я получу?">
            <p>
              На созданные нами звуковые эффекты, голосовое озвучивание и
              элементы аудио бренда мы по умолчанию передаём вам исключительные
              материальные права интеллектуальной собственности, конечно, с
              одним условием — после оплаты наших услуг.
            </p>

            <p>
              На созданные нами музыкальные композиции мы по умолчанию передаём
              вам имущественные права интеллектуальной собственности, с
              ограничением возможности свободной их перепродажи и перевыпуска,
              вне оговоренного нами проекта, а также получения монетизации со
              стриминговых сервисов и маркетплейсов.
            </p>

            <p>
              При необходимости мы можем передать вам все исключительные
              имущественные права интеллектуальной собственности без каких-либо
              ограничений, но это следует оговаривать отдельно и в таком случае
              общая стоимость проекта будет выше.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Почему вы просто не передаёте все исключительные права на музыку?">
            <p>
              Иногда саундтреки становятся дополнительным средством монетизации
              проекта и источником сверхприбыли. Мы считаем справедливым, что в
              подобных случаях композитор имеет право на дополнительную
              компенсацию.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>

        <Headline Tag="h2" className={styles.subtitle}>
          Вопросы о консультациях
        </Headline>

        <StyledAccordion className={styles.accordeon}>
          <StyledAccordionItem title="Какие у вас есть виды консультаций?">
            <p>
              Сейчас мы проводим консультации двух видов: консультация по аудио конкретного проекта и аудио-ревью.
            </p>
            <p>
              На консультации по аудио мы обсуждаем с вами проект в целом, его
              позиционирование, выделяем основные задачи аудио, помогаем
              составить техническое задание, обсуждаем возможные идеи для
              реализации, рассказываем о лучших практиках и о том, чему стоит
              уделить внимание при работе над аудио.
            </p>
            <p>
              Аудио-ревью проекта включает в себя оценку аудиального игрового
              опыта, оценку звучания отдельных анимаций и/или катсцен,
              составление документа с предложениями по правкам.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Какую информацию нужно подготовить для консультации?">
            <p>
              Для обоих видов консультаций мы должны заранее ознакомиться с
              подробным описанием проекта, включая информацию о его целевой
              аудитории, рынке и конкурентах. Кроме того, пригодятся любые
              визуальные материалы: арты, видео, анимации и т.д. В общем, если у
              вас есть питч-документ — это идеально!
            </p>

            <p>
              Для аудио-ревью нам также понадобятся играбельный unlock-билд и
              документ с названиями всех аудио-ассетов и ссылками на
              соответствующие озвученные анимации.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Сколько длятся консультации?">
            <p>
              Консультация по аудио для конкретного проекта обычно длится 1,5
              часа, но при необходимости мы можем её продлить, либо провести
              несколько сессий.
            </p>
            <p>Аудио-ревью мы выполняем в течение трёх рабочих дней.</p>
          </StyledAccordionItem>
          <StyledAccordionItem title="В каком формате проходят консультации?">
            <p>
              Аудио-ревью мы обычно проводим в текстовом формате и в результате
              вы получаете текстовый документ.
            </p>
            <p>
              Консультацию по аудио для конкретного проекта мы проводим в
              формате аудио- или видеоконференции в Google Meet или Zoom.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Сколько стоит каждый вид консультаций?">
            <p>
              Стоимость консультации по аудио конкретного проекта — €100/час.
            </p>
            <p>Стоимость аудио-ревью — €400.</p>
            <p>
              Кроме того, каждый месяц мы проводим три бесплатных
              тридцатиминутных консультации или лайт аудио-ревью.{" "}
              <a
                rel="nofollow noopener noreferrer"
                target="_blank"
                href={`maito:${contactEmail}`}
              >
                Напишите нам
              </a>
              , чтобы поймать свой слот.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>

        <Headline Tag="h2" className={styles.subtitle}>
          Глоссарий
        </Headline>

        <StyledAccordion className={styles.accordeon}>
          <StyledAccordionItem title="Аудиоадаптации">
            <p>
              Музыкальные или звуковые фрагменты, которые создаются на основе
              аудио-ДНК и разрабатываются с учётом специфических потребностей
              каждой точки контакта бренда с аудиторией.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудиобренд">
            <p>
              Система музыки и звуков, которая транслирует смысл бренда в каждой
              его точке контакта с аудиторией.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудиобрендинг">
            <p>
              Также известен как звуковой, акустический и музыкальный брендинг.
              Процесс создания и управления системой музыки и звуков, которая
              транслирует смысл бренда в каждой его точке контакта с аудиторией.
            </p>
            <p>
              Это такая же важная часть брендинга, как и создание фирменного
              стиля. Только тут главные инструменты – музыка, звуки и голос.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудиальная концепция">
            <p>
              Документ, который содержит позиционирование бренда, список его
              аудиальных точек контакта с аудиторией, а также идеи о том, как
              передать конкретное сообщение бренда и выполнить необходимые
              задачи с помощью аудио.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудио-ДНК">
            <p>
              Центральный элемент каждого аудиобренда. Аудио «коллаж» или
              короткий полноценный трек, который воплощает обещание, ценности и
              индивидуальность бренда в аудиальной форме, а также определяет,
              как будут звучать все точки контакта бренда с аудиторией. Он
              определяет музыкальную «лексику» бренда: инструменты, гармонии,
              темп, ритмические рисунки, тембр звука, голоса и т.д.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудио логотип">
            <p>
              Звук длительностью 2-3 секунды и важнейшая часть аудио-ДНК. Он
              привлекает внимание слушателей и транслирует обещание, ценности и
              индивидуальность бренда, при этом он разработан таким образом,
              чтобы стать чрезвычайно запоминающимся.
            </p>
            <p>
              Аудиологотип - это прямой аналог визуального логотипа в
              музыкальной форме.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудиальный мудборд">
            <p>
              Плейлист, демонстрирующий различные способы передачи определённого
              сообщения, смысла или идеи.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудио стайлгайд">
            <p>
              Часть брендбука компании или отдельный документ, который содержит
              описание и правила использования аудио-ДНК, всех его адаптаций и
              других элементов аудио бренда.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Аудиальные точки контакта">
            <p>
              Точки контакта сотрудников и клиентов с брендом, которые могут
              звучать. Они должны быть унифицированы по звучанию, чтобы
              слушатели всегда могли получать положительный и предсказуемый опыт
              взаимодействия с брендом.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Джингл">
            <p>
              Короткая музыкально-вокальная фраза, которая чаще всего явно
              рекламирует продукт или услугу и почти всегда содержит слоган
              компании.
            </p>
          </StyledAccordionItem>
          <StyledAccordionItem title="Звуковая идентичность">
            <p>
              Совокупность звуковых впечатлений, которые производит бренд или
              продукт. Она должна быть как минимум последовательной и как
              максимум передавать желаемый смысл, обещание и ценности.
            </p>
          </StyledAccordionItem>
        </StyledAccordion>

        <Contacts>
          Не нашли ответ на важный для вас вопрос? Обязательно задайте его нам –
          мы будем рады познакомиться с вами и помочь.
        </Contacts>
      </Inner>
    </Layout>
  )
}

export const query = graphql`
  query FAQPageRuQuery {
    site {
      siteMetadata {
        adminEmail
      }
    }
    seoPagesData: wpCustomPage(
      language: { code: { eq: RU } }
      seo: { pageSlug: { eq: "faq" } }
    ) {
      ...seoCustomPageData
    }
  }
`

export default Faq
