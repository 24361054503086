import React from 'react'

import Flag from './flag.inline.svg'
import styles from "./UkraineSupportAlert.module.scss"

const UkraineSupportAlert = props => {

  return (
    <div className={styles.container}>
        <div className={styles.flag}>
            <Flag />
        </div>
        <div className={styles.message}>
        help Ukraine withstand russian aggression
        </div>
        <div className={styles.button}>
            <a target="__blank" rel="nofollow noindex" href="https://supportukrainenow.org/">help</a>
        </div>
    </div>
  )
}

export default UkraineSupportAlert
