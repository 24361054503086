import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import ContactUs from "../../ContactUs/ContactUs"

const Contacts = ({ title, children, className }) => (
  <section className={classNames("Contacts", className)}>
    <ContactUs title={title}>{children}</ContactUs>
  </section>
)

Contacts.defaultProps = {
  title: "",
  children: "",
}

Contacts.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Contacts
