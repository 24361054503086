import React from "react"
import { FormattedMessage } from "react-intl"

import * as styles from "./styles.module.css"

import Card from "./Card"

import TwIcon from "./icons/tw.inline.svg"
import InstIcon from "./icons/inst.inline.svg"
import FbIcon from "./icons/fb.inline.svg"
import LinIcon from "./icons/lin.inline.svg"

const socialLinks = {
  tw: "https://twitter.com/vpprodaudio",
  inst: "https://www.instagram.com/vpproduction.audio/",
  fb: "https://www.facebook.com/vpproduction.audio/",
  lin: "https://www.linkedin.com/company/vpproductionaudio/",
}

const SocialCards = props => {
  return (
    <div className={styles.container}>
      <FormattedMessage id="post.soc.title" defaultMessage="Want To See More?">
        {msg => <h4 className={styles.title}>{msg}</h4>}
      </FormattedMessage>

      <div className={styles.list}>
        <Card icon={TwIcon} link={socialLinks.tw}>
          <FormattedMessage id="post.soc.tw" defaultMessage="More Fun">
            {msg => msg}
          </FormattedMessage>
        </Card>
        <Card icon={InstIcon} link={socialLinks.inst}>
          <FormattedMessage id="post.soc.inst" defaultMessage="More Backstage">
            {msg => msg}
          </FormattedMessage>
        </Card>
        <Card icon={FbIcon} link={socialLinks.fb}>
          <FormattedMessage id="post.soc.fb" defaultMessage="More News">
            {msg => msg}
          </FormattedMessage>
        </Card>
        <Card icon={LinIcon} link={socialLinks.lin}>
          <FormattedMessage
            id="post.soc.link"
            defaultMessage="More Useful Info"
          >
            {msg => msg}
          </FormattedMessage>
        </Card>
      </div>
    </div>
  )
}

export default SocialCards
